import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

const SChip = styled(Chip)({
  //Layout properties
  borderColor: "#60A5FA",
  backgroundColor: "#E7F4FF",
  borderRadius: "6px",
  width: "fit-content",
  height: "30px",
  padding: "4px 8px",
  alignItems: "center",
  gap: "2px",

  //Typography properties
  fontFamily: '"Inter", sans-serif',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  color: "#2563EB",
  lineHeight: "20px",
  letterSpacing: "-0.28px",
});

export default SChip;
