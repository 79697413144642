import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import sensia_logo from "../../../res/images/logo/logo_sensia.svg";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../app/routing";
import UserHelper from "../../../app/helpers/UserHelper";
import { Fragment } from "react";

import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import List from "@mui/joy/List";
import Select, { selectClasses } from "@mui/joy/Select";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import Option, { optionClasses } from "@mui/joy/Option";
import { listItemDecoratorClasses } from "@mui/joy/ListItemDecorator";
import { productService } from "../../../app/services/product.service";
import HttpHelper from "../../../app/helpers/HttpHelper";

import InfoPicto from "../../../res/images/icons/InfoOutlined.svg";
import SignupHelper from "../../../app/helpers/SignupHelper";
import { userService } from "../../../app/services";
import Loader from "../../../components/loader/Loader";
import IntercomHelper from "../../../app/helpers/IntercomHelper";
import { globalConstants } from "../../../app/constants";
import { billingService } from "../../../app/services/billing.service";

const CreateAccountView = (props) => {
  const { t } = useTranslation();
  const isNonMobileScreen = useMediaQuery("(min-width:1000px)");
  const isSmallScreen = useMediaQuery("(max-width:796px)");
  const user = UserHelper.getUser();
  SignupHelper.deleteUser();

  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [groupedCategory, setGroupedCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [workspaceName, setWorkspaceName] = useState(
    UserHelper.guessCompanyName()
  );
  const [role, setRole] = useState(null);
  const [size, setSize] = useState(null);
  const [expectations, setExpectations] = useState([]);
  const [discover, setDiscover] = useState(null);
  const [redirectPriceId, setRedirectPriceId] = useState(null);

  const [error, setError] = useState(null);

  useEffect(() => {
    document
      .getElementById("form_container")
      ?.scrollTo({ top: 0, behavior: "smooth" });
  }, [step]);

  useEffect(() => {
    getProductsCategory();
    const plan = UserHelper.getSignupPlan();
    if (plan) {
      fetchPlan(plan);
    }
  }, []);

  const fetchPlan = (plan) => {
    const url = `${globalConstants.API_URL}`;
    const finalUrl = url + ROUTES.PLAN_PRICING_WS + "?currency_code=eur";

    fetch(finalUrl)
      .then((response) => response.json())
      .then((data) => {
        const redirectPlans = data.plans.filter(
          (p) => p.level.toLowerCase() === plan.toLowerCase()
        );
        if (redirectPlans && redirectPlans.length > 0) {
          const redirectPlan = redirectPlans[0];
          if (redirectPlan.monthly_priceid) {
            setRedirectPriceId(redirectPlan.monthly_priceid);
          } else if (redirectPlan.yearly_priceid) {
            setRedirectPriceId(redirectPlan.yearly_priceid);
          }
        }
      });
  };

  const getProductsCategory = () => {
    const category = [];
    productService.publicProducts(
      (response) => {
        const productCategories = []
          .concat(response)
          .sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
        productCategories.forEach((elm) => {
          category.push({
            label: elm.title,
            value: elm.id,
          });
        });
        const categoriesGroupedByMainCategory = productCategories.reduce(
          (acc, category) => {
            // Vérifiez si la main_category existe déjà dans l'accumulateur
            if (acc.hasOwnProperty(category.main_category)) {
              // Si elle existe, ajoutez la catégorie à la liste existante
              acc[category.main_category].push({
                label: category.title,
                value: category.id,
                description: category.description,
              });
            } else {
              // Sinon, créez une nouvelle liste pour cette main_category et ajoutez la catégorie
              acc[category.main_category] = [
                {
                  label: category.title,
                  value: category.id,
                  description: category.description,
                },
              ];
            }
            return acc;
          },
          {}
        );
        setGroupedCategory(categoriesGroupedByMainCategory);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  };

  const getCurrentStepQuestion = () => {
    switch (step) {
      case 1:
        return t("What best describes your role?");
      case 2:
        return t("What is the size of your company ?");
      case 3:
        return t("What are your primary expectations from Sensia?");
      case 4:
        return t("How did you discover Sensia?");
      case 5:
        return t("Which AI specialization fits your industry best?");
      default:
        return "";
    }
  };
  const getCurrentStepQuestionInfo = () => {
    switch (step) {
      case 1:
        return t("Select the option that most closely matches your job title.");
      case 2:
        return null;
      case 3:
        return t("Select all options that apply");
      case 4:
        return t(
          "Select the option that best describes how you found out about us."
        );
      case 5:
        return t("Select one to get started. You can add more later!");
      default:
        return "";
    }
  };

  const getCurrentStepOptions = () => {
    switch (step) {
      case 1:
        return [
          t("Founder/CEO"),
          t("CMO"),
          t("Brand Manager"),
          t("Marketing Director"),
          t("Insights Manager"),
          t("Product Manager"),
          t("Retail Manager"),
          t("Data Analyst"),
          t("Other"),
        ];
      case 2:
        return [t("only me"), "1-10", "11-50", "51-200", "201-1000", "1000+"];
      case 3:
        return [
          t("Improve product performance"),
          t("Gain market insights"),
          t("Optimize retail operations"),
          t("Enhance customer experience"),
          t("Increase online visibility"),
          t("Understand consumer behavior"),
          t("Drive innovation"),
          t("Track competitor activity"),
          t("Other"),
        ];
      case 4:
        return [
          t("Social media"),
          t("Search engine"),
          t("Online advertisement"),
          t("Industry event or conference"),
          t("Referral from a colleague"),
          t("News article or blog"),
          t("Email campaign"),
          t("Other"),
        ];
      case 5:
        return [];
      default:
        return [];
    }
  };

  const handleRedirect = () => {
    if (redirectPriceId) {
      billingService.createSubscribtion(
        redirectPriceId,
        1,
        globalConstants.CLIENT_URL,
        (response) => {
          if (response.status === "success") {
            UserHelper.deleteSignupPlan();
            window.location.replace(response.url);
          } else {
            props.addToast("Unknown error occurred", {
              type: "error",
              autoDismiss: true,
            });
            setIsLoading(false);
            props.history.push(ROUTES.HOME);
          }
        },
        (error) => {
          props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
          setIsLoading(false);
          props.history.push(ROUTES.HOME);
        }
      );
    } else {
      setIsLoading(false);
      props.history.push(ROUTES.HOME);
    }
  };

  const handleSubmit = () => {
    if (step < 5) {
      setStep(step + 1);
    } else {
      setIsLoading(true);

      const formData = {
        product_category_id: selectedCategoryId,
        worspace_name: workspaceName,
        user_job: role,
        company_size: size,
        user_expectation: expectations.join(", "),
        user_discovery_source: discover,
      };

      userService.createAccount(
        user.id,
        formData,
        (response) => {
          UserHelper.updateUser(formData);
          handleRedirect();
        },
        (error) => {
          setIsLoading(false);
          setError(HttpHelper.getErrorMessage(error));
        }
      );
    }
  };

  const disableNext = () => {
    switch (step) {
      case 1:
        return role === null;
      case 2:
        return size === null;
      case 3:
        return expectations === null || expectations.length === 0;
      case 4:
        return discover === null;
      case 5:
        return selectedCategoryId === null;
      default:
        return true;
    }
  };
  const isOptionSelected = (option) => {
    switch (step) {
      case 1:
        return role === option;
      case 2:
        return size === option;
      case 3:
        return [...expectations].indexOf(option) != -1;
      case 4:
        return discover === option;
      case 5:
        return false;
      default:
        return false;
    }
  };

  const displayOtherTextfield = () => {
    return false;
  };

  const handleSelectOption = (option) => {
    switch (step) {
      case 1:
        setRole(option);
        break;
      case 2:
        setSize(option);
        break;
      case 3:
        if ([...expectations].indexOf(option) != -1) {
          setExpectations(expectations.filter((e, i) => e != option));
        } else {
          setExpectations([...expectations, option]);
        }
        break;
      case 4:
        setDiscover(option);
        break;
      case 5:
        return;
      default:
        return [];
    }
    return false;
  };

  const handleChangeOther = (value) => {
    return false;
  };

  const centralContentForm = () => {
    if (step === 5) {
      return (
        <>
          <Select
            placeholder="Select your AI"
            onChange={(e, newValue) => setSelectedCategoryId(newValue)}
            indicator={<ArrowDropDown />}
            slotProps={{
              listbox: {
                component: "div",
                sx: {
                  maxHeight: 240,
                  overflow: "auto",
                  "--List-padding": "0px",
                  "--ListItem-radius": "0px",
                },
              },
            }}
            sx={{
              width: "100%",
              height: "50px",
              fontFamily: "Inter",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              background: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
                border: " 1px solid #000",
              },
              [`& .${selectClasses.indicator}`]: {
                transition: "0.2s",
                [`&.${selectClasses.expanded}`]: {
                  transform: "rotate(-180deg)",
                },
              },
              [`& .${selectClasses.button}`]: {
                color: selectedCategoryId ? "black" : "#B4B4B4",
                fontFamily: "Inter",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              },
            }}
          >
            {Object.entries(groupedCategory).map(
              ([name, categories], index) => (
                <React.Fragment key={name}>
                  {index !== 0 && <ListDivider role="none" />}
                  <List
                    aria-labelledby={`select-group-${name}`}
                    sx={{ "--ListItemDecorator-size": "28px" }}
                  >
                    <ListItem id={`select-group-${name}`} sticky>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "normal",
                          textTransform: "capitalize",
                        }}
                      >
                        {name.toLowerCase()}
                      </Typography>
                    </ListItem>
                    {categories.map((category) => (
                      <Option
                        key={category.id}
                        value={category.value}
                        label={<Fragment>{category.label}</Fragment>}
                        sx={{
                          pl: "17px",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "2px",
                          [`&.${optionClasses.selected} .${listItemDecoratorClasses.root}`]:
                            {
                              opacity: 1,
                            },
                        }}
                      >
                        {/* {category.label}
                  {category.description} */}
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "normal",
                          }}
                        >
                          {category.label}
                        </Typography>
                        {category.description && (
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontSize: "13px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                              color: "#969696",
                            }}
                          >
                            {category.description}
                          </Typography>
                        )}
                      </Option>
                    ))}
                  </List>
                </React.Fragment>
              )
            )}
          </Select>
          <Box sx={{ mt: "16px", display: "flex" }}>
            <img src={InfoPicto} alt="" />
            <Typography
              sx={{
                ml: "4px",
                color: "#7F7F7F",
                fontFamily: "Inter",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "22px",
              }}
            >
              {t(
                "Your plan includes 1 AI specialization. Additional specializations can be purchased later."
              )}
            </Typography>
          </Box>
        </>
      );
    }

    const formResponse = getCurrentStepOptions();
    const displayOther = displayOtherTextfield();
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: isNonMobileScreen ? "flex-start" : "center",
        }}
      >
        {formResponse.map((option) => {
          const isSelected = isOptionSelected(option);
          return (
            <Button
              key={option}
              variant="text"
              onClick={() => handleSelectOption(option)}
              sx={{
                textTransform: "none",
                width: "225px",
                height: "64px",
                justifyContent: "center",
                alignItems: "center",
                flexShrink: 0,
                borderRadius: "6px",
                border: isSelected ? "1px solid #3A79E6" : "none",
                background: isSelected ? "#E6EFFF" : "#F7F9FF",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                {option}
              </Typography>
            </Button>
          );
        })}
        {displayOther && (
          <TextField
            size="medium"
            type="text"
            hiddenLabel={true}
            name="other"
            disabled={isLoading}
            placeholder={t("Please specify")}
            onChange={(e) => {
              handleChangeOther(e.target.value);
            }}
            sx={{ mt: "30px", height: "50px", width: "100%" }}
          />
        )}
      </Box>
    );
  };

  const centralContent = (
    <Box sx={{ maxWidth: "729px" }}>
      <Typography
        sx={{
          color: "#0E004B",
          fontFamily: "Inter",
          fontSize: "34px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          opacity: step === 1 || step === 5 ? "1" : "0",
        }}
      >
        {step === 1 ? (
          <p>
            Welcome{" "}
            <span style={{ color: "#30BEEC", fontWeight: 600 }}>
              {user?.firstname ? user.firstname : ""}!
            </span>
          </p>
        ) : (
          <p>
            Almost{" "}
            <span style={{ color: "#30BEEC", fontWeight: 600 }}>done!</span>
          </p>
        )}
      </Typography>
      <Typography
        sx={{
          mt: "10px",
          color: "#A3A4A8",
          fontFamily: "Inter",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          opacity: step === 1 || step === 5 ? "1" : "0",
        }}
      >
        {step === 1
          ? t(
              "Tell us about yourself to make your Sensia experience even better!"
            )
          : t("Thanks for your time. We're excited to have you join Sensia.")}
      </Typography>
      <Typography
        sx={{
          mt: "50px",
          color: "#0E004B",
          fontFamily: "Inter",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "28px",
        }}
      >
        {step + "/5"}
      </Typography>
      <Typography
        sx={{
          mt: "10px",
          color: "#0E004B",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
        }}
      >
        {getCurrentStepQuestion()}
      </Typography>
      <Typography
        sx={{
          mt: "2px",
          color: "#0E004B",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        }}
      >
        {getCurrentStepQuestionInfo()}
      </Typography>
      <Box sx={{ mt: "30px" }}>{centralContentForm()}</Box>
      <LinearProgress
        sx={{ mt: "110px" }}
        variant="determinate"
        value={step * 20}
      />
      <Box sx={{ mt: "50px", display: "flex", justifyContent: "flex-end" }}>
        {isLoading && (
          <Box sx={{ width: "20px", padding: "14px 52px" }}>
            <Loader />
          </Box>
        )}
        {!isLoading && (
          <Button
            variant="contained"
            disabled={isLoading || disableNext()}
            onClick={() => handleSubmit()}
            sx={{
              background: "#306ED6",
              textTransform: "none",
              padding: "14px 52px",
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                fontFamily: "Inter",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              {t("Next")}
            </Typography>
          </Button>
        )}
      </Box>
      {error && (
        <Typography
          sx={{
            mt: "10px",
            textAlign: "end",
            color: "#e04f77",
            fontFamily: "Inter",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          {error}
        </Typography>
      )}

      {/* keep same width with empty box */}
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: "190px", height: "10px" }}></Box>
        <Box sx={{ width: "190px", height: "10px" }}></Box>
        <Box sx={{ width: "190px", height: "10px" }}></Box>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        position: "fixed",
      }}
    >
      <Box
        sx={{
          pl: isNonMobileScreen ? "170px" : isSmallScreen ? "30px" : "100px",
          height: "64px",
          width: "100%",
          display: "flex",
        }}
      >
        <img src={sensia_logo} alt="logo sensia" width="126px" />
      </Box>
      <Divider />
      <Box
        id="form_container"
        item
        sx={{
          p: "34px",
          display: "flex",
          justifyContent: "center",
          overflowY: "scroll",
          height: "calc(100% - 132px)",
        }}
      >
        {centralContent}
      </Box>
    </Box>
  );
};

export default CreateAccountView;
