import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { ROUTES } from "../../../app/routing";
import { SessionStorageService } from "../../../app/services/storage.service";
import StagingRibbon from "../../../components/stagingRibbon/StagingRibbon";
import LogoSensia from "../../../res/images/logo/logo-sensia-modal.svg";
import "../addMedia.css";

class MediaHeader extends React.Component {
  constructor(props) {
    super(props);

    this.getContent = this.getContent.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.onLogoClick = this.onLogoClick.bind(this);
  }

  onCloseClick() {
    const update_media_referrer = SessionStorageService.get(
      "update_media_referrer"
    );
    SessionStorageService.delete("update_media_referrer");

    sessionStorage.removeItem("selectedSheet");
    sessionStorage.removeItem("selectedCharacteristics");
    sessionStorage.removeItem("selectedFields");
    sessionStorage.removeItem("selectedReviews");
    sessionStorage.removeItem("columnsOptions");

    if (update_media_referrer) {
      this.props.history.push(update_media_referrer);
    } else {
      this.props.history.push(ROUTES.HOME);
    }
  }

  onLogoClick() {
    SessionStorageService.delete("update_media_referrer");
    this.props.history.push(ROUTES.HOME);
  }

  getContent() {
    const { title, boldTitle } = this.props;
    return (
      <Fragment>
        <StagingRibbon />
        <div className="central-content">
          <div className="logo">
            <div onClick={this.onLogoClick}>
              <img alt="" className="logo-sensia" src={LogoSensia} />
            </div>
          </div>
          <div className="close" onClick={this.onCloseClick} />
          {title && (
            <div className="title">
              <span className="font-weight-700 font-size-20">{title}</span>
            </div>
          )}
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="media-header">{this.getContent()}</div>
        <div className="media-header fake">{this.getContent()}</div>
      </Fragment>
    );
  }
}

export default withTranslation()(MediaHeader);
