import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip } from "@mui/material";
import { resourceService } from "../app/services/resource.service";
import { useState } from "react";
import { useEffect } from "react";
import STypography from "./SMui/STypography";

export default function LanguageSelect({
  title = null,
  sx = { width: 300 },
  onChange = () => null,
  values = [],
  availableLanguages = null,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      setLoading(true);
      await resourceService.getLanguages(
        (response) => {
          setLanguages(response);
          setLoading(false);
          setError(null);
        },
        (error) => {
          console.error(error);
          setError(
            error.message || "An error occurred while fetching languages"
          );
          setLoading(false);
        }
      );
    };

    fetchLanguages();
  }, []);

  let filteredLanguages = Object.assign([], languages);

  if (availableLanguages && availableLanguages.length > 0) {
    filteredLanguages = filteredLanguages.filter((lang) =>
      availableLanguages.includes(lang.label)
    );
  }

  let currentValues = [];
  if (values?.length > 0) {
    currentValues = filteredLanguages.filter((lang) =>
      values.includes(lang.label)
    );
  }

  if (error) {
    return <STypography sx={{ color: "#E04F77" }}>{error}</STypography>;
  }

  return (
    <Autocomplete
      id="language-select"
      multiple
      sx={sx}
      size="small"
      options={filteredLanguages.sort((a, b) =>
        a.suggested === b.suggested ? 0 : a.suggested ? -1 : 1
      )} // Tri pour que les suggérés viennent en premier
      groupBy={(option) => (option.suggested ? "Most popular language" : "")} // Groupe les options suggérées
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={(event, values) => onChange(values)}
      limitTags={4}
      value={currentValues}
      ListboxProps={{
        style: {
          maxHeight: "180px",
          border: "1px solid #cdd7e1",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        },
      }}
      // renderTags={(value, getTagProps) =>
      //   value.map((option, index) => {
      //     const { key, ...tagProps } = getTagProps({ index });
      //     return (
      //       <Chip
      //         variant="filled"
      //         label={option.label}
      //         sx={{
      //           backgroundColor: "#79A7FF29",
      //           height: "28px",
      //         }}
      //         key={key}
      //         {...tagProps}
      //       />
      //     );
      //   })
      // }
      renderOption={(props, option) => (
        <Box {...props} key={option.label}>
          <STypography sx={{ fontSize: "15px", fontWeight: 400 }}>
            {option.label}
          </STypography>
        </Box>
      )}
      renderGroup={(params) => (
        <>
          {params.group === "Most popular language" && (
            <Box
              key={`group-${params.key}`}
              sx={{ padding: "8px 16px", fontWeight: "bold" }}
            >
              {params.group}
            </Box>
          )}
          {params.group !== "Most popular search" && (
            <hr
              style={{
                margin: "8px 0",
                border: "none",
                borderTop: "1px solid #ccc",
              }}
            />
          )}
          <Box key={params.key}>{params.children}</Box>
        </>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            key={`input-${params.key}`}
            sx={{
              fontFamily: "Inter",

              input: {
                "&::placeholder": {
                  fontSize: "15px",
                  fontWeight: 400,
                },
              },
            }}
            label={title}
            placeholder={
              values?.length ? "" : loading ? "Loading..." : "Select"
            }
            autoComplete="off"
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              style: {
                height: "40px",
                paddingTop: "1px",
                paddingBottom: "1px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
          />
        );
      }}
      isOptionEqualToValue={(option, value) => option.label === value.label}
    />
  );
}
