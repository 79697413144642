import React, { createRef, Fragment } from "react";

import { globalConstants } from "../../app/constants";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { projectService } from "../../app/services/project.service";
import { mediaService } from "../../app/services/media.service";
import HttpHelper from "../../app/helpers/HttpHelper";

import { SessionStorageService } from "../../app/services/storage.service";

import parse from "html-react-parser";

import "../projectDetail/tabViews/overview/OverView.css";

import "./style.css";
import moment from "moment";

import sensiaLogo from "../../res/images/icons/SensiaLogo.svg";
import EmptyState from "./EmptyState";
import SkeletonLoad from "../projectReport/SkeletonLoad";
import CarouselComponent from "../../components/carroussel/Carousel";
import CarouselCard from "../../components/carroussel/Card";

import p1 from "../../res/images/carousel/p1.svg";
import p2 from "../../res/images/carousel/p2.svg";
import p3 from "../../res/images/carousel/p3.svg";
import p4 from "../../res/images/carousel/p4.svg";

import loadArrow from "../../res/images/icons/cached-filled.svg";
import endAnalyse from "../../res/images/icons/check-filled.svg";
import StatisticsView from "../project/components/StatisticsView";
import DataSourceSelect from "../project/components/DataSourceSelect";
import { Box } from "@mui/material";

class ProjectNewIndightView extends React.Component {
  constructor(props) {
    super(props);

    const sortedBy =
      SessionStorageService.get("project_detail_sorted_by") || "updated_at";

    console.log("datasource id:", props.datasourceId);
    this.state = {
      projectId: props.projectId,
      project: null,
      datasourceId: props.datasourceId,
      nbSources: 0,
      datasourceList: [],
      isLoaded: false,
      inlineLoading: false,
      isFirstLoad: true,
      formData: {
        sorted_by: sortedBy,
      },
      mediasAnalyseInProgress: false,
      mediasAnalyseJustFinish: false,
      mediaChatGpt: null,
      mediaStats: null,
      chatGpt: {},
      media: props.datasourceId != null,
      clipboardToast: false,
      isAllDatasource: props.datasourceId == null,
    };

    this.abortController = new AbortController();
    this.abortControllerSignal = this.abortController.signal;

    this.fetchProject = this.fetchProject.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);
    this.pageTitleHandler = this.pageTitleHandler.bind(this);
    this.onChangeMediaScope = this.onChangeMediaScope.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.statisticsViewRef = createRef();
  }

  componentDidMount() {
    this._ismounted = true;
    window.addEventListener("scroll", () => {
      this.handleScroll();
    });
    this.fetchProject();
  }

  componentWillUnmount() {
    this.abortController.abort();
    this._ismounted = false;
    window.removeEventListener("scroll", () => {
      this.handleScroll();
    });
  }

  checkDatasourcesStatus() {
    const checkDatasourcesStatusInterval = setInterval(() => {
      mediaService.get.getProjectMedias(
        this.state.projectId,
        this.state.formData,
        (response) => {
          let result = response.data.filter(
            (elm) => elm.status === "INPROGRESS"
          );
          if (result == undefined || result.length == 0) {
            clearInterval(checkDatasourcesStatusInterval);
            this.fetchProject();
            this.statisticsViewRef.current.refreshStatistics();
          }
        },
        (error) => {
          this.handleErrorData(error, this.state.formData);
        },
        this.abortControllerSignal
      );
    }, 5000);
  }

  displayToastErrorMessage(error, onThen, updatedState) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    if (typeof onThen == "function") {
      onThen();
    }
    if (updatedState) {
      this.setState(updatedState);
    }
  }

  async fetchProject(first) {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        this.setState({
          project: response,
          nbSources: response.medias ? response.medias : 0,
          chatGpt: {
            updated_at: response.updated_at,
            reports: response.reports,
          },
        });

        if (response.medias > 0) {
          this.fetchData();
        } else {
          this.setState({
            isLoaded: true,
            isFirstLoad: false,
            inlineLoading: false,
          });
        }
      },
      (error) => {
        this.displayToastErrorMessage(error);
      },
      null,
      this.abortControllerSignal
    );
  }

  fetchData() {
    if (this._ismounted) {
      this.setState({ inlineLoading: true });
    }

    mediaService.get.getProjectMedias(
      this.state.projectId,
      this.state.formData,
      (response) => {
        let mediaChatGptList = {};
        let mediaStatsList = {};

        response.data.forEach((elm) => {
          mediaChatGptList[elm.id] = {
            updated_at: elm.updated_at,
            reports: elm.reports,
          };

          mediaStatsList[elm.id] = {
            source: 1,
            rewiews: elm.total_obs.total_obs_used,
            total_obs: elm.total_obs.total_obs,
            total_obs_used: elm.total_obs.total_obs_used,
            pos: elm.percent_pos,
            neg: elm.percent_neg,
            score: elm.average_score,
            feeling: elm.main_emotion,
            updated_at: elm.updated_at,
          };
        });
        let hasMediaInProgress = false;
        let analyseJustFinish = this.state.mediasAnalyseJustFinish;

        const result = response.data.filter(
          (elm) => elm.status === "INPROGRESS"
        );
        if (result != undefined && result.length > 0) {
          hasMediaInProgress = true;
          this.checkDatasourcesStatus();
        }

        if (this.state.mediasAnalyseInProgress && !hasMediaInProgress) {
          analyseJustFinish = true;
        }

        const successList = response.data.filter(
          (elm) =>
            (elm.status === "SUCCESS" && elm.total_obs?.total_obs) ||
            (elm.status === "INPROGRESS" &&
              elm.main_emotion &&
              elm.percent_neg &&
              elm.percent_pos &&
              elm.reports &&
              elm.reports?.length)
        );

        const sources = response.data.filter((elm) => elm.status !== "FAILED");
        let nbSources = 0;
        if (sources) {
          nbSources = sources.length;
        }

        this.setState({
          isLoaded: true,
          isFirstLoad: false,
          inlineLoading: false,
          mediaChatGpt: mediaChatGptList,
          mediaStats: mediaStatsList,
          datasourceList: successList,
          mediasAnalyseInProgress: hasMediaInProgress,
          mediasAnalyseJustFinish: analyseJustFinish,
          nbSources: nbSources,
        });
      },
      (error) => {
        this.handleErrorData(error, this.state.formData);
      },
      this.abortControllerSignal
    );
  }

  onChangeMediaScope(datasource_id) {
    this.setState({
      datasourceId: datasource_id,
      isAllDatasource: datasource_id == null,
    });

    this.props.selectDatasourceId(datasource_id);

    if (datasource_id == null) {
      sessionStorage.removeItem("selectedDatasource");
    }
  }

  handleErrorData(error, newFormData) {
    this.displayToastErrorMessage(error, null, {
      formData: newFormData,
      isLoaded: true,
      inlineLoading: false,
    });
  }

  pageTitleHandler() {
    return `${this.state.project ? this.state.project.name + " -" : ""} ${
      globalConstants.WEBSITE_TITLE
    }`;
  }

  selectAnchor(_anchor) {
    if (this.state.isLoaded && this.state.nbSources > 0) {
      document.querySelectorAll(".anchor").forEach((anchor) => {
        anchor.classList.remove("active");
      });
      const menu_div = document.getElementById(`menu_${_anchor}`);
      menu_div.classList.add("active");
      const selectedAnchor = document.getElementById(_anchor);
      const selectedAnchorPos = selectedAnchor.offsetTop;

      window.scrollTo({ top: selectedAnchorPos - 30, behavior: "smooth" });
    }
  }

  handleScroll() {
    const mainContent = document.getElementById("main-content");

    if (
      this.state.isLoaded &&
      this.state.nbSources > 0 &&
      mainContent !== null &&
      mainContent !== undefined
    ) {
      let scrollValue = window.scrollY;
      let mainContentPos = mainContent.offsetTop;

      let activeSection = null;
      let prviousPos = mainContentPos - 50;
      document.querySelectorAll(".report-section").forEach((section) => {
        const sectionPosition = section.offsetHeight + prviousPos;
        prviousPos += section.offsetHeight;

        if (!activeSection && scrollValue < sectionPosition) {
          activeSection = section;
        }
      });

      document.querySelectorAll(".anchor").forEach((anchor) => {
        anchor.classList.remove("active");
      });

      const menu_div = document.getElementById(`menu_${activeSection?.id}`);
      menu_div?.classList.add("active");
    }
  }

  handleDatasourceTooltip() {
    if (Array.isArray(this.state.datasourceList)) {
      if (this.state.isAllDatasource) {
        return this.state.datasourceList;
      } else if (Number.isInteger(this.state.datasourceId)) {
        return this.state.datasourceList.filter(
          (ds) => ds.id === this.state.datasourceId
        );
      }
    }

    return [];
  }

  getCarousselCards() {
    return [
      {
        key: 1,
        title: "Summary",
        text: "The summary provides a comprehensive overview of the product analysis.",
        content: <CarouselCard imagen={p1} />,
      },
      {
        key: 2,
        title: "Weaknesses",
        text: "Weaknesses highlights the most significant product issues.",
        content: <CarouselCard imagen={p2} />,
      },
      {
        key: 3,
        title: "Recommendations",
        text: "Recommendations provides tips for product improvement.",
        content: <CarouselCard imagen={p3} />,
      },
      {
        key: 4,
        title: "Select data source",
        text: "Select data source provides the flexibility to generate the report from all data sources or from a single source.",
        content: <CarouselCard imagen={p4} />,
      },
    ];
  }

  render() {
    const { t } = this.props;
    const {
      isLoaded,
      datasourceId,
      mediasAnalyseInProgress,
      mediasAnalyseJustFinish,
      nbSources,
      datasourceList,
    } = this.state;

    const { print } = this.props;

    if (!isLoaded) {
      return (
        <Fragment>
          <SkeletonLoad />
        </Fragment>
      );
    }

    const hasSource = nbSources > 0;
    const hasSourceWithData = datasourceList.length > 0;

    const displaySourceList = hasSourceWithData;

    let chatGpt = this.state.chatGpt;
    let score = this.state.project?.statistics?.product_score;
    if (datasourceId && hasSource && this.state.mediaChatGpt) {
      chatGpt = this.state.mediaChatGpt[datasourceId];
      const datasource = datasourceList.filter((ds) => ds.id === datasourceId);
      score = datasource?.average_score;
    }

    const sortedChatGptReports = chatGpt.reports.sort(
      (a, b) => a.display_order - b.display_order
    );

    const displayEmptyState = !hasSource;

    const displayCaroussel =
      hasSource && score == null && chatGpt.reports?.length == 0;

    const displayScore = !displayCaroussel && hasSourceWithData;

    const displayStayTuned =
      !displayCaroussel && mediasAnalyseInProgress && hasSourceWithData;
    const dsiplayLetsAnalyse = mediasAnalyseJustFinish && hasSourceWithData;

    const displayExecSummary = chatGpt.reports?.length > 0;

    return (
      <Fragment>
        <div
          id="projectNewInsight"
          className="responsive-920-padding-top-20"
          style={{ zIndex: "0" }}
        >
          <div className="central-content" id="scrollArea">
            <div className="position-relative content-title-project">
              {print && (
                <div
                  style={{
                    fontSize: "9px",
                    marginTop: "15px",
                    alignSelf: "flex-end",
                    textAlign: "right",
                    width: "700px",
                    display: "flex",
                    alignItems: "center",
                    columnGap: "6px",
                    justifyContent: "flex-end",
                  }}
                >
                  Powered by <img src={sensiaLogo} alt="" />
                </div>
              )}
            </div>

            {displaySourceList && (
              <Box id="datasource-list" sx={{ mt: "10px", mr: "95px" }}>
                <DataSourceSelect
                  isLoading={this.state.datasourceList.length == 0}
                  menuTitle={t("Choose insight scope")}
                  datasources={this.state.datasourceList}
                  selectedDatasource={this.state.datasourceId}
                  onChange={this.onChangeMediaScope}
                />
              </Box>
            )}

            {displayScore && (
              <div id="insights-score" className="margin-top-20">
                <StatisticsView
                  ref={this.statisticsViewRef}
                  projectId={this.state.projectId}
                  datasourceId={this.state.datasourceId}
                />
              </div>
            )}

            <div className="position-relative">
              <div
                style={
                  !print
                    ? { alignItems: "flex-end" }
                    : {
                        marginTop: "6px",
                        marginBottom: "33px",
                        alignItems: "flex-end",
                      }
                }
                className="display-flex"
              >
                {displayStayTuned && (
                  <div className="updateMessage">
                    <img className="rotate-img" src={loadArrow} alt="" />
                    {t("Almost there, your reports are shaping up!")}
                  </div>
                )}
                {dsiplayLetsAnalyse && (
                  <div className="updateMessage">
                    <img src={endAnalyse} alt="" />
                    {t("All set, let's analyse")}
                  </div>
                )}
              </div>
            </div>

            {displayExecSummary && (
              <div id="main-content">
                {!print && (
                  <div id="menu">
                    <ul>
                      {sortedChatGptReports?.map((report, index) => {
                        return (
                          <li
                            key={`chat-gpt-reports-${index}`}
                            onClick={() =>
                              this.selectAnchor(
                                report.title.split(" ").join("_")
                              )
                            }
                            id={`menu_${report.title.split(" ").join("_")}`}
                            className={`anchor ${index == 0 ? "active" : ""}`}
                          >
                            <p>{report.title}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                <div
                  id="text"
                  style={print ? { border: "none", paddingLeft: "0" } : {}}
                >
                  {sortedChatGptReports?.map((report, index) => {
                    return (
                      <section
                        key={`chat-gpt-reports-section-${index}`}
                        style={
                          !print ? {} : { fontSize: "11px", width: "700px" }
                        }
                        className="report-section"
                        id={report.title.split(" ").join("_")}
                        menu={report.title.split(" ").join("_")}
                      >
                        <div>
                          {report.sections?.map((section, index) => {
                            return <div key={index}>{parse(section)}</div>;
                          })}
                        </div>
                      </section>
                    );
                  })}
                  <p
                    style={
                      !print
                        ? {}
                        : {
                            fontSize: "9px",
                            width: "700px",
                            alignSelf: "flex-start",
                          }
                    }
                    id="lastUpdate"
                  >
                    Last updated{" "}
                    {moment.utc(chatGpt.updated_at).local().format("L")}
                  </p>
                </div>
              </div>
            )}

            {/* si pas de datasources */}
            {displayEmptyState && (
              <EmptyState
                history={this.props.history}
                projectId={this.state.projectId}
                handleClickAddDatasouce={() => {
                  this.props.handleClickAddDatasource();
                }}
                handleClickSearchDatasouce={() => {
                  this.props.handleClickSearchDatasource();
                }}
              />
            )}

            {/* si 1 datasource mais pas encore charger a 100% */}
            {displayCaroussel && (
              <CarouselComponent
                cards={this.getCarousselCards()}
                height="500px"
                width="50%"
                margin="0 auto"
                offset={2}
                showArrows={false}
                mainTitle={"Waiting is rewarding. Explore features now! ⭐🔍"}
                secondTitle={
                  "While Sensia does its magic, why not take a breather or enjoy a quick virtual coffee? We'll have your insights ready before you know it! ☕✨"
                }
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(
  connect(mapStateToProps)(ProjectNewIndightView)
);
