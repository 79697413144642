import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import HttpHelper from "../../../../app/helpers/HttpHelper";
import star_icon from "../../../../res/images/icons/star-icon-orange.png";
import star_icon_blue from "../../../../res/images/icons/star-icon-blue.svg";
import ReviewsIcon from "@mui/icons-material/Reviews";
import add_user_icon from "../../../../res/images/plan-icons/add-user-orange.svg";
import "../../settings.css";
import { ROUTES } from "../../../../app/routing";
import { ACCOUNT_PLAN, globalConstants } from "../../../../app/constants";

import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  LinearProgress,
} from "@mui/material";

import Loader from "../../../../components/loader/Loader";
import STypography from "../../../../components/SMui/STypography";
import IntercomHelper from "../../../../app/helpers/IntercomHelper";
import moment from "moment-timezone";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Select, { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { AccountDataManagerContext } from "../../../../dataManagers/accountDataManager";
import { billingService } from "../../../../app/services/billing.service";
import { format } from "date-fns";

class OverviewTab extends React.Component {
  static contextType = AccountDataManagerContext;

  constructor(props) {
    super(props);

    this.state = {
      account: props.account,
      usersStatus: "success",
      current_plan: null,
      planReached: null,
      planOptions: [],
      current_capacity: null,
      current_ai: [],
      aiOptions: [],
      aiReached: null,
      capacityReached: null,
      capacityOptions: [],
      dataLoaded: false,
      seatNumber: props.account.quantity,
      changePlanLoading: false,
      changeSeatLoading: false,
      changeCapacityLoading: false,
      changeAiLoading: false,
      freePlanReviews: 500,
    };

    this.upgradePlan = this.upgradePlan.bind(this);
    this.upgradePlanSeat = this.upgradePlanSeat.bind(this);
    this.upgradeCapacity = this.upgradeCapacity.bind(this);
    this.upgradeAi = this.upgradeAi.bind(this);
    this.displayToast = this.displayToast.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;

    billingService.getPlan(
      (response) => {
        const planOptions = response.data?.PLAN?.flatMap((plan) =>
          plan.prices
            ?.filter((price) => price.enabled) // Filtrer les prix activés
            .map((price) => ({
              name: plan.name, // Nom du plan
              level: plan.extra_metadata.level, // Niveau du plan
              stripePriceId: price.stripe_price_id, // ID Stripe du prix
              interval: price.interval, // Intervalle (month/year)
              unitAmount: price.unit_amount, // Montant unitaire
              currency: price.currency, // Devise
            }))
        );

        let freePlanReviews = 500;
        const freePlan = response.data?.PLAN.find((plan) => plan.id === "FREE");
        if (freePlan) {
          freePlanReviews = freePlan.reviews;
        }

        let current_plan = response.subscriptions?.filter(
          (s) => s.type === "PLAN"
        );

        if (current_plan.length > 0) {
          current_plan = current_plan[0];
        } else {
          current_plan = null;
        }

        const capacityOptions = response.data?.CAPACITY?.flatMap((capacity) =>
          capacity.prices?.map((price) => ({
            name: capacity.name, // Nom du pack capacity
            level: capacity.extra_metadata.level, // Niveau du plan
            stripePriceId: price.stripe_price_id, // ID Stripe du prix
            interval: price.interval, // Intervalle (month/year)
            unitAmount: price.unit_amount / 100, // Montant unitaire
            currency: price.currency, // Devise
            quantity: price.transform_quantity,
          }))
        );

        let current_capacity = response.subscriptions?.filter(
          (s) => s.type === "capacity"
        );

        if (current_capacity.length > 0) {
          current_capacity = current_capacity[0];
          if (
            !capacityOptions.find(
              (capacity) =>
                capacity.stripePriceId === current_capacity.stripe_price_id
            )
          ) {
            capacityOptions.push({
              name: current_capacity.product, // Nom du plan
              level: null,
              stripePriceId: current_capacity.stripe_price_id, // ID Stripe du prix
              interval: current_capacity.interval, // Intervalle (month/year)
              unitAmount: current_capacity.unit_amount / 100, // Montant unitaire
              currency: current_capacity.currency, // Devise
              quantity: current_capacity.quantity,
            });
          }
        } else {
          current_capacity = null;
        }

        const aiOptions = response.data?.AI_ADDON?.flatMap((ai) =>
          ai.prices?.map((price) => ({
            name: ai.name, // Nom du pack ai
            level: ai.extra_metadata.level, // Niveau du plan
            stripePriceId: price.stripe_price_id, // ID Stripe du prix
            interval: price.interval, // Intervalle (month/year)
            unitAmount: price.unit_amount / 100, // Montant unitaire
            currency: price.currency, // Devise
            quantity: price.transform_quantity,
          }))
        );

        let current_ai = response.subscriptions?.filter(
          (s) => s.type === "ai_addon"
        );

        current_ai.forEach((ai) => {
          if (
            !aiOptions.find(
              (aiOption) => aiOption.stripePriceId === ai.stripe_price_id
            )
          ) {
            aiOptions.push({
              name: ai.product, // Nom du plan
              level: null,
              stripePriceId: ai.stripe_price_id, // ID Stripe du prix
              interval: ai.interval, // Intervalle (month/year)
              unitAmount: ai.unit_amount / 100, // Montant unitaire
              currency: ai.currency, // Devise
            });
          }
        });

        this.setState({
          dataLoaded: true,
          freePlanReviews: freePlanReviews,
          planOptions: planOptions,
          planReached: planOptions[0]?.stripePriceId,
          capacityOptions: capacityOptions,
          current_plan: current_plan,
          current_capacity: current_capacity,
          aiOptions: aiOptions,
          current_ai: current_ai,
        });
      },
      (error) => {
        this.setState({
          dataLoaded: true,
        });
        this.displayToast(HttpHelper.getErrorMessage(error));
      }
    );
  }

  displayToast(message, autoDismiss = true) {
    this.props.addToast(message, {
      type: "error",
      autoDismiss: autoDismiss,
    });
  }

  upgradePlan() {
    if (!this.state.planReached) {
      return; //TODO: set error
    }
    const current_plan = this.state.current_plan;

    const maxUsers =
      (this.state.account.nb_user ?? 0) > (this.state.account.quantity ?? 0)
        ? this.state.account.nb_user ?? 0
        : this.state.account.quantity ?? 0;

    this.setState({ changePlanLoading: true });
    if (this.state.planReached === "Cancel") {
      billingService.cancelSubscribtion(
        current_plan?.subscription_id,
        (response) => {
          if (response.status === "success") {
            window.location.replace(response.url);
          } else {
            this.displayToast("Unknown error occurred");
          }
          this.setState({ changePlanLoading: false });
        },
        (error) => {
          this.displayToast(HttpHelper.getErrorMessage(error));
          this.setState({ changePlanLoading: false });
        }
      );
    } else if (!current_plan) {
      billingService.createSubscribtion(
        this.state.planReached,
        maxUsers,
        globalConstants.CLIENT_URL,
        (response) => {
          if (response.status === "success") {
            window.location.replace(response.url);
          } else {
            this.displayToast("Unknown error occurred");
          }
          this.setState({ changePlanLoading: false });
        },
        (error) => {
          this.displayToast(HttpHelper.getErrorMessage(error));
          this.setState({ changePlanLoading: false });
        }
      );
    } else {
      billingService.updateSubscribtion(
        this.state.planReached,
        current_plan?.subscription_id,
        maxUsers,
        (response) => {
          if (response.status === "success") {
            window.location.replace(response.url);
          } else {
            this.displayToast("Unknown error occurred");
          }
          this.setState({ changePlanLoading: false });
        },
        (error) => {
          this.displayToast(HttpHelper.getErrorMessage(error));
          this.setState({ changePlanLoading: false });
        }
      );
    }
  }

  upgradePlanSeat() {
    const current_plan = this.state.current_plan;

    if (!current_plan || current_plan.length === 0) {
      return;
    }

    this.setState({ changeSeatLoading: true });

    billingService.updateSubscribtion(
      current_plan.stripe_price_id,
      current_plan.subscription_id,
      this.state.seatNumber,
      (response) => {
        if (response.status === "success") {
          window.location.replace(response.url);
        } else {
          this.displayToast("Unknown error occurred");
        }
        this.setState({ changeSeatLoading: false });
      },
      (error) => {
        this.displayToast(HttpHelper.getErrorMessage(error));
        this.setState({ changeSeatLoading: false });
      }
    );
  }

  upgradeCapacity() {
    if (!this.state.capacityReached) {
      return; //TODO: set error
    }
    const current_capacity = this.state.current_capacity;

    const new_capacity = this.state.capacityOptions.find(
      (o) => o.stripePriceId == this.state.capacityReached
    );

    this.setState({ changeCapacityLoading: true });

    if (current_capacity) {
      if (current_capacity.stripe_price_id == this.state.capacityReached) {
        billingService.cancelSubscribtion(
          current_capacity.subscription_id,
          (response) => {
            if (response.status === "success") {
              window.location.replace(response.url);
            } else {
              this.displayToast("Unknown error occurred");
            }
            this.setState({ changeCapacityLoading: false });
          },
          (error) => {
            this.displayToast(HttpHelper.getErrorMessage(error));
            this.setState({ changeCapacityLoading: false });
          }
        );
      } else {
        billingService.updateSubscribtion(
          this.state.capacityReached,
          current_capacity.subscription_id,
          new_capacity.quantity,
          (response) => {
            if (response.status === "success") {
              window.location.replace(response.url);
            } else {
              this.displayToast("Unknown error occurred");
            }
            this.setState({ changeCapacityLoading: false });
          },
          (error) => {
            this.displayToast(HttpHelper.getErrorMessage(error));
            this.setState({ changeCapacityLoading: false });
          }
        );
      }
    } else {
      billingService.createSubscribtion(
        this.state.capacityReached,
        new_capacity.quantity,
        `${globalConstants.CLIENT_URL}/settings/plan-and-billing`,
        (response) => {
          if (response.status === "success") {
            window.location.replace(response.url);
          } else {
            this.displayToast("Unknown error occurred");
          }
          this.setState({ changeCapacityLoading: false });
        },
        (error) => {
          this.displayToast(HttpHelper.getErrorMessage(error));
          this.setState({ changeCapacityLoading: false });
        }
      );
    }
  }

  upgradeAi() {
    if (!this.state.aiReached) {
      return; //TODO: set error
    }
    const current_ai = this.state.current_ai;
    const aiReached = this.state.aiReached;

    const aiToCancel = current_ai.find(
      (ai) => ai.stripe_price_id === aiReached
    );

    this.setState({ changeAiLoading: true });
    if (aiToCancel) {
      billingService.cancelSubscribtion(
        aiToCancel.subscription_id,
        (response) => {
          if (response.status === "success") {
            window.location.replace(response.url);
          } else {
            this.displayToast("Unknown error occurred");
          }
          this.setState({ changeAiLoading: false });
        },
        (error) => {
          this.displayToast(HttpHelper.getErrorMessage(error));
          this.setState({ changeAiLoading: false });
        }
      );
    } else if (!aiToCancel) {
      billingService.createSubscribtion(
        aiReached,
        1,
        `${globalConstants.CLIENT_URL}/settings/plan-and-billing`,
        (response) => {
          if (response.status === "success") {
            window.location.replace(response.url);
          } else {
            this.displayToast("Unknown error occurred");
          }
          this.setState({ changeAiLoading: false });
        },
        (error) => {
          this.displayToast(HttpHelper.getErrorMessage(error));
          this.setState({ changeAiLoading: false });
        }
      );
    }
  }

  render() {
    const { t, account } = this.props;

    const totalReviews = this.context.accountDataManagerState.totalReviews;
    let maxReviews = this.context.accountDataManagerState.maxReviews;
    let manualReviews = this.context.accountDataManagerState.manualReviews;
    const reviewStatus =
      this.context.accountDataManagerState.reviewsQuotasStatus;

    const totalUsers = this.state.account.nb_user;
    let maxUsers = this.state.account.quantity;
    let manualSeat = this.context.accountDataManagerState.manualSeat;

    const planKey = this.context.accountDataManagerState.plan;
    const planFreq = this.state.current_plan?.interval;

    if (planKey == ACCOUNT_PLAN.MANUAL_BILLING) {
      if (this.state.account.manual_reviews != null) {
        maxReviews = this.state.account.manual_reviews;
      } else {
        maxReviews = this.state.account?.kpi_current_month?.max_reviews;
      }

      if (this.state.account.manual_seat != null) {
        maxUsers = this.state.account.manual_seat;
      } else {
        maxUsers = this.state.account?.kpi_current_month?.max_users || maxUsers;
      }
    }

    const quotasPercent = (totalReviews / maxReviews ?? 1) * 100;
    const usersPercent = (totalUsers / maxUsers ?? 1) * 100;
    const seatNumber = this.state.seatNumber;

    const isSaasPlan = ![
      ACCOUNT_PLAN.ENTERPRISE,
      ACCOUNT_PLAN.MANUAL_BILLING,
    ].includes(planKey);

    let included_reviews = this.state.freePlanReviews;

    if (this.state.current_plan) {
      included_reviews = this.state.current_plan.reviews;
    }

    const current_capacity = this.state.current_capacity;
    let current_capacity_price = null;
    let current_capacity_end_date = null;
    if (current_capacity) {
      current_capacity_price = new Intl.NumberFormat("fr", {
        style: "currency",
        currency: current_capacity.currency,
        minimumFractionDigits: 0,
      }).format(current_capacity.unit_amount.toFixed() / 100);

      current_capacity_end_date = format(
        new Date(current_capacity.current_period_end),
        "MMMM dd, yyyy"
      );
    }

    const reniewDate = moment
      .utc(account.expiration_date)
      .local()
      .format("MMMM DD, YYYY");

    let plan_price = null;
    if (this.state.current_plan && this.state.current_plan.unit_amount) {
      plan_price = new Intl.NumberFormat("fr", {
        style: "currency",
        currency: this.state.current_plan.currency,
        minimumFractionDigits: 0,
      }).format(this.state.current_plan.unit_amount.toFixed() / 100);
    }

    let reniewText = null;

    if (account.expiration_date) {
      if (this.state.current_plan?.status === "trialing") {
        reniewText = `Your trial period ends on ${reniewDate}`;
      } else {
        reniewText = `Your subscription will renew on ${reniewDate}`;
      }
    }
    let planText = (
      <>
        You are currently on the{" "}
        <span style={{ fontWeight: 600 }}>
          {this.state.current_plan?.product}
        </span>
        , billed per {planFreq}.
      </>
    );

    if (planKey == ACCOUNT_PLAN.MANUAL_BILLING) {
      planText = (
        <>
          You are currently on the{" "}
          <span style={{ fontWeight: 600 }}>
            Sensia Enterprise - Manual Billing
          </span>{" "}
          plan
        </>
      );
    }

    let capacity_quantity_reached = Number(included_reviews ?? 0);
    let unsubscribeReviewsAction = false;
    if (
      current_capacity &&
      current_capacity.stripe_price_id == this.state.capacityReached
    ) {
      unsubscribeReviewsAction = true;
    } else if (this.state.capacityReached) {
      const new_capacity = this.state.capacityOptions.find(
        (o) => o.stripePriceId == this.state.capacityReached
      );
      capacity_quantity_reached += new_capacity?.quantity;
    }

    let unsubscribeAiErrorMessage = null;
    if (this.state.aiReached) {
      const aiToCancel = this.state.current_ai.find(
        (ai) => ai.stripe_price_id == this.state.aiReached
      );

      if (aiToCancel && aiToCancel.model) {
        const accountProjectsIa = account.projects_ia;
        const key = Object.keys(accountProjectsIa).find(
          (k) => k.toLowerCase() === aiToCancel.model.toLowerCase()
        );
        const nbProjects = accountProjectsIa[key];
        if (nbProjects > 0) {
          unsubscribeAiErrorMessage = `${nbProjects} project${
            nbProjects > 1 ? "s" : ""
          } are using this IA. Delete projects using this AI to proceed.`;
        }
      }
    }

    let title = "";
    let subtitle = "";
    switch (planKey) {
      case ACCOUNT_PLAN.FREE:
        title = t(
          "Upgrade now to Pro Plan to get Deeper Insights and Advanced Analytics!"
        );
        subtitle = t(
          "Manage 3 projects, access detailed analytics, and create 1 custom classe. Collect data from all Sensia sources and easily import surveys. Elevate your marketing intelligence today!"
        );
        break;
      case ACCOUNT_PLAN.PRO:
        title = t(
          "Upgrade now to Supercharge your Analytics and Collaboration!"
        );
        subtitle = t(
          "Manage 5 projects, access even deeper analytics, and create up to 4 custom classes. collaborate with up to 3 users. Elevate your marketing capabilities today!"
        );
        break;
      case ACCOUNT_PLAN.BUSINESS:
        title = t(
          "Upgrade now to Unlock Limitless Potential and Full Integration!"
        );
        subtitle = t(
          "enjoy unlimited projects, users, and custom classes. Access 500 data sources per project, including advanced integrations with cloud apps, eCommerce reviews, and more."
        );
        break;
      case ACCOUNT_PLAN.ENTERPRISE:
        title = t("Maximize the Full Potential of your Enterprise Plan");
        subtitle = t(
          "With Sensia Enterprise, enjoy unlimited projects, users, custom classes, and advanced integrations. Need help or want to optimize further? We're here for you!"
        );
        break;
    }

    return (
      <Fragment>
        {/* PLAN */}
        <Box
          sx={{
            display: "flex",
            padding: "25px",
            flexDirection: "column",
            alignItems: "flex-start",
            borderRadius: "9px",
            background: "#FFF",
            boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "14px",
                alignSelf: "stretch",
                justifyContent: "space-between",
              }}
            >
              <STypography
                sx={{
                  fontSize: "24px",
                  fontWeight: 700,
                  gap: "3px",
                }}
              >
                {[
                  ACCOUNT_PLAN.ENTERPRISE,
                  ACCOUNT_PLAN.ENTERPRISE_SAAS,
                  ACCOUNT_PLAN.MANUAL_BILLING,
                ].includes(planKey) && "Enterprise"}
                {![
                  ACCOUNT_PLAN.ENTERPRISE,
                  ACCOUNT_PLAN.ENTERPRISE_SAAS,
                  ACCOUNT_PLAN.MANUAL_BILLING,
                ].includes(planKey) && `${planKey} plan`}
              </STypography>

              {![ACCOUNT_PLAN.ENTERPRISE, ACCOUNT_PLAN.MANUAL_BILLING].includes(
                planKey
              ) && (
                <STypography
                  className="light-link-blue"
                  sx={{
                    color: " #306ed6",
                    fontSize: "15px",
                    fontWeight: 500,
                    textAlign: "end",
                  }}
                  onClick={() => this.props.history.push(ROUTES.PLAN_CHOOSE)}
                >
                  {t("View all plan")}
                </STypography>
              )}
            </Box>
            {planKey != ACCOUNT_PLAN.FREE && (
              <>
                <STypography
                  sx={{
                    flex: "1 0 0",
                    textAlign: "start",
                    color: "#000",
                    fontWeight: 400,
                    mt: "22px",
                  }}
                >
                  {planText}
                </STypography>
                {reniewText && (
                  <STypography
                    sx={{
                      flex: "1 0 0",
                      textAlign: "start",
                      color: "#000",
                      fontWeight: 400,
                      mt: "5px",
                    }}
                  >
                    {reniewText}
                  </STypography>
                )}
              </>
            )}
          </Box>
          {![
            // ACCOUNT_PLAN.FREE,
            ACCOUNT_PLAN.ENTERPRISE,
            ACCOUNT_PLAN.MANUAL_BILLING,
          ].includes(planKey) && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  gap: "24px",
                  alignSelf: "stretch",
                  borderRadius: "6px",
                  mt: "10px",
                }}
              >
                <Select
                  placeholder="Upgrade your plan"
                  indicator={<ArrowDropDown />}
                  value={this.state.planReached}
                  onChange={(e, newValue) =>
                    this.setState({ planReached: newValue })
                  }
                  sx={{
                    borderRadius: "4px",
                    width: "100%",
                    maxWidth: "299px",
                    background: "transparent",
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    color: "#000",
                    [`& .${selectClasses.indicator}`]: {
                      transition: "0.2s",
                      [`&.${selectClasses.expanded}`]: {
                        transform: "rotate(-180deg)",
                      },
                    },
                  }}
                >
                  {this.state.planOptions?.map((plan) => {
                    return (
                      <Option
                        key={plan.stripePriceId}
                        value={plan.stripePriceId}
                      >{`${
                        planKey.toUpperCase() == plan.level
                          ? "Switch to"
                          : "Upgrade to"
                      } ${plan.name}${
                        plan.interval == "year" ? " (Annual)" : ""
                      }`}</Option>
                    );
                  })}
                  <Option value={"Cancel"}>{t("Cancel Plan")}</Option>
                </Select>
                {!this.state.changePlanLoading && (
                  <Button
                    variant="contained"
                    disabled={
                      !this.state.planReached ||
                      (totalReviews > this.state.freePlanReviews &&
                        this.state.planReached === "Cancel")
                    }
                    sx={{ borderRadius: "6px", textTransform: "none" }}
                    onClick={() => this.upgradePlan()}
                  >
                    {t("Change Plan")}
                  </Button>
                )}
                {this.state.changePlanLoading && (
                  <Box>
                    <Loader />
                  </Box>
                )}
              </Box>
              {totalReviews > 500 && this.state.planReached === "Cancel" && (
                <STypography
                  sx={{ color: "#E04F77", fontWeight: 400, mt: "20px" }}
                >
                  {t(
                    `You’ve exceeded the 500 review limit for the Free plan. Please free up space to proceed`
                  )}
                </STypography>
              )}
            </>
          )}
          {/* {planKey === ACCOUNT_PLAN.FREE && (
            <Box
              sx={{
                mt: "22px",
                display: "flex",
                padding: "15px",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
                alignSelf: "stretch",
                borderRadius: "6px",
                background: "#FDE7D4",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  gap: "15px",
                }}
              >
                <STypography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {t("Upgrade now for Deeper Insights and Advanced Analytics!")}
                </STypography>
                <STypography
                  sx={{
                    color: "#7F7F7F",
                    fontWeight: 400,
                  }}
                >
                  {t(
                    "Unlock more AI customization, extra review storage, and powerful sharing options. Collect data from all Sensia sources and import surveys with ease. Elevate your marketing intelligence today!"
                  )}
                </STypography>
              </Box>
              <Button
                sx={{
                  mt: "5px",
                  borderRadius: "6px",
                  background:
                    "linear-gradient(90deg, #F9B16E 0%, #F68080 100%)",
                  display: "flex",
                  width: "126px",
                  height: "26px",
                }}
                onClick={() => this.props.history.push(ROUTES.PLAN_CHOOSE)}
              >
                <STypography
                  sx={{
                    color: "#FFF",
                    fontSize: "15px",
                    textTransform: "none",
                  }}
                >
                  {t("Upgrade")}
                </STypography>
              </Button>
            </Box>
          )} */}
          {[ACCOUNT_PLAN.ENTERPRISE, ACCOUNT_PLAN.MANUAL_BILLING].includes(
            planKey
          ) && (
            <Box
              sx={{
                mt: "22px",
                display: "flex",
                padding: "15px",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
                alignSelf: "stretch",
                borderRadius: "6px",
                background: "#E2E2FB",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  gap: "15px",
                }}
              >
                <STypography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {t("Maximize the Full Potential of your Enterprise Plan!")}
                </STypography>
                <STypography
                  sx={{
                    color: "#000",
                    fontWeight: 400,
                  }}
                >
                  {t(
                    "With Sensia Enterprise, enjoy unlimited projects, users, custom classes, and advanced integrations. Need help or want to optimize further? We're here for you!"
                  )}
                </STypography>

                <Button
                  sx={{
                    mt: "5px",
                    borderRadius: "6px",
                    background: "#7879F1",
                    display: "flex",
                    width: "208px",
                    height: "30px",
                    "&:hover": {
                      background: "#6B6DF3",
                    },
                  }}
                  onClick={() =>
                    IntercomHelper.trackEvent("contact-success-team")
                  }
                >
                  <STypography
                    sx={{
                      color: "#FFF",
                      fontSize: "15px",
                      textTransform: "none",
                    }}
                  >
                    {t("Contact our success team")}
                  </STypography>
                </Button>
              </Box>
            </Box>
          )}
        </Box>

        {/* USERS */}
        <Box
          sx={{
            mt: "26px",
            display: "flex",
            padding: "25px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "25px",
            borderRadius: "9px",
            background: "#FFF",
            boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "50px",
                height: "50px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "6px",
                background: "#FDE7D4",
              }}
            >
              <img src={add_user_icon} height={"27px"} />
            </Box>

            <STypography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {t("User")}
            </STypography>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                padding: "5px 0px",
                alignItems: "flex-start",
                gap: "17px",
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <STypography
                sx={{
                  fontSize: "24px",
                  fontWeight: 700,
                }}
              >
                {planKey !== ACCOUNT_PLAN.MANUAL_BILLING &&
                  totalUsers?.toLocaleString()}{" "}
                {planKey === ACCOUNT_PLAN.MANUAL_BILLING &&
                  manualSeat == null &&
                  maxUsers?.toLocaleString()}{" "}
                {planKey === ACCOUNT_PLAN.MANUAL_BILLING &&
                  manualSeat != null &&
                  totalUsers?.toLocaleString()}{" "}
                {planKey === ACCOUNT_PLAN.FREE && (
                  <span className="font-size-15 font-weight-600">used</span>
                )}
                {![ACCOUNT_PLAN.FREE, ACCOUNT_PLAN.MANUAL_BILLING].includes(
                  planKey
                ) && (
                  <span className="font-size-15 font-weight-600">
                    of {maxUsers?.toLocaleString()} used
                  </span>
                )}
                {planKey == ACCOUNT_PLAN.MANUAL_BILLING &&
                  manualSeat != null && (
                    <span className="font-size-15 font-weight-600">
                      of {manualSeat?.toLocaleString()} used
                    </span>
                  )}
                {planKey == ACCOUNT_PLAN.MANUAL_BILLING &&
                  manualSeat == null && (
                    <span className="font-size-15 font-weight-600">
                      used this month
                    </span>
                  )}
              </STypography>

              <LinearProgress
                sx={{
                  width: "100%",
                  height: "10px",
                  backgroundColor: "#27C98699",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor:
                      usersPercent > 100 && planKey !== ACCOUNT_PLAN.FREE
                        ? "#F78F64"
                        : "#27C986",
                  },
                  borderRadius: 5,
                }}
                variant="determinate"
                value={usersPercent > 100 ? 100 : usersPercent}
              />
              {planKey == ACCOUNT_PLAN.MANUAL_BILLING && manualSeat == null && (
                <STypography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {`Current usage : ${totalUsers?.toLocaleString()} users`}
                </STypography>
              )}
            </Box>
            {![
              ACCOUNT_PLAN.FREE,
              ACCOUNT_PLAN.ENTERPRISE,
              ACCOUNT_PLAN.MANUAL_BILLING,
            ].includes(planKey) && (
              <>
                <STypography
                  sx={{ mt: "37px", fontSize: "15px", fontWeight: 400 }}
                >
                  {t(
                    "Add users to strengthen your team and speed up projects. More users mean more collaboration and efficiency. Boost your team now!"
                  )}
                </STypography>
                <Box
                  sx={{
                    display: "flex",
                    mt: "20px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <STypography sx={{ fontSize: "15px" }}>
                      {t("Adjust Seat")}
                    </STypography>
                    <ButtonGroup
                      variant="outlined"
                      aria-label="Basic button group"
                      sx={{
                        borderRadius: "4px",
                        border: "1px solid #D2D2D2",
                      }}
                    >
                      <Button
                        disabled={seatNumber <= 1}
                        onClick={() => {
                          let newSeatNumber = seatNumber - 1;
                          if (newSeatNumber <= 0) {
                            newSeatNumber = 1;
                          }
                          this.setState({
                            seatNumber: newSeatNumber,
                            usersStatus:
                              newSeatNumber < totalUsers ? "error" : "success",
                          });
                        }}
                        sx={{ border: "1px solid #D2D2D2" }}
                      >
                        -
                      </Button>
                      <Divider orientation="vertical" flexItem />
                      <STypography
                        sx={{
                          fontSize: "15px",
                          width: "34px",
                          textAlign: "center",
                          alignContent: "center",
                          border: "1px solid #D2D2D2",
                        }}
                      >
                        {seatNumber}
                      </STypography>
                      <Divider orientation="vertical" flexItem />
                      <Button
                        onClick={() => {
                          const newSeatNumber = seatNumber + 1;
                          this.setState({
                            seatNumber: newSeatNumber,
                            usersStatus:
                              newSeatNumber < totalUsers ? "error" : "success",
                          });
                        }}
                        sx={{ border: "1px solid #D2D2D2" }}
                      >
                        +
                      </Button>
                    </ButtonGroup>
                  </Box>
                  {!this.state.changeSeatLoading && (
                    <Button
                      variant="contained"
                      disabled={
                        maxUsers === seatNumber ||
                        this.state.usersStatus === "error"
                      }
                      sx={{ borderRadius: "6px", textTransform: "none" }}
                      onClick={() => this.upgradePlanSeat()}
                    >
                      {seatNumber >= maxUsers
                        ? t("Add seat")
                        : t("Remove seat")}
                    </Button>
                  )}
                  {this.state.changeSeatLoading && (
                    <Box>
                      <Loader />
                    </Box>
                  )}
                </Box>
                {this.state.usersStatus == "error" && (
                  <STypography
                    sx={{ mt: "20px", fontWeight: 400, color: "#E04F77" }}
                  >
                    {t(
                      "Cannot reduce seats below active users. Please deactivate users to proceed."
                    )}
                  </STypography>
                )}
              </>
            )}
            {planKey === ACCOUNT_PLAN.ENTERPRISE && (
              <STypography
                sx={{
                  mt: "20px",
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              >
                {t("Need more seat ?")}{" "}
                <span
                  onClick={() => IntercomHelper.trackEvent("contact-sales")}
                  style={{
                    cursor: "pointer",
                    color: "#3A79E6",
                    fontSize: "15px",
                    textTransform: "none",
                  }}
                >
                  {t("Contact sales")}
                </span>
              </STypography>
            )}
          </Box>
        </Box>

        {/* REVIEWS */}
        <Box
          sx={{
            mt: "26px",
            display: "flex",
            padding: "25px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "25px",
            borderRadius: "9px",
            background: "#FFF",
            boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "50px",
                height: "50px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "6px",
                background: "#FDE7D4",
              }}
            >
              <ReviewsIcon sx={{ height: "27px", color: "#FF9378" }} />
            </Box>

            <STypography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {t("Reviews")}
            </STypography>
          </Box>

          <Box
            sx={{
              display: "flex",
              padding: "5px 0px",
              alignItems: "flex-start",
              gap: "17px",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Box>
              <STypography
                sx={{
                  fontSize: "24px",
                  fontWeight: 700,
                }}
              >
                {planKey !== ACCOUNT_PLAN.MANUAL_BILLING &&
                  totalReviews?.toLocaleString()}{" "}
                {planKey === ACCOUNT_PLAN.MANUAL_BILLING &&
                  manualReviews == null &&
                  maxReviews?.toLocaleString()}{" "}
                {planKey === ACCOUNT_PLAN.MANUAL_BILLING &&
                  manualReviews != null &&
                  totalReviews?.toLocaleString()}{" "}
                {planKey != ACCOUNT_PLAN.MANUAL_BILLING && (
                  <span className="font-size-15 font-weight-600">
                    of {maxReviews?.toLocaleString()} used
                  </span>
                )}
                {planKey == ACCOUNT_PLAN.MANUAL_BILLING &&
                  manualReviews != null && (
                    <span className="font-size-15 font-weight-600">
                      of {maxReviews?.toLocaleString()} used
                    </span>
                  )}
                {planKey == ACCOUNT_PLAN.MANUAL_BILLING &&
                  manualReviews == null && (
                    <span className="font-size-15 font-weight-600">
                      used this month
                    </span>
                  )}
              </STypography>
              {isSaasPlan && current_capacity && (
                <STypography sx={{ color: "#7F7F7F", fontWeight: 400 }}>
                  {`(${included_reviews} included in your plan)`}
                </STypography>
              )}
            </Box>

            <LinearProgress
              sx={{
                width: "100%",
                height: "10px",
                backgroundColor: "#27C98699",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: quotasPercent > 100 ? "#F78F64" : "#27C986",
                },
                borderRadius: 5,
              }}
              variant="determinate"
              value={quotasPercent > 100 ? 100 : quotasPercent}
            />
            {planKey == ACCOUNT_PLAN.MANUAL_BILLING &&
              manualReviews == null && (
                <STypography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {`Current usage : ${totalReviews?.toLocaleString()} reviews`}
                </STypography>
              )}
            <Box sx={{ width: "100%" }}>
              {reviewStatus == "error" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <InfoOutlinedIcon
                    sx={{ width: "18px", height: "18px", color: "#000" }}
                  />
                  <STypography sx={{ fontWeight: 400 }}>
                    {t(
                      "Storage Limit Exceeded. Upgrade to continue exploring and get the latest updates."
                    )}
                  </STypography>
                </Box>
              )}
            </Box>
          </Box>

          {isSaasPlan && (
            <Box
              sx={{
                width: "100%",
                borderRadius: "4px",
                borderTop: "5px solid #F78F64",
                boxShadow: "0px 0px 0px 1px #E0E0E0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "20px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "20px",
                  alignSelf: "stretch",
                }}
              >
                <STypography
                  sx={{
                    color: "#FF9378",
                    fontSize: "15px",
                    fontWeight: 600,
                  }}
                >
                  {t("ADDITIONAL STORAGE")}
                </STypography>
                <STypography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                    lineHeight: "22px",
                  }}
                >
                  {t(
                    "Increase your storage capacity to analyze more reviews. More space means more precise insights. Don't miss any opportunities, expand your storage!"
                  )}
                </STypography>
                {current_capacity && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      alignSelf: "stretch",
                    }}
                  >
                    <STypography
                      sx={{
                        display: "flex",
                        width: "83px",
                        height: "19px",
                        padding: "4px 8px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "22px",
                        background: "#FF9378",
                        color: "#FFF",
                        fontWeight: 600,
                      }}
                    >
                      {t("Current offer")}
                    </STypography>
                    <STypography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                      }}
                    >
                      {current_capacity.quantity?.toLocaleString()}{" "}
                      {t("reviews")}
                    </STypography>
                    <STypography sx={{ fontWeight: 400 }}>
                      {`Next renewal on ${current_capacity_end_date} for ${current_capacity_price}`}
                    </STypography>
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    alignSelf: "stretch",
                    justifyContent: "space-between",
                  }}
                >
                  <Select
                    placeholder="Pick the extra storage you need"
                    indicator={<ArrowDropDown />}
                    onChange={(e, newValue) =>
                      this.setState({ capacityReached: newValue })
                    }
                    value={this.state.capacityReached}
                    renderValue={(option) => {
                      const selected_capacity = this.state.capacityOptions.find(
                        (c) => c.stripePriceId === option.value
                      );

                      let subscribed = false;
                      if (
                        current_capacity &&
                        current_capacity.stripe_price_id == option.value
                      ) {
                        subscribed = true;
                      }

                      return (
                        <STypography
                          sx={{ fontSize: "15px", fontWeight: 400 }}
                        >{`${
                          subscribed ? "Unsubscribe" : "Subscribe"
                        } ${selected_capacity.quantity?.toLocaleString()} reviews`}</STypography>
                      );
                    }}
                    sx={{
                      borderRadius: "4px",
                      width: "100%",
                      maxWidth: "299px",
                      background: "transparent",
                      fontFamily: "Inter",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      color: "#000",
                      [`& .${selectClasses.indicator}`]: {
                        transition: "0.2s",
                        [`&.${selectClasses.expanded}`]: {
                          transform: "rotate(-180deg)",
                        },
                      },
                    }}
                  >
                    {this.state.capacityOptions?.map((capacity) => {
                      const price = new Intl.NumberFormat("fr", {
                        style: "currency",
                        currency: capacity.currency,
                        minimumFractionDigits: 0,
                      }).format(capacity.unitAmount.toFixed());

                      let subscribed = false;
                      if (
                        current_capacity &&
                        current_capacity.stripe_price_id ==
                          capacity.stripePriceId
                      ) {
                        subscribed = true;
                      }
                      return (
                        <Option
                          key={capacity.stripePriceId}
                          value={capacity.stripePriceId}
                          quantity={capacity.quantity}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <STypography
                              sx={{ fontSize: "15px", fontWeight: 400 }}
                            >{`${capacity.quantity?.toLocaleString()} reviews`}</STypography>
                            <STypography
                              sx={{ fontWeight: 400, color: "#7F7F7F" }}
                            >{`${
                              subscribed
                                ? "Current offer"
                                : current_capacity
                                ? "Change"
                                : "Add"
                            } for ${price}/mth`}</STypography>
                          </Box>
                          <Box
                            sx={{
                              position: "absolute",
                              right: 0,
                              left: 0,
                              top: 0,
                              bottom: 0,
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              opacity: subscribed ? 1 : 0,
                              "&:hover": {
                                opacity: 1,
                              },
                            }}
                          >
                            <STypography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 400,
                                color: "white",
                                display: "flex",
                                padding: "3px 10px",
                                mr: "16px",
                                height: "22px",
                                alignItems: "center",
                                borderRadius: "16px",
                                background: "#FF9378",
                              }}
                            >
                              {subscribed ? t("Unsubscribe") : t("Subscribe")}
                            </STypography>
                          </Box>
                        </Option>
                      );
                    })}

                    <Box
                      sx={{
                        mb: "-6px",
                        p: "6px 16px",
                        background: "#F8F9FC",
                        cursor: "pointer",
                      }}
                      onClick={() => IntercomHelper.trackEvent("contact-sales")}
                    >
                      <STypography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 600,
                        }}
                      >
                        {t("Need more storage ?")}
                      </STypography>
                      <STypography sx={{ fontWeight: 400 }}>
                        {t("Contact us ")}
                      </STypography>
                    </Box>
                  </Select>
                  {this.state.capacityReached &&
                    this.state.changeCapacityLoading && (
                      <Box>
                        <Loader />
                      </Box>
                    )}
                  {this.state.capacityReached &&
                    !this.state.changeCapacityLoading && (
                      <Button
                        variant="contained"
                        disabled={capacity_quantity_reached < totalReviews}
                        sx={{ borderRadius: "6px", textTransform: "none" }}
                        onClick={() => this.upgradeCapacity()}
                      >
                        {t("Confirm")}
                      </Button>
                    )}
                </Box>
                {this.state.capacityReached &&
                  unsubscribeReviewsAction &&
                  capacity_quantity_reached < totalReviews && (
                    <STypography sx={{ color: "#E04F77", fontWeight: 400 }}>
                      {`You’ve exceeded the ${included_reviews} review limit include in your ${planKey?.toLowerCase()} plan. Please free up space to proceed`}
                    </STypography>
                  )}
                {this.state.capacityReached &&
                  !unsubscribeReviewsAction &&
                  capacity_quantity_reached < totalReviews && (
                    <STypography sx={{ color: "#E04F77", fontWeight: 400 }}>
                      {`You’ve exceeded the ${capacity_quantity_reached} review limit. Please free up space to proceed`}
                    </STypography>
                  )}
              </Box>
            </Box>
          )}
          {planKey === ACCOUNT_PLAN.ENTERPRISE && (
            <STypography
              sx={{
                fontSize: "15px",
                fontWeight: 400,
              }}
            >
              {t("Need more storage?")}{" "}
              <span
                style={{ color: "#3A79E6", cursor: "pointer" }}
                onClick={() => IntercomHelper.trackEvent("contact-sales")}
              >
                {t("Contact sales")}
              </span>
            </STypography>
          )}
        </Box>

        {/* IA */}
        <Box
          sx={{
            mt: "26px",
            display: "flex",
            padding: "25px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            borderRadius: "9px",
            background: "#FFF",
            boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "50px",
                  height: "50px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  background: "#FDE7D4",
                }}
              >
                <img alt="Reviews" height="25px" src={star_icon} />
              </Box>
              <STypography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {t("IA")}
              </STypography>
            </Box>
          </Box>

          {this.state.account.product_categories
            .filter((pc) => pc.association_type !== "Stripe")
            .map((pc) => {
              return (
                <Box
                  key={pc.key}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <STypography
                    sx={{
                      fontWeight: 600,
                      letterSpacing: "0.15px",
                      fontSize: "15px",
                    }}
                  >
                    {pc.title}
                  </STypography>
                  <STypography sx={{ fontWeight: 400 }}>
                    {[
                      ACCOUNT_PLAN.ENTERPRISE,
                      ACCOUNT_PLAN.ENTERPRISE_SAAS,
                      ACCOUNT_PLAN.MANUAL_BILLING,
                    ].includes(planKey) && ` included in your Enterprise plan`}
                    {![
                      ACCOUNT_PLAN.ENTERPRISE,
                      ACCOUNT_PLAN.ENTERPRISE_SAAS,
                      ACCOUNT_PLAN.MANUAL_BILLING,
                    ].includes(planKey) && ` included in your ${planKey} plan`}
                  </STypography>
                </Box>
              );
            })}

          {this.state.current_ai?.map((ai) => {
            const ai_end_date = format(
              new Date(ai.current_period_end),
              "MMMM dd, yyyy"
            );

            const ai_price = new Intl.NumberFormat("fr", {
              style: "currency",
              currency: ai.currency,
              minimumFractionDigits: 0,
            }).format(ai.unit_amount.toFixed() / 100);

            return (
              <Box
                key={ai.product}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
              >
                <STypography
                  sx={{
                    fontWeight: 600,
                    letterSpacing: "0.15px",
                    fontSize: "15px",
                  }}
                >
                  {ai.product}
                </STypography>
                {isSaasPlan && (
                  <STypography sx={{ fontWeight: 400 }}>
                    {`Next renewal on ${ai_end_date} for ${ai_price}`}
                  </STypography>
                )}
              </Box>
            );
          })}

          {isSaasPlan && (
            <Box
              sx={{
                mt: "40px",
                width: "100%",
                borderRadius: "4px",
                borderTop: "5px solid #F78F64",
                boxShadow: "0px 0px 0px 1px #E0E0E0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "20px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "20px",
                  alignSelf: "stretch",
                }}
              >
                <STypography
                  sx={{
                    color: "#FF9378",
                    fontSize: "15px",
                    fontWeight: 600,
                  }}
                >
                  {t("ADDITIONAL IA")}
                </STypography>
                <STypography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                    lineHeight: "22px",
                  }}
                >
                  {t(
                    "Add AI to enhance your analyses. More AI means more detailed and personalized insights. Amplify your marketing intelligence today!"
                  )}
                </STypography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    alignSelf: "stretch",
                    justifyContent: "space-between",
                  }}
                >
                  <Select
                    placeholder="Pick the AI tailored to your needs"
                    indicator={<ArrowDropDown />}
                    onChange={(e, newValue) =>
                      this.setState({ aiReached: newValue })
                    }
                    value={this.state.aiReached}
                    renderValue={(option) => {
                      const selected_ai = this.state.aiOptions.find(
                        (c) => c.stripePriceId === option.value
                      );

                      let subscribed = false;
                      if (
                        this.state.current_ai.find(
                          (c) => c.stripe_price_id === option.value
                        )
                      ) {
                        subscribed = true;
                      }

                      return (
                        <STypography
                          sx={{ fontSize: "15px", fontWeight: 400 }}
                        >{`${subscribed ? "Unsubscribe" : "Subscribe"} ${
                          selected_ai.name
                        }`}</STypography>
                      );
                    }}
                    sx={{
                      borderRadius: "4px",
                      width: "100%",
                      maxWidth: "299px",
                      background: "transparent",
                      fontFamily: "Inter",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      color: "#000",
                      [`& .${selectClasses.indicator}`]: {
                        transition: "0.2s",
                        [`&.${selectClasses.expanded}`]: {
                          transform: "rotate(-180deg)",
                        },
                      },
                    }}
                  >
                    {this.state.aiOptions?.map((ai) => {
                      const price = new Intl.NumberFormat("fr", {
                        style: "currency",
                        currency: ai.currency,
                        minimumFractionDigits: 0,
                      }).format(ai.unitAmount.toFixed());

                      let subscribed = false;
                      if (
                        this.state.current_ai.find(
                          (c) => c.stripe_price_id === ai.stripePriceId
                        )
                      ) {
                        subscribed = true;
                      }
                      return (
                        <Option
                          key={ai.stripePriceId}
                          value={ai.stripePriceId}
                          quantity={ai.quantity}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              width: "fit-content",
                              pr: "100px",
                            }}
                          >
                            <STypography
                              sx={{ fontSize: "15px", fontWeight: 400 }}
                            >{`${ai.name}`}</STypography>
                            <STypography
                              sx={{ fontWeight: 400, color: "#7F7F7F" }}
                            >{`For ${price}/mth`}</STypography>
                          </Box>
                          <Box
                            sx={{
                              position: "absolute",
                              right: 0,
                              left: 0,
                              top: 0,
                              bottom: 0,
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              opacity: subscribed ? 1 : 0,
                              "&:hover": {
                                opacity: 1,
                              },
                            }}
                          >
                            <STypography
                              sx={{
                                fontSize: "13px",
                                fontWeight: 400,
                                color: "white",
                                display: "flex",
                                padding: "3px 4px",
                                mr: "16px",
                                height: "22px",
                                alignItems: "center",
                                borderRadius: "16px",
                                background: "#FF9378",
                              }}
                            >
                              {subscribed ? t("Unsubscribe") : t("Subscribe")}
                            </STypography>
                          </Box>
                        </Option>
                      );
                    })}
                  </Select>
                  {this.state.aiReached && this.state.changeAiLoading && (
                    <Box>
                      <Loader />
                    </Box>
                  )}
                  {this.state.aiReached && !this.state.changeAiLoading && (
                    <Button
                      variant="contained"
                      disabled={
                        unsubscribeAiErrorMessage || this.state.changeAiLoading
                      }
                      sx={{ borderRadius: "6px", textTransform: "none" }}
                      onClick={() => this.upgradeAi()}
                    >
                      {t("Confirm")}
                    </Button>
                  )}
                </Box>
                {unsubscribeAiErrorMessage && (
                  <STypography
                    sx={{
                      color: "#E04F77",
                      fontWeight: 400,
                    }}
                  >
                    {unsubscribeAiErrorMessage}
                  </STypography>
                )}
              </Box>
            </Box>
          )}
          {[ACCOUNT_PLAN.ENTERPRISE, ACCOUNT_PLAN.MANUAL_BILLING].includes(
            planKey
          ) && (
            <STypography
              sx={{
                mt: "20px", // gap 20 + mt 20
                fontSize: "15px",
                fontWeight: 400,
              }}
            >
              {t("Need more IA?")}{" "}
              <span
                style={{ color: "#3A79E6", cursor: "pointer" }}
                onClick={() => IntercomHelper.trackEvent("contact-sales")}
              >
                {t("Contact sales")}
              </span>
            </STypography>
          )}
        </Box>

        <div className="padding-top-50" />
      </Fragment>
    );
  }
}

export default withTranslation()(OverviewTab);
