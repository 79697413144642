import Tooltip from "@mui/joy/Tooltip";
import InterrogationPicto from "../../res/images/icons/interrogation.svg";

const STooltip = ({
  icon = InterrogationPicto,
  iconSize = "24px",
  iconStyle = { marginLeft: "5px" },
  ...props
}) => (
  <Tooltip
    {...props}
    sx={{
      ...props.sx,
      maxWidth: "400px",
      color: "#FFF",
      fontFamily: "Inter",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0.14px",
      background: "rgba(97, 97, 97, 1)",
    }}
  >
    <img style={iconStyle} width={iconSize} src={icon} alt="" />
  </Tooltip>
);

export default STooltip;
