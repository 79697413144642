import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import { history } from "../app/helpers";
import moment from "moment-timezone";

import { ROUTES } from "./routing";
import PrivateRoute from "../components/PrivateRoute";

import LoginView from "../views/unconnected/login/LoginView";
import LogoutView from "../views/unconnected/logout/LogoutView";

import ForgotPasswordView from "../views/unconnected/forgotPassword/ForgotPasswordView";
import ForgotPasswordCheckYourEmailView from "../views/unconnected/forgotPassword/ForgotPasswordCheckYourEmailView";

import SignupView from "../views/unconnected/signup/SignupView";
import SignupStep2View from "../views/unconnected/signup/SignupStep2View";
import SignupStep3View from "../views/unconnected/signup/SignupStep3View";
import SignupStep4View from "../views/unconnected/signup/SignupStep4View";

import RegisterInvitationView from "../views/unconnected/registerInvitation/RegisterInvitationView";
import ForgotPasswordSetNewView from "../views/unconnected/forgotPassword/ForgotPasswordSetNewView";
import UserGuideView from "../views/settings/userGuide/UserGuideView";

import {
  ToastProvider,
  SlideSlideToastRoot,
  GlossyToastContent,
} from "react-cooked-bread";

import LoadingView from "../views/loading/LoadingView";

import HomeView from "../views/home/HomeView";
import CreateProjectView from "../views/createProject/CreateProjectView";
import ManageClassesView from "../views/manageClasses/ManageClassesView";
import ProjectView from "../views/project/projectView";
import ProjectWorkspaceView from "../views/projectWorkspace/ProjectWorkspaceView";
import AddMediaView from "../views/addMedia/AddMediaView";
import SelectSheetView from "../views/addMedia/SelectSheetView";
import SelectSheetFields from "../views/addMedia/SelectSheetFields";
import EditMediaCaption from "../views/editMediaCaption/EditMediaCaptionView";
import EditClassView from "../views/editClass/EditClassView";
import AddClassView from "../views/addClass/AddClassView";
import ConnectivityListener from "../listeners/ConnectivityListener";
import ToastedComponent from "../components/ToastedComponent";
import NotFoundView from "../views/errors/NotFoundView";
import Error500View from "../views/errors/Error500View";
import Error403View from "../views/errors/Error403View";
import { useWebsiteTheme } from "../listeners/easter/useWebsiteTheme";
import ProjectReportView from "../views/projectReport/ProjectReportView";
import RefineProjectView from "../views/refineProject/RefineProjectView";
import DefaultAccountSettingsView from "../views/settings/defaultAccountSettings/DefaultAccountSettingsView";
import ProfileAndPreferencesView from "../views/settings/profileAndPreferences/ProfileAndPreferencesView";
import UsersAndTeamsView from "../views/settings/usersAndTeams/UsersAndTeamsView";
import AccountsAndBillingView from "../views/settings/accountsAndBilling/AccountsAndBillingView";

import ChoosePlanView from "../views/plan/choose/ChoosePlanView";
import RedirectView from "../views/redirect/RedirectView";

import PublicRoute from "../components/PublicRoute";
import { hotjar } from "react-hotjar";
import { globalConstants } from "./constants";
import BackOfficeView from "../views/backOffice/BackOfficeView";
import DowngradePlanView from "../views/plan/downgrade/DowngradePlanView";
import DowngradeResultView from "../views/plan/downgradeResult/DowngradeResultView";
import PurchaseResultView from "../views/plan/purchaseResult/PurchaseResultView";
import ContactYourAdminView from "../views/plan/contactYourAdmin/ContactYourAdminView";
import TestGraphComponents from "../views/testGraphComponents/testGraphComponents";
import ModelsScoreView from "../views/models/ModelsScoreView";
import ModelsEmotionView from "../views/models/ModelsEmotionView";
import ModelsAnalyseView from "../views/models/ModelsAnalyseView";
import AddMediaConfirmationView from "../views/addMedia/AddMediaConfirmationView";
import ModelSettingsView from "../views/models/ModelSettingsView";

import ProjectInsightsView from "../views/projectInsights/ProjectInsightsView";
import ProjectNewInsightsView from "../views/projectNewInsight/projectNewInsight";
import CreateAccountView from "../views/unconnected/signup/CreateAccountView";
import SearchMediaView from "../views/addMedia/SearchMediaView";
import SubscriptionSuccessView from "../views/plan/SubscriptionSuccessView";
import { ProjectDataManager } from "../dataManagers/projectDataManager";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "Now",
    ss: "Now",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    w: "1 week",
    ww: "%d weeks",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

const App = () => {
  if (globalConstants.ENV === "prod") {
    hotjar.initialize(2658164, 6);
  }
  useWebsiteTheme();
  return (
    <ToastProvider
      toastRoot={SlideSlideToastRoot}
      toastContent={GlossyToastContent}
    >
      <Suspense fallback={<LoadingView />}>
        <Router history={history}>
          <Switch>
            <PrivateRoute exact path={ROUTES.HOME} component={HomeView} />
            <PrivateRoute
              exact
              path={ROUTES.SUBSCRIPTION_SUCCESS}
              component={SubscriptionSuccessView}
            />
            <PrivateRoute
              exact
              path={ROUTES.CREATE_PROJECT}
              component={CreateProjectView}
            />
            <PrivateRoute
              exact
              path={ROUTES.EDIT_PROJECT}
              component={CreateProjectView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_EDIT}
              component={ManageClassesView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_REFINE}
              component={RefineProjectView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_DETAIL}
              component={ProjectView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_WORKSPACE}
              component={ProjectWorkspaceView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_MEDIA_WORKSPACE}
              component={ProjectWorkspaceView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_MEDIA_WORKSPACE_CLASS}
              component={ProjectWorkspaceView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_SEARCH_MEDIA}
              component={SearchMediaView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_ADD_MEDIA}
              component={AddMediaView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_ADD_MEDIA_CONFIRM}
              component={AddMediaConfirmationView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_ADD_MEDIA_SELECT}
              component={SelectSheetView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_ADD_MEDIA_SELECT_FIELDS}
              component={SelectSheetFields}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_MEDIA_EDIT_CAPTION}
              component={EditMediaCaption}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_MEDIA_REPORT}
              component={ProjectReportView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_MEDIA_INSIGHTS}
              component={ProjectInsightsView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_MEDIA_NEW_INSIGHTS}
              component={ProjectNewInsightsView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_REPORT}
              component={ProjectReportView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_INSIGHTS}
              component={ProjectInsightsView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_NEW_INSIGHTS}
              component={ProjectNewInsightsView}
            />
            <PrivateRoute
              exact
              path={ROUTES.CLASS_EDIT}
              component={EditClassView}
            />
            <PrivateRoute
              exact
              path={ROUTES.CLASS_ADD}
              component={AddClassView}
            />
            <PrivateRoute
              exact
              path={ROUTES.SETTINGS_DEFAULT_ACCOUNT}
              component={DefaultAccountSettingsView}
            />
            <PrivateRoute
              exact
              path={ROUTES.SETTINGS_PROFILE_AND_PREFERENCES}
              component={ProfileAndPreferencesView}
            />
            <PrivateRoute
              exact
              path={ROUTES.SETTINGS_USERS_AND_TEAMS_BASE}
              component={UsersAndTeamsView}
            />
            <PrivateRoute
              exact
              path={ROUTES.SETTINGS_USERS_AND_TEAMS}
              component={UsersAndTeamsView}
            />
            <PrivateRoute
              exact
              path={ROUTES.SETTINGS_PLAN_AND_BILLING_BASE}
              component={AccountsAndBillingView}
            />
            <PrivateRoute
              exact
              path={ROUTES.SETTINGS_PLAN_AND_BILLING}
              component={AccountsAndBillingView}
            />
            <PrivateRoute
              exact
              path={ROUTES.SETTINGS_USER_GUIDE}
              component={UserGuideView}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODELS_SCORE}
              component={ModelsScoreView}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODELS_EMOTION}
              component={ModelsEmotionView}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODELS_ANALYSE}
              component={ModelsAnalyseView}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODEL_SETTINGS}
              component={ModelSettingsView}
            />
            <PrivateRoute
              exact
              path={ROUTES.BACK_OFFICE}
              component={BackOfficeView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PLAN_DOWNGRADE}
              component={DowngradePlanView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PLAN_DOWNGRADE_RESULT}
              component={DowngradeResultView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PLAN_PURCHASE_RESULT}
              component={PurchaseResultView}
            />

            <PrivateRoute
              exact
              path={ROUTES.PLAN_CHOOSE}
              component={ChoosePlanView}
            />
            <PrivateRoute
              exact
              path={ROUTES.PLAN_CONTACT_YOUR_ADMINISTRATOR}
              component={ContactYourAdminView}
            />

            <PrivateRoute
              exact
              path={ROUTES.TEST}
              component={TestGraphComponents}
            />
            <PrivateRoute
              exact
              path={ROUTES.CREATE_ACCOUNT}
              component={CreateAccountView}
            />
            <PublicRoute exact path={ROUTES.LOGIN} component={LoginView} />
            <PublicRoute exact path={ROUTES.LOGOUT} component={LogoutView} />
            <PublicRoute
              exact
              path={ROUTES.FORGOT_PASSWORD}
              component={ForgotPasswordView}
            />
            <PublicRoute
              exact
              path={ROUTES.FORGOT_PASSWORD_CHECK_YOUR_EMAIL}
              component={ForgotPasswordCheckYourEmailView}
            />
            <PublicRoute
              exact
              path={ROUTES.FORGOT_PASSWORD_SET_NEW}
              component={ForgotPasswordSetNewView}
            />
            <PublicRoute exact path={ROUTES.SIGN_UP} component={SignupView} />
            <Route
              exact
              path={ROUTES.SIGN_UP_STEP_1}
              render={() => <Redirect to="/login?step=SIGNUP" />}
            />
            <PublicRoute
              exact
              path={ROUTES.SIGN_UP_STEP_2}
              component={SignupStep2View}
            />
            <PublicRoute
              exact
              path={ROUTES.SIGN_UP_STEP_3}
              component={SignupStep3View}
            />
            <PublicRoute
              exact
              path={ROUTES.SIGN_UP_STEP_4}
              component={SignupStep4View}
            />
            <PublicRoute
              exact
              path={ROUTES.REGISTER_INVITATION}
              component={RegisterInvitationView}
            />
            <PublicRoute path={ROUTES.REDIRECT} component={RedirectView} />
            <PublicRoute path={ROUTES.ARF} component={Error500View} />
            <PublicRoute path={ROUTES.UNAUTHORIZED} component={Error403View} />
            {/* Catch all route */}
            <PublicRoute path="*" component={NotFoundView} status={404} />
          </Switch>
        </Router>
        <ToastedComponent component={ConnectivityListener} />
      </Suspense>
    </ToastProvider>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(App);
