import { USER_ROLES_ENUM } from "../constants";
import { LocalStorageService } from "../services/storage.service";
import IntercomHelper from "./IntercomHelper";
import StringHelper from "./StringHelper";

class UserHelper {}

UserHelper.getUser = () => {
  return LocalStorageService.getJson("user");
};

UserHelper.guessCompanyName = () => {
  let currentUser = UserHelper.getUser();
  if (!currentUser.email) {
    return "";
  }
  const first_split = currentUser.email.split("@")[1];
  const second_split = first_split.split(".");
  if (
    ["gmail", "outlook", "icloud", "yahoo"].includes(
      second_split[0].toLowerCase()
    )
  ) {
    return "";
  }
  return StringHelper.capitalizeFirstLetter(second_split[0]);
};

UserHelper.generateFakeUser = () => {
  const fakeUser = {
    id: 5,
    fullname: "Jon Snow",
    firstname: "Jon",
    lastname: "Snow",
    email: "jon@sensia.ai",
    status: "active",
    initial: "A",
    color: "background-5",
    profile_picture_url: null,
    team: {
      id: 1,
      title: "GoT",
    },
    role: "admin",
    user_guide_percent_completion: 11,
    token: "FAKE_BEARER_TOKEN",
  };
  localStorage.setItem("user", JSON.stringify(fakeUser));
};

UserHelper.setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
  IntercomHelper.bootIntercom();
  // Créer et déclencher un événement personnalisé pour notifier les autres composants
  const event = new Event("userUpdate");
  window.dispatchEvent(event);
};

UserHelper.getSignupPlan = () => {
  return localStorage.getItem("plan");
};

UserHelper.setSignupPlan = (plan) => {
  localStorage.setItem("plan", plan);
};

UserHelper.deleteSignupPlan = () => {
  localStorage.removeItem("plan");
};

UserHelper.userIsSensia = () => {
  const user = UserHelper.getUser();
  return user && user.email.includes("@corellis.eu");
};

UserHelper.updateUser = (user) => {
  const currentUser = UserHelper.getUser() || {};
  const newUser = Object.assign(currentUser, user);
  UserHelper.setUser(newUser);
  IntercomHelper.updateIntercomUser(newUser, false);
};

UserHelper.deleteUser = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("current_account_id");
  IntercomHelper.shutdownIntercom();
  const event = new Event("userUpdate");
  window.dispatchEvent(event);
};

UserHelper.updateCurrentAccount = (account) => {
  localStorage.setItem("current_account_id", account.id);
};

UserHelper.updateCurrentAccountById = (account_id) => {
  localStorage.setItem("current_account_id", account_id);
};

UserHelper.getCurrentAccount = () => {
  const user = UserHelper.getUser();
  const savedAccountId = LocalStorageService.get("current_account_id");
  if (!user) {
    return null;
  }
  if (!user.accounts) {
    return null;
  }
  if (savedAccountId) {
    for (const account of user.accounts) {
      if (account.id == savedAccountId) {
        return account;
      }
    }
  }
  return user.accounts[0];
};

UserHelper.isAdmin = () => {
  const currentAccount = UserHelper.getCurrentAccount();
  if (currentAccount) {
    return currentAccount.user_role === USER_ROLES_ENUM.ADMIN;
  }
  return false;
};

UserHelper.getAllUserAccounts = () => {
  const user = UserHelper.getUser();
  if (!user) {
    return [];
  }
  if (!user.accounts) {
    return [];
  }
  return user.accounts;
};

UserHelper.getAllUserAccountIds = () => {
  const user = UserHelper.getUser();
  if (!user) {
    return [];
  }
  if (!user.accounts) {
    return [];
  }
  const userAccountIds = user.accounts.map((account) => {
    return account.id;
  });

  return userAccountIds;
};

export default UserHelper;
