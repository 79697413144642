import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import { ACCOUNT_PLAN, globalConstants } from "../../app/constants";
import LoadingView from "../loading/LoadingView";

import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { projectService } from "../../app/services/project.service";
import Loader from "../../components/loader/Loader";
import { PROJECT_DETAIL, ROUTES } from "../../app/routing";
import HttpHelper from "../../app/helpers/HttpHelper";
import LimitedTextareaField from "../../components/form/field/LimitedTextareaField";

import UserHelper from "../../app/helpers/UserHelper";
import { productService } from "../../app/services/product.service";

import ProjectHeader from "../addMedia/elements/MediaHeader";
import ProjectBanner from "./elements/ProjectBanner";
import CreateProjectBanner from "../../res/images/banner/Createprojectbanner.svg";
import "./createproject.css";

import upgradeIcon from "../../res/images/icons/upgrade_icon.svg";
import InfoPicto from "../../res/images/icons/InfoOutlined.svg";
import { Box, Typography } from "@mui/joy";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import List from "@mui/joy/List";
import Select, { selectClasses } from "@mui/joy/Select";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import Option, { optionClasses } from "@mui/joy/Option";
import { listItemDecoratorClasses } from "@mui/joy/ListItemDecorator";
import IntercomHelper from "../../app/helpers/IntercomHelper";
import STypography from "../../components/SMui/STypography";
import { Switch, TextField } from "@mui/material";
import LanguageSelect from "../../components/LanguageSelect";
import DateRangeSelect from "../../components/DateRangeSelect";
import { AccountDataManagerContext } from "../../dataManagers/accountDataManager";
import STooltip from "../../components/SMui/STooltip";

class CreateProjectView extends React.Component {
  static contextType = AccountDataManagerContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isEdition: props.match.params.id != null,
      projectId: props.match.params.id,
      originalFormData: {
        name: "",
        main_characteristics: "",
        product_category_id: null,
        classe_ids: null,
        filters: null,
      },
      formData: {
        name: "",
        main_characteristics: "",
        product_category_id: null,
        classe_ids: null,
        filters: null,
      },
      languageSwitchChecked: false,
      dateSwitchChecked: false,
      selected_category: null,
      submitLoading: false,
      category: [],
      groupClassLabel: "",
    };

    this.abortController = new AbortController();
    this.abortControllerSignal = this.abortController.signal;

    this.fetchData = this.fetchData.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeMainCharacteristics =
      this.handleChangeMainCharacteristics.bind(this);
    this.handleChangeGroupClasses = this.handleChangeGroupClasses.bind(this);
    this.selectProjectCategory = this.selectProjectCategory.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchData();
  }

  componentWillUnmount() {
    this._ismounted = false;
    this.abortController.abort();
  }

  fetchData() {
    let account = UserHelper.getCurrentAccount();

    if (this.state.isEdition) {
      projectService.get.get(
        this.state.projectId,
        (response) => {
          this.setState({
            isLoaded: true,
            languageSwitchChecked: response.filters?.langs,
            dateSwitchChecked: response.filters?.date,
            groupedCategory: [], // No need real values in edition mode (read only)
            groupClassLabel: response.type_name,
            formData: {
              name: response.name,
              main_characteristics: response.main_characteristics,
              product_category_id: response.type_id,
              filters: response.filters,
              classe_ids: response.classe_ids,
            },
            originalFormData: JSON.stringify({
              name: response.name,
              main_characteristics: response.main_characteristics,
              product_category_id: response.type_id,
              filters: response.filters,
              classe_ids: response.classe_ids,
            }),
          });
        },
        (error) => {
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        },
        null,
        this.abortControllerSignal
      );
    } else {
      productService.productsForAccount(
        account["id"],
        (response) => {
          let defaultCategoryId = null;
          if (response.last_product_category_id) {
            defaultCategoryId = response.last_product_category_id;
          }

          const productCategories = []
            .concat(response.product_categories)
            .sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
          productCategories.forEach((elm) => {
            if (defaultCategoryId == null && elm.is_available) {
              defaultCategoryId = elm.id;
            }
            this.state.category.push({
              label: elm.title,
              value: elm.id,
              is_available: elm.is_available,
            });
          });
          const categoriesGroupedByMainCategory = productCategories.reduce(
            (acc, category) => {
              // Vérifiez si la main_category existe déjà dans l'accumulateur
              if (acc.hasOwnProperty(category.main_category)) {
                // Si elle existe, ajoutez la catégorie à la liste existante
                acc[category.main_category].push({
                  label: category.title,
                  value: category.id,
                  description: category.description,
                  is_available: category.is_available,
                });
              } else {
                // Sinon, créez une nouvelle liste pour cette main_category et ajoutez la catégorie
                acc[category.main_category] = [
                  {
                    label: category.title,
                    value: category.id,
                    description: category.description,
                    is_available: category.is_available,
                  },
                ];
              }
              return acc;
            },
            {}
          );

          this.setState({
            groupedCategory: categoriesGroupedByMainCategory,
            isLoaded: true,
          });

          this.handleChangeGroupClasses(defaultCategoryId);
        },
        (error) => {
          if (this._ismounted) {
            this.setState({ isLoaded: true });
            this.props.addToast(HttpHelper.getErrorMessage(error), {
              type: "error",
              autoDismiss: true,
            });
          }
        }
      );
    }
  }

  handleChangeName(event) {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.name = event.target.value;
    this.setState({
      formData: newFormData,
    });
  }

  handleChangeMainCharacteristics(newValue) {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.main_characteristics = newValue;
    this.setState({
      formData: newFormData,
    });
  }

  handleChangeGroupClasses(value) {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.product_category_id = value;
    const categoryIndex = this.state.category.findIndex(
      (c) => c.value === value
    );
    let label = "Category";
    if (categoryIndex != -1) {
      label = this.state.category[categoryIndex].label;
    }
    this.setState({
      selected_category: this.state.category[categoryIndex],
      formData: newFormData,
      groupClassLabel: label,
    });
  }

  onSubmitCreate = (e) => {
    e.preventDefault();

    let formData = Object.assign({}, this.state.formData);
    const user = UserHelper.getUser();
    const account = UserHelper.getCurrentAccount();
    formData.user_id = user.id;
    formData.account_id = account.id;
    if (formData) {
      this.setState({ submitLoading: true });
      projectService.post.create(
        formData,
        (response) => {
          const projectId = response.id;
          this.setState({ submitLoading: false });
          setTimeout(() => {
            this.props.addToast(
              'Project "' + this.state.formData.name + '" successfully created',
              { type: "success", autoDismiss: true }
            );
          }, 800);
          IntercomHelper.trackEvent("project_created", {
            name: this.state.formData.name,
            id: projectId,
          });
          this.props.history.push(PROJECT_DETAIL(projectId));
        },
        (error) => {
          if (this._ismounted) {
            this.setState({ isLoaded: true });
            this.props.addToast(HttpHelper.getErrorMessage(error), {
              type: "error",
              autoDismiss: true,
            });
          }
        }
      );
    }
  };

  onSubmitEdit = (e, scrap = false) => {
    // METHOD SUBMIT
    e.preventDefault();
    let formData = Object.assign({}, this.state.formData);
    const user = UserHelper.getUser();
    const account = UserHelper.getCurrentAccount();
    formData.user_id = user.id;
    formData.account_id = account.id;
    formData.scrap = scrap;
    if (formData) {
      this.setState({ submitLoading: true });
      projectService.put.update(
        this.state.projectId,
        formData,
        (response) => {
          const projectId = response.id;
          this.setState({ submitLoading: false });
          setTimeout(() => {
            this.props.addToast(
              'Project "' + this.state.formData.name + '" successfully edited',
              { type: "success", autoDismiss: true }
            );
          }, 800);
          IntercomHelper.trackEvent("project_edited", {
            name: this.state.formData.name,
            id: this.state.projectId,
          });
          this.props.history.push(PROJECT_DETAIL(this.state.projectId));
        },
        (error) => {
          if (this._ismounted) {
            this.setState({ isLoaded: true });
            this.props.addToast(HttpHelper.getErrorMessage(error), {
              type: "error",
              autoDismiss: true,
            });
          }
        }
      );
    }
  };

  selectProjectCategory() {
    if (!this.state.groupedCategory) {
      return <></>;
    }
    return (
      <Select
        placeholder={
          this.state.isEdition
            ? this.state.groupClassLabel
            : "Select the category"
        }
        disabled={this.state.isEdition}
        onChange={(e, newValue) => this.handleChangeGroupClasses(newValue)}
        indicator={<ArrowDropDown />}
        defaultValue={this.state.formData.product_category_id}
        slotProps={{
          listbox: {
            component: "div",
            sx: {
              maxHeight: 240,
              overflow: "auto",
              "--List-padding": "0px",
              "--ListItem-radius": "0px",
            },
          },
        }}
        sx={{
          width: "100%",
          height: "37px",
          mb: "35px",
          background: "transparent",
          fontFamily: "Inter",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          "&:hover": {
            backgroundColor: "transparent",
            border: " 1px solid #000",
          },
          [`& .${selectClasses.indicator}`]: {
            transition: "0.2s",
            [`&.${selectClasses.expanded}`]: {
              transform: "rotate(-180deg)",
            },
          },
          [`& .${selectClasses.button}`]: {
            opacity: 1,

            color: this.state.formData?.product_category_id
              ? this.state.isEdition
                ? "#B6B6B6"
                : "black"
              : "#B6B6B6",
          },
        }}
      >
        {Object.entries(this.state.groupedCategory).map(
          ([name, categories], index) => (
            <>
              {index !== 0 && (
                <ListDivider key={`divider-${name}`} role="none" />
              )}
              <List
                key={`list-${name}`}
                aria-labelledby={`select-group-${name}`}
                sx={{ "--ListItemDecorator-size": "28px" }}
              >
                <ListItem id={`select-group-${name}`} sticky>
                  <STypography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 700,
                      textTransform: "capitalize",
                    }}
                  >
                    {name.toLowerCase()}
                  </STypography>
                </ListItem>
                {categories.map((category) => (
                  <Option
                    key={category.id}
                    value={category.value}
                    label={<React.Fragment>{category.label}</React.Fragment>}
                    sx={{
                      pl: "17px",
                      justifyContent: "space-between",
                      width: "100%",
                      [`&.${optionClasses.selected} .${listItemDecoratorClasses.root}`]:
                        {
                          opacity: 1,
                        },
                    }}
                  >
                    {/* {category.label}
                    {category.description} */}
                    <Box
                      sx={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <STypography
                        sx={{
                          fontSize: "14px",
                        }}
                      >
                        {category.label}
                      </STypography>
                      {category.description && (
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            color: "#969696",
                          }}
                        >
                          {category.description}
                        </Typography>
                      )}
                    </Box>
                    {!category.is_available && (
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={upgradeIcon}
                      />
                    )}
                  </Option>
                ))}
              </List>
            </>
          )
        )}
      </Select>
    );
  }

  render() {
    const { t } = this.props;
    let page_title = `${
      this.state.isEdition ? t("Edit project") : t("Create project")
    } - ${globalConstants.WEBSITE_TITLE}`;

    let buttonIsEnabled =
      this.state.selected_category &&
      this.state.selected_category.is_available &&
      this.state.formData.name.length > 0 &&
      this.state.formData.main_characteristics.length > 0 &&
      typeof this.state.formData.product_category_id === "number"
        ? true
        : 0;

    if (this.state.isEdition) {
      buttonIsEnabled =
        this.state.formData.product_category_id != null &&
        this.state.formData.name.length > 0 &&
        this.state.formData.main_characteristics.length > 0;
    }

    let validDateFilter = true;
    if (this.state.formData.filters?.date) {
      validDateFilter =
        (this.state.formData.filters.date.first_review &&
          this.state.formData.filters.date.type) ||
        (this.state.formData.filters.date.last_review &&
          this.state.formData.filters.date.type) ||
        (this.state.formData.filters.date.after_date &&
          this.state.formData.filters.date.duration &&
          this.state.formData.filters.date.type) ||
        (this.state.formData.filters.date.before_date &&
          this.state.formData.filters.date.duration &&
          this.state.formData.filters.date.type) ||
        (this.state.formData.filters.date.start &&
          this.state.formData.filters.date.end &&
          this.state.formData.filters.date.start <=
            this.state.formData.filters.date.end);
    } else {
      validDateFilter = !this.state.dateSwitchChecked;
    }

    buttonIsEnabled = buttonIsEnabled && validDateFilter;

    if (this.state.languageSwitchChecked) {
      buttonIsEnabled =
        buttonIsEnabled && this.state.formData.filters?.langs?.length;
    }

    if (this.state.isEdition && buttonIsEnabled) {
      console.log("isEdition and buttonIsEnabled");
      console.log("original FormData:", this.state.originalFormData);
      console.log("formData:", JSON.stringify(this.state.formData));
      buttonIsEnabled =
        this.state.originalFormData != JSON.stringify(this.state.formData);
      console.log("JSON DIFF:", buttonIsEnabled);
    }

    const isAdmin = UserHelper.isAdmin();

    const accountPlan = this.context.accountDataManagerState.plan;
    const disableFilterLangs = accountPlan == ACCOUNT_PLAN.FREE;
    const disableFilterDates =
      accountPlan == ACCOUNT_PLAN.FREE || accountPlan == ACCOUNT_PLAN.PRO;

    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <ProjectHeader
            history={this.props.history}
            title={
              this.state.isEdition
                ? t("Edit Project")
                : t("Create a New Project")
            }
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              overflowY: "clip",
            }}
          >
            <Box
              sx={{
                display: "flex",
                mx: "20px",
                width: "100%",
                maxWidth: "1098px",
                justifyContent: { xs: "center", md: "space-between" },
                gap: "20px",
              }}
            >
              {/* LEFT FORM */}
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "624px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  pt: "30px",
                  mb: "160px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    mb: "10px",
                    gap: "16px",
                  }}
                >
                  <STypography sx={{ fontSize: "16px", fontWeight: 600 }}>
                    {t("Project Information")}
                  </STypography>
                  <STypography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {t("Name")}
                  </STypography>
                  {/* <STooltip
                    title={t(
                      "Choose a clear, business-oriented title for your project. Well-crafted titles lead to more relevant results and make it easier to understand."
                    )}
                    arrow
                  /> */}
                </Box>

                <TextField
                  hiddenLabel
                  fullWidth
                  size="small"
                  placeholder={t("Enter a descriptive name for your project.")}
                  value={this.state.formData.name}
                  onChange={this.handleChangeName}
                  disabled={this.state.submitLoading}
                />

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: "20px",
                    mb: "10px",
                  }}
                >
                  <STypography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {t("Objective")}
                  </STypography>
                  {/* <STooltip
                    title={t(
                      "Draft clear and concise objectives for your project, avoiding brand-specific details. This will enhance the quality of your results and recommendations."
                    )}
                    arrow
                  /> */}
                </Box>

                <LimitedTextareaField
                  className="width-percent-100 min-height-317"
                  defaultValue={this.state.formData.main_characteristics}
                  onChange={this.handleChangeMainCharacteristics}
                  placeholder={t(
                    "Please provide a brief overview of your project's goals and context. This information is essential for our AI to better understand the purpose and desired outcomes of your project"
                  )}
                  disabled={this.state.submitLoading}
                  limit={null}
                />

                <Box
                  sx={{
                    display: "flex",
                    gap: "7px",
                    alignItems: "center",
                    color: "#9e9e9e",
                    mt: "10px",
                  }}
                >
                  <img src={InfoPicto} alt="" />
                  <STypography
                    sx={{
                      color: "#9E9E9E",
                    }}
                  >
                    {t(
                      "If your story is not in English, it will be automatically translated for analysis. "
                    )}
                  </STypography>
                </Box>
                <STypography
                  sx={{
                    mt: "20px",
                    mb: "15px",
                    fontSize: "14px",
                  }}
                >
                  {t("Category")}
                </STypography>
                {this.selectProjectCategory()}
                {this.state.selected_category &&
                  !this.state.selected_category.is_available && (
                    <Box
                      sx={{
                        maxWidth: "924px",
                        mb: "35px",
                        p: "15px 12px 16px 12px",
                        borderRadius: "4px",
                        border: "1px solid #FF9378",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <img style={{ width: "16px" }} src={upgradeIcon} />
                        <STypography
                          sx={{
                            ml: "13px",
                            color: "#F78F64",
                            fontSize: "15px",
                            fontWeight: "600",
                          }}
                        >
                          {t("Unlock Specialized AI")}
                        </STypography>
                      </Box>

                      <STypography
                        sx={{
                          mt: "20px",
                          mb: "20px",
                          color: "#757575",
                          fontSize: "15px",
                          fontWeight: "400",
                        }}
                      >
                        {isAdmin && (
                          <Trans i18nKey="unlock_relevant_insights">
                            Unlock relevant insights and maximize your data
                            analysis for your{" "}
                            <span style={{ fontWeight: 700 }}>
                              {this.state.groupClassLabel}
                            </span>{" "}
                            projects by adding a dedicated AI now.{" "}
                          </Trans>
                        )}
                        {!isAdmin && (
                          <Trans i18nKey="unlock_relevant_insights">
                            A dedicated AI can provide deeper insights for your{" "}
                            <span style={{ fontWeight: 700 }}>
                              {this.state.groupClassLabel}
                            </span>{" "}
                            projects. Please contact your account admin to
                            unlock this feature and boost your data analysis.{" "}
                          </Trans>
                        )}
                      </STypography>
                      {isAdmin && (
                        <div
                          style={{
                            width: "100%",
                          }}
                          className="float-right width-100"
                        >
                          <STypography
                            className="float-right"
                            onClick={() =>
                              this.props.history.push(
                                ROUTES.SETTINGS_PLAN_AND_BILLING_BASE
                              )
                            }
                            sx={{
                              cursor: "pointer",
                              mr: "10px",
                              color: "#6D9EEE",
                              fontSize: "15px",
                              textDecoration: "none",
                            }}
                          >
                            {t("Add AI")}
                          </STypography>
                        </div>
                      )}
                    </Box>
                  )}
                <STypography sx={{ fontSize: "16px", fontWeight: 600 }}>
                  {t("Filters")}
                </STypography>
                <Box
                  sx={{
                    mt: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <STypography sx={{ fontSize: "14px" }}>
                      {t("Language")}
                    </STypography>
                    {disableFilterLangs && (
                      <STooltip
                        arrow
                        icon={upgradeIcon}
                        iconSize="16px"
                        title={t(
                          "Focus on relevant markets by filtering reviews by language. Available starting from the Pro plan."
                        )}
                      />
                    )}
                  </Box>
                  <Switch
                    checked={
                      this.state.languageSwitchChecked && !disableFilterLangs
                    }
                    disabled={disableFilterLangs}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const newFormData = Object.assign(
                        {},
                        this.state.formData
                      );
                      // delete langs property from filter if switch is not selected
                      if (!checked && newFormData.filters) {
                        delete newFormData.filters.langs;
                      }
                      this.setState({
                        languageSwitchChecked: checked,
                        formData: newFormData,
                      });
                    }}
                  />
                </Box>

                {this.state.languageSwitchChecked && !disableFilterLangs && (
                  <Box>
                    <STypography sx={{ fontWeight: 400 }}>
                      {t(
                        "Choose the languages you want to include in your analysis"
                      )}
                    </STypography>
                    <LanguageSelect
                      sx={{ width: "100%", maxWidth: "624px", mt: "5px" }}
                      values={this.state.formData.filters?.langs}
                      onChange={(values) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            filters: {
                              ...this.state.formData.filters,
                              langs: values.map((value) => value.label),
                            },
                          },
                        });
                      }}
                    />
                  </Box>
                )}

                <Box
                  sx={{
                    mt: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <STypography sx={{ fontSize: "14px" }}>
                      {t("Date Range")}
                    </STypography>
                    {disableFilterDates && (
                      <STooltip
                        arrow
                        icon={upgradeIcon}
                        iconSize="16px"
                        title={t(
                          "Analyze specific timeframes, like launches or campaigns. Available starting from the Business plan."
                        )}
                      />
                    )}
                  </Box>
                  <Switch
                    checked={
                      this.state.dateSwitchChecked && !disableFilterDates
                    }
                    disabled={disableFilterDates}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const newFormData = Object.assign(
                        {},
                        this.state.formData
                      );
                      console.log(newFormData);
                      // delete date property from filter if switch is not selected
                      if (!checked && newFormData.filters) {
                        delete newFormData.filters.date;
                      }
                      console.log(newFormData);
                      this.setState({
                        dateSwitchChecked: checked,
                        formData: newFormData,
                      });
                    }}
                  />
                </Box>

                {this.state.dateSwitchChecked && !disableFilterDates && (
                  <Box>
                    <STypography sx={{ fontWeight: 400 }}>
                      {t("Analyze reviews within a specific time frame")}
                    </STypography>
                    <DateRangeSelect
                      sx={{
                        width: "100%",
                        maxWidth: "624px",
                        mt: "5px",
                      }}
                      value={this.state.formData.filters?.date}
                      onChange={(value) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            filters: {
                              ...this.state.formData.filters,
                              date: value,
                            },
                          },
                        });
                      }}
                    />
                  </Box>
                )}
              </Box>

              {/* RIGHT IMAGE */}
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "flex-start",
                  width: "100%",
                  maxWidth: "426px",
                  mb: "100px",
                }}
              >
                <Box
                  sx={{
                    position: "fixed",
                    top: "150px",
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "12px",
                    fontWeight: 600,
                    letterSpacing: "0.2px",
                    textAlign: "left",
                    color: "#616161",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="banner-titles banner-name">
                    {this.state.formData.name.length > 0
                      ? this.state.formData.name
                      : "Name"}
                  </div>

                  <div className="banner-titles banner-product-category">
                    {this.state.groupClassLabel.length > 0
                      ? this.state.groupClassLabel
                      : "Category"}
                  </div>

                  <ProjectBanner
                    imgWidth="396px"
                    withShapeDivider={true}
                    className="features"
                    img={CreateProjectBanner}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          {/* BOTTOM BAR BUTTONS */}
          <Box
            sx={{
              position: "fixed",
              backgroundColor: "#FFF",
              borderTop: "1px solid #F1F5F6",
              bottom: 0,
              display: "flex",
              py: "22px",
              left: 0,
              right: { xs: "0px", sm: "100px", lg: "0px" },
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                maxWidth: "1098px",
                justifyContent: {
                  xs: "flex-start",
                  sm: this.state.isEdition ? "space-between" : "flex-end",
                },
              }}
            >
              <button
                type="button"
                className="button backgroundless color-6d9eee"
                disabled={this.state.submitLoading}
                onClick={(e) => {
                  if (this.state.isEdition) {
                    this.props.history.push(
                      PROJECT_DETAIL(this.state.projectId)
                    );
                  } else {
                    this.props.history.push(ROUTES.HOME);
                  }
                }}
              >
                {t("Cancel")}
              </button>
              {this.state.submitLoading && (
                <Box>
                  <Loader />
                </Box>
              )}
              {!this.state.submitLoading && this.state.isEdition && (
                <Box>
                  <button
                    type="button"
                    disabled={!buttonIsEnabled}
                    className="button backgroundless color-6d9eee"
                    onClick={(e) => this.onSubmitEdit(e, true)}
                  >
                    {t("Scrap & Update")}
                  </button>
                  <button
                    type="button"
                    disabled={!buttonIsEnabled}
                    className="button backgroundless"
                    onClick={(e) => this.onSubmitEdit(e, false)}
                  >
                    {t("Update")}
                  </button>
                </Box>
              )}
              {!this.state.submitLoading && !this.state.isEdition && (
                <button
                  onClick={this.onSubmitCreate}
                  disabled={!buttonIsEnabled}
                  className="margin-left-5 button backgroundless"
                >
                  {t("Create project")}
                </button>
              )}
            </Box>
          </Box>
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={true}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(CreateProjectView));
