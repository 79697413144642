import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  ACCOUNT_PLAN,
  MAX_DATASOURCE_BY_PROJECT,
  PLAN_JSON_LOGIC_KEYS,
  globalConstants,
} from "../../app/constants";
import LoadingView from "../loading/LoadingView";

import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { projectService } from "../../app/services/project.service";
import Link from "../../components/Link";
import { PROJECT_DETAIL, ROUTES } from "../../app/routing";
import "./addMedia.css";
import HttpHelper from "../../app/helpers/HttpHelper";
import MediaHeader from "./elements/MediaHeader";
import start_icon from "../../res/images/icons/StarFilled.svg";
import Zoom from "../../res/images/empty-state-attribute.svg";
import like_icon from "../../res/images/icons/icon-social-like.svg";
import comment_icon from "../../res/images/icons/icon-social-comment.svg";
import empty_state_data from "../../res/lotties/search-datasource-empty-state.json";
import avatar_default from "../../res/images/avatar.png";
import SearchIcon from "@mui/icons-material/Search";
import Close from "../../res/images/icons/pic-tag-clear.svg";
import illus_quotas from "../../res/images/illustrations/illus-quotas-popup.svg";
import upgradeIcon from "../../res/images/icons/upgrade_icon.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import parse from "html-react-parser";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import { mediaService } from "../../app/services/media.service";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import CountrySelect from "../../components/CountrySelect";
import { SessionStorageService } from "../../app/services/storage.service";
import STypography from "../../components/SMui/STypography";
import IntercomHelper from "../../app/helpers/IntercomHelper";
import UserHelper from "../../app/helpers/UserHelper";
import ServiceSelect from "../../components/ServiceSelect";
import Lottie from "react-lottie-player";

class SearchMediaView extends React.Component {
  constructor(props) {
    super(props);
    this.timeoutUrl = null;
    this.state = {
      projectId: props.match.params.id,
      project: null,
      isLoaded: false,
      enterpriseFeatureIsDisabled: true,
      unlockAdvancedDataSourceModal: false,
      displayCountrySelect: false,
      changeSinceLastSearch: false,
      availableCountries: null,
      services: [],
      formData: {
        project_id: props.match.params.id,
        q: null,
        country: "US",
        service: "Google Shopping",
      },
      storageData: {
        status: null,
        totalReviews: null,
        maxReviews: null,
        isEnterprise: false,
      },
      searchLoader: false,
      addLoader: false,
      isSocialService: false,
      searchResults: [],
      searchResultEmpty: false,
      selectedDatasource: [],
    };

    this.fetchProject = this.fetchProject.bind(this);
    this.fetchServices = this.fetchServices.bind(this);
    this.search = this.search.bind(this);
    this.keyDownlistener = this.keyDownlistener.bind(this);
    this.addDatasources = this.addDatasources.bind(this);
    this.handleClickProduct = this.handleClickProduct.bind(this);
    this.resultCard = this.resultCard.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.handleWarningToastErrorList =
      this.handleWarningToastErrorList.bind(this);
    this.checkJsonLogic = this.checkJsonLogic.bind(this);
    this.renderStorageMessage = this.renderStorageMessage.bind(this);
    this.getUnlockAdvancedDataSourceModal =
      this.getUnlockAdvancedDataSourceModal.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchProject();
    this.fetchServices();

    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.ENTERPRISE_FEATURE,
      {},
      (response) => {
        this.setState({
          enterpriseFeatureIsDisabled: response.status === "error",
        });
      },
      (error) => {}
    );

    document.addEventListener("keydown", this.keyDownlistener);
  }

  componentWillUnmount() {
    this._ismounted = false;
    document.removeEventListener("keydown", this.keyDownlistener);
  }

  keyDownlistener(event) {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();

      if (
        this.state.formData.q &&
        this.state.formData.q.length &&
        this.state.changeSinceLastSearch &&
        (this.state.displayCountrySelect ? this.state.formData.country : true)
      ) {
        this.search();
      }
    }
  }

  onCloseClick() {
    const update_media_referrer = SessionStorageService.get(
      "update_media_referrer"
    );
    SessionStorageService.delete("update_media_referrer");

    if (update_media_referrer) {
      this.props.history.push(update_media_referrer);
    } else {
      this.props.history.push(ROUTES.HOME);
    }
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        this.setState({
          project: response,
          isLoaded: true,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          isLoaded: true,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      { schema: "minimal" }
    );
  }

  async fetchServices() {
    await projectService.get.getSearchableSources(
      this.state.projectId,
      (response) => {
        const googleShoppingIndex = response.services.findIndex(
          (service) => service.service === "Google Shopping"
        );
        const isGoogleShoppingInList = googleShoppingIndex > -1;
        this.setState({
          services: response.services,
          displayCountrySelect: isGoogleShoppingInList ? true : false,
          formData: {
            ...this.state.formData,
            service: isGoogleShoppingInList ? "Google Shopping" : null,
            country: isGoogleShoppingInList ? "US" : null,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  async search() {
    this.setState({ searchLoader: true, selectedDatasource: [] });

    await mediaService.post.search(
      this.state.formData,
      (response) => {
        this.setState({
          changeSinceLastSearch: false,
          searchLoader: false,
          isSocialService: response.is_social_service,
          serviceIcon: response.service_icon,
          serviceName: response.service_name,
          searchResults: response.data,
          searchResultEmpty: response.data?.length == 0,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          changeSinceLastSearch: false,
          searchLoader: false,
          searchResults: [],
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  handleWarningToastErrorList(errorList) {
    if (!errorList || errorList.length == 0) {
      return;
    }

    let msg = `Unfortunately, ${errorList.length} data source can’t be added : `;
    msg = msg + errorList.map((product) => product.name).join(", ");

    this.props.addToast(msg, {
      type: "warning",
      autoDismiss: true,
    });
  }

  async addDatasources() {
    const { selectedDatasource, projectId } = this.state;
    this.setState({ addLoader: true });

    // let data = [];
    // selectedDatasource.forEach((datasource) => {
    //   data.push({
    //     title: datasource.name,
    //     url: datasource.url,
    //     nbReviews: datasource.numberOfReviews,
    //   });
    // });
    const formData = {
      products: selectedDatasource,
      country: this.state.formData.country,
    };
    await mediaService.post.createMultiple(
      projectId,
      formData,
      (response) => {
        this.setState({ addLoader: false });
        if (response.success_list.length > 0) {
          this.handleWarningToastErrorList(response.error_list);
          this.props.history.push(PROJECT_DETAIL(projectId));
        } else {
          this.props.addToast(
            "Unfortunately, none of your data sources could be added",
            {
              type: "error",
              autoDismiss: true,
            }
          );
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({ addLoader: false });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  handleClickProduct(result) {
    var array = [...this.state.selectedDatasource];
    var index = array.indexOf(result);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(result);
    }
    this.setState({ selectedDatasource: array });

    this.checkJsonLogic(array);
  }

  checkJsonLogic(selectedDatasource) {
    const nbReviews = selectedDatasource.reduce(
      (total, datasource) => total + datasource.numberOfReviews,
      0
    );
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.MEDIAS_ADD_MEDIA,
      {
        project_id: this.state.projectId,
        other_datas: { new_datasource_nb_reviews: nbReviews }, // TODO: set real nb_reviews
      },
      (response) => {
        const max = response.data.max_reviews_for_account;
        const total =
          response.current_data.current_reviews_for_account +
          response.current_data.new_datasource_nb_reviews;

        this.setState({
          storageData: {
            status: response.status,
            totalReviews: total,
            maxReviews: max,
            isEnterprise: response.data.plan == ACCOUNT_PLAN.ENTERPRISE,
          },
        });
      },
      (error) => {
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  renderStorageMessage() {
    const { storageData } = this.state;
    const isAdmin = UserHelper.isAdmin();

    if (storageData.status == null || storageData.status == "success") {
      return <></>;
    }

    const totalReviews = storageData.totalReviews;
    const maxReviews = storageData.maxReviews;

    const warningMessage = (
      <Trans i18nKey="hit_storage_media_limit_warning_search">
        Caution! You’re exceed your review limit ({{ totalReviews }}/
        {{ maxReviews }}
        ). Further updates will be locked.
      </Trans>
    );

    const messagelink = storageData.isEnterprise ? (
      <STypography
        onClick={() => IntercomHelper.trackEvent("contact-sales")}
        sx={{
          display: "inline",
          fontWeight: 500,
          fontSize: "13px",
          textTransform: "none",
          color: "#E04F77",
          textDecoration: "underline",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Trans i18nKey="talk_to_our_success_team">contact us</Trans>
      </STypography>
    ) : (
      <Link
        to={ROUTES.SETTINGS_PLAN_AND_BILLING_OVERVIEW}
        className="color-3A79E6"
      >
        add more review
      </Link>
    );

    const errorMessage = isAdmin ? (
      <Trans i18nKey="hit_storage_media_limit_warning_search">
        Limit exceeded (
        <span className="font-weight-600">
          {{ totalReviews }}/{{ maxReviews }}
        </span>
        ). Reduce selection or {messagelink} to proceed
      </Trans>
    ) : (
      <Trans i18nKey="hit_storage_media_limit_warning_search">
        Limit exceeded (
        <span className="font-weight-600">
          {{ totalReviews }}/{{ maxReviews }}
        </span>
        ). Reduce selection to proceed
      </Trans>
    );

    const message =
      storageData.status == "warning" ? warningMessage : errorMessage;

    return (
      <STypography
        sx={{
          fontSize: "13px",
          color: storageData.status == "warning" ? "#0E004B" : "#E04F77",
        }}
      >
        {message}
      </STypography>
    );

    //Limit exceeded (1150/500). Reduce selection or add more reviews to proceed

    // {
    //   status: null,
    //   totalReviews: null,
    //   maxReviews: null,
    //   isEnterprise: false,
    // }
  }

  emptyState() {
    const { t } = this.props;
    if (this.state.searchResultEmpty) {
      return (
        <div
          className="central-content"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            rowGap: "10px",
          }}
        >
          <img src={Zoom} alt="" style={{ width: "250", height: "200px" }} />
          <h3 className="font-size-24 color-616161">{t("No result found")}</h3>
          <p className="font-size-15 color-757575">
            {t("Try adjusting your search")} <br />{" "}
            {t("to find what you are looking for")}
          </p>
        </div>
      );
    }
    return (
      <Box
        sx={{
          maxWidth: "689px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "25px",
        }}
      >
        <Lottie
          loop
          animationData={empty_state_data}
          play
          style={{ width: "100%" }}
        />
      </Box>
    );
  }

  getUnlockAdvancedDataSourceModal() {
    const { t } = this.props;
    const isAdmin = UserHelper.isAdmin();
    const { enterpriseFeatureIsDisabled } = this.state;

    return (
      <div className="position-absolute sensia-popup">
        <img
          onClick={() =>
            this.setState({ unlockAdvancedDataSourceModal: false })
          }
          className="close"
          src={Close}
          alt=""
        />

        <Box sx={{ display: "flex", gap: "10px" }}>
          <img style={{ width: "25px", height: "25px" }} src={upgradeIcon} />
          <STypography sx={{ fontSize: "18px" }}>
            {t("Unlock Advanced Data Source")}
          </STypography>
        </Box>

        <Box
          sx={{
            mt: "32px",
            display: "flex",
            alignItems: "center",
            gap: "28px",
          }}
        >
          <Box>
            <STypography sx={{ fontSize: "24px", fontWeight: 700 }}>
              {t("Upgrade your plan to access Advanced Data Sources")}
            </STypography>

            <STypography
              sx={{
                mt: "15px",
                fontSize: "18px",
                fontWeight: 400,
                color: "#757575",
              }}
            >
              {t(
                "Unlock enriched data, customer reviews, and actionable insights to drive smarter decisions."
              )}
            </STypography>
          </Box>
          <img src={illus_quotas} alt="illustration-quotas" />
        </Box>

        {isAdmin && enterpriseFeatureIsDisabled && (
          <Button
            onClick={() =>
              this.props.history.push(ROUTES.SETTINGS_PLAN_AND_BILLING_OVERVIEW)
            }
            sx={{
              mt: "30px",
              borderRadius: "6px",
              background: "#F78B7C",
              color: "#FFF",
              textTransform: "none",
              "&:hover": {
                background: "#F78B7A",
              },
            }}
          >
            Upgrade Now
          </Button>
        )}
        {isAdmin && !enterpriseFeatureIsDisabled && (
          <Button
            onClick={() => IntercomHelper.trackEvent("contact-success-team")}
            sx={{
              mt: "30px",
              borderRadius: "6px",
              background: "#F78B7C",
              color: "#FFF",
              textTransform: "none",
              "&:hover": {
                background: "#F78B7A",
              },
            }}
          >
            Contact Our Success Team
          </Button>
        )}
      </div>
    );
  }

  resultCard(result) {
    const { t } = this.props;
    let { searchResults, isSocialService, serviceIcon, serviceName } =
      this.state;
    return (
      <Grid item key={result.url}>
        <Box
          sx={{
            display: "flex",
            width: "198px",
            position: "relative",
            height: isSocialService ? "410px" : "220px",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            flexShrink: 0,
            borderRadius: "4px",
            overflow: "hidden",
            outline: this.state.selectedDatasource.includes(result)
              ? "2px solid #3A79E6"
              : "none",
            background: "#FFF",
            boxShadow:
              "0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
            cursor: "pointer",
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
          onClick={() => {
            if (result.exist_in_project) {
            } else {
              this.handleClickProduct(result);
            }
          }}
        >
          {result.exist_in_project && (
            <Tooltip
              arrow
              title={t("This data sources is already in your project")}
              placement="right-start"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [7, -20],
                      },
                    },
                  ],
                },
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(189, 189, 189, 0.10)",
                }}
              >
                <Box sx={{ position: "absolute", top: "10px", right: "20px" }}>
                  <CheckCircleIcon sx={{ color: "#3A79E6" }} />
                </Box>
              </Box>
            </Tooltip>
          )}
          <Box
            sx={{
              width: "198px",
              height: isSocialService ? "262px" : "120px",
            }}
          >
            <img
              src={result.image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = `${globalConstants.API_URL}/static/images/medias/website-url-default.svg`;
                // edit the image src in search results
                searchResults?.forEach((sr, index) => {
                  if (sr.url == result.url) {
                    searchResults[
                      index
                    ].image = `${globalConstants.API_URL}/static/images/medias/website-url-default.svg`;
                  }
                });
                this.setState({ searchResults: searchResults });
              }}
              style={{
                margin: isSocialService ? 0 : "10px 5px",
                objectFit: isSocialService ? "cover" : "contain",
                width: isSocialService ? "100%" : "188px",
                height: isSocialService ? "100%" : "100px",
              }}
              alt="Sensia search datasource image"
            />
          </Box>

          {isSocialService && (
            <>
              <Box
                sx={{
                  height: "24px",
                  display: "flex",
                  padding: "0 8px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  alignSelf: "stretch",
                  gap: "5px",
                }}
              >
                <img
                  width="24px"
                  height="24px"
                  style={{ borderRadius: "12px" }}
                  src={result.authorAvatar || avatar_default}
                  alt="author avatar"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = avatar_default;
                  }}
                />
                <STypography
                  noWrap
                  sx={{
                    fontSize: "13px",
                    fontWeight: 600,
                    height: "16px",
                    textOverflow: "ellipsis",
                  }}
                >
                  {result.authorNickname}
                </STypography>
              </Box>
              <Divider width="100%" />
            </>
          )}
          <Box
            sx={{
              display: "flex",
              padding: "0px 8px",
              justifyContent: "flex-start",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            <STypography
              sx={{
                height: "32px",
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {parse(result.name)}
            </STypography>
          </Box>

          <Box
            sx={{
              height: "20px",
              display: "flex",
              p: "0 8px",
              justifyContent: "flex-start",
              alignItems: "center",
              alignSelf: "stretch",
              gap: "5px",
            }}
          >
            <img
              style={{ width: "16px", height: "16px" }}
              alt={serviceName}
              src={`${globalConstants.API_URL}/${serviceIcon}`}
            />
            <STypography
              sx={{
                fontSize: "10px",
                fontWeight: 400,
              }}
            >
              {serviceName}
            </STypography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "0px 8px",
              justifyContent: "flex-start",
              alignItems: "center",
              alignSelf: "stretch",
              gap: "4px",
            }}
          >
            {isSocialService && <img src={like_icon} alt="like icon sensia" />}
            <STypography
              sx={{
                fontWeight: 600,
                mr: isSocialService ? "12px" : 0,
              }}
            >
              {result.averageRating}
            </STypography>

            {isSocialService && (
              <img src={comment_icon} alt="comment icon sensia" />
            )}
            {!isSocialService && (
              <img src={start_icon} alt="start icon sensia" />
            )}
            {result.numberOfReviews != null &&
              result.numberOfReviews != undefined && (
                <STypography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {!isSocialService && "("}
                  {result.numberOfReviews}
                  {!isSocialService && ")"}
                </STypography>
              )}
          </Box>
        </Box>
      </Grid>
    );
  }

  render() {
    const { t } = this.props;
    const {
      project,
      searchResults,
      formData,
      changeSinceLastSearch,
      displayCountrySelect,
      selectedDatasource,
      searchLoader,
      unlockAdvancedDataSourceModal,
      addLoader,
    } = this.state;
    let page_title = `${t("Search")} - ${globalConstants.WEBSITE_TITLE}`;

    const enableSearch =
      formData.q &&
      formData.q.length &&
      changeSinceLastSearch &&
      (displayCountrySelect ? formData.country : true);

    let placeholder = t("Enter a product or brand");
    if (formData.service == "Instagram") {
      placeholder = t("Enter a hashtag");
    } else if (formData.service == "Google My Business") {
      placeholder = t("Enter a store name");
    } else if (formData.service == "Avis Vérifiés") {
      placeholder = t("Enter brand or store name ");
    }

    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={addLoader || unlockAdvancedDataSourceModal}
          >
            {addLoader && <CircularProgress color="inherit" />}
            {unlockAdvancedDataSourceModal &&
              this.getUnlockAdvancedDataSourceModal()}
          </Backdrop>

          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <MediaHeader history={this.props.history} title={t("Search")} />
          <Container
            maxWidth={false}
            disableGutters
            sx={{
              display: "flex",
              flexDirection: "column",
              height: { xs: "calc(100vh - 200px)", md: "calc(100vh - 150px)" },
            }}
          >
            <Box
              sx={{
                flex: "0 0 auto",
                mt: { xs: "0px", md: "20px" },
                mx: "20px",
              }}
            >
              {project && (
                <STypography
                  sx={{
                    maxWidth: "1100px",
                    width: "100%",
                    px: "12px",
                    mx: "auto",
                    mt: "6px",
                    mb: "20px",
                    fontSize: "14px",
                  }}
                >
                  {project.name}
                </STypography>
              )}
              <Box
                sx={{
                  maxWidth: "1100px",
                  width: "100%",
                  mx: "auto",
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                  alignItems: "start",
                  columnGap: "8px",
                  rowGap: "5px",
                }}
              >
                <ServiceSelect
                  sx={{
                    width: { xs: "-webkit-fill-available", md: "477px" },
                    height: "56px",
                    backgroundColor: "white",
                  }}
                  listServices={this.state.services}
                  defaultValue={this.state.formData.service}
                  projectId={this.state.projectId}
                  onChange={(service) => {
                    if (service) {
                      // Set default country for selected service
                      let defaultCountry = this.state.formData.country;
                      if (!service.available_countries) {
                        defaultCountry = null;
                      } else if (service.available_countries?.length > 0) {
                        defaultCountry = service.available_countries[0];
                        if (defaultCountry == "ALL") {
                          defaultCountry = "US";
                        }
                      }

                      if (!service.isAvailable) {
                        this.setState({ unlockAdvancedDataSourceModal: true });
                      } else {
                        this.setState({
                          displayCountrySelect:
                            service.available_countries &&
                            service.available_countries.length > 0,
                          changeSinceLastSearch: true,
                          availableCountries: service.available_countries,
                          formData: {
                            ...this.state.formData,
                            country: defaultCountry,
                            service: service.service,
                          },
                        });
                      }
                    }
                  }}
                />
                <TextField
                  placeholder={placeholder}
                  sx={{
                    width: "-webkit-fill-available",
                    mx: "auto",
                    fontFamily: "Inter",
                    "& .MuiOutlinedInput-input": {
                      "&:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0 100px #FFF inset",
                        WebkitTextFillColor: "default",
                      },
                    },
                  }}
                  onChange={(e) =>
                    this.setState({
                      changeSinceLastSearch: true,
                      formData: { ...this.state.formData, q: e.target.value },
                    })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search sx={{ color: "#3A79E6" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                {displayCountrySelect && (
                  <CountrySelect
                    sx={{
                      width: { xs: "-webkit-fill-available", md: "550px" },
                      height: "56px",
                      fontFamily: "Inter",
                    }}
                    value={this.state.formData.country}
                    availableCountries={this.state.availableCountries}
                    onChange={(country) => {
                      this.setState({
                        changeSinceLastSearch: true,
                        formData: {
                          ...this.state.formData,
                          country: country?.code,
                        },
                      });
                    }}
                  />
                )}
                <Button
                  variant="contained"
                  disabled={!enableSearch || searchLoader}
                  sx={{
                    height: "56px",
                    width: { xs: "100%", md: "56px" },
                    p: "12px 30px",
                    mb: { xs: 2, md: 0 },
                    backgroundColor: "#306ed6",
                    textTransform: "none",
                    borderRadius: "6px",
                    "&:hover": {
                      backgroundColor: "#0075ff",
                    },
                  }}
                  onClick={() => this.search()}
                >
                  <SearchIcon />
                </Button>
              </Box>
            </Box>
            {/* Section des résultats de recherche */}
            <Box
              sx={{
                flex: "1 1 auto",
                maxWidth: "1100px",
                mx: "auto",
                mt: 2,
                overflowY: "auto",
                p: 2,
              }}
            >
              {searchResults?.length > 0 || searchLoader ? (
                <>
                  {searchLoader ? (
                    <Skeleton width="50px" height="16px" sx={{ mb: "10px" }} />
                  ) : (
                    <STypography
                      sx={{
                        color: "#3A79E6",
                        fontSize: "13px",
                        mb: "10px",
                      }}
                    >
                      {`${searchResults?.length} result${
                        searchResults?.length > 1 ? "s" : ""
                      }`}
                    </STypography>
                  )}

                  <Grid
                    container
                    spacing="27px"
                    sx={{
                      justifyContent: { xs: "center", md: "flex-start" },
                    }}
                  >
                    {searchLoader
                      ? [...Array(25)].map((e, _i) => (
                          <Grid item key={e}>
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={198}
                              height={220}
                            />
                          </Grid>
                        ))
                      : searchResults.map((result) => this.resultCard(result))}
                  </Grid>
                </>
              ) : (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {this.emptyState()}
                </Box>
              )}
            </Box>
            {/* Bottom bar */}
            <Box
              sx={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                height: "34px",
                backgroundColor: "#FFF",
                py: "22px",
                borderTop: "1px solid #F1F5F6",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  flex: "1 1 auto",
                  maxWidth: "1100px",
                  mx: "auto",
                  mt: 2,
                  overflowY: "auto",
                  p: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {this.renderStorageMessage()}
                <button
                  onClick={this.onCloseClick}
                  className="margin-left-5 button backgroundless color-306ed6"
                >
                  {t("Cancel")}
                </button>
                <button
                  disabled={
                    selectedDatasource?.length < 1 ||
                    this.state.storageData.status === "error"
                  }
                  onClick={() => this.addDatasources()}
                  className="button backgroundless color-306ed6 "
                >
                  Add {selectedDatasource?.length} Data Sources
                </button>
              </Box>
            </Box>
          </Container>
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={false}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(SearchMediaView));
