import React, { Fragment } from "react";
import { withTranslation, Trans } from "react-i18next";
import Loader from "../../../components/loader/Loader";
import Modal from "../../../components/modal/Modal";
import { ACCOUNT_PLAN } from "../../../app/constants";
import StringHelper from "../../../app/helpers/StringHelper";
import { PLAN_UGRAPDE } from "../../../app/routing";
import Link from "../../../components/Link";
import { modelService } from "../../../app/services/model.service";
import HttpHelper from "../../../app/helpers/HttpHelper";
import { classService } from "../../../app/services/class.service";
import SelectProductType from "../../editClass/elements/SelectProductType";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Typography,
} from "@mui/joy";
import Select, { selectClasses } from "@mui/joy/Select";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { InputLabel } from "@mui/material";

class CreateModelModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        product_type: "",
        description: "",
        sort_order: 0,
        key: "",
        main_category: "",
        score_model_id: null,
        emotion_model_id: null,
        accounts: "",
        classes: [],
        product_sources: [],
      },
      inputClassesList: null,
      product_type: null,
      keys: [],
      main_categories: [],
      score_model: null,
      emotion_model: null,
      errors: null,
      isLoaded: false,
      classesList: [],
    };
    this.modalRef = React.createRef();
    this.handleChangeProduct = this.handleChangeProduct.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);
    this.handleChangeKey = this.handleChangeKey.bind(this);
    this.handleChangeMainCategory = this.handleChangeMainCategory.bind(this);
    this.handleChangeScore = this.handleChangeScore.bind(this);
    this.handleChangeEmotion = this.handleChangeEmotion.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.handleChangeSelectedClasses =
      this.handleChangeSelectedClasses.bind(this);
    this.handleChangeAccounts = this.handleChangeAccounts.bind(this);
    this.getMessageErrorPlan = this.getMessageErrorPlan.bind(this);
  }

  componentDidMount() {
    this.fetchModels();
  }

  fetchModels() {
    modelService.getInfosModels(
      (response) => {
        let score_model = [];
        response.score_models.map((item, index) => {
          score_model.push({ value: item.id, label: item.title });
        });
        let emotion_model = [];
        response.emotion_models.map((item, index) => {
          emotion_model.push({ value: item.id, label: item.title });
        });

        this.setState({
          isLoaded: true,
          keys: response.keys,
          main_categories: response.main_categories,
          score_model: score_model,
          emotion_model: emotion_model,
        });

        this.fetchData();
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({ isLoaded: true });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  fetchData() {
    classService.get.getIntegrated(
      (response) => {
        let classListArray = [];
        response.map((item) => {
          classListArray.push({ value: item.id, label: item.display_name });
        });
        this.setState({
          inputClassesList: classListArray,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({ is_loaded: true });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  onCancelClick() {
    if (this.modalRef && this.modalRef.current) {
      this.modalRef.current.onModalCloseClick();
    }
  }

  handleChangeProduct(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        product_type: event.target.value,
      },
    });
  }

  handleChangeDescription(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        description: event.target.value,
      },
    });
  }

  handleChangeOrder(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        sort_order: event.target.value,
      },
    });
  }

  handleChangeKey(value) {
    this.setState({
      formData: {
        ...this.state.formData,
        key: value,
      },
    });
  }

  handleChangeMainCategory(value) {
    this.setState({
      formData: {
        ...this.state.formData,
        main_category: value,
      },
    });
  }

  handleChangeScore(value) {
    this.setState({
      formData: {
        ...this.state.formData,
        score_model_id: value,
      },
    });
  }

  handleChangeEmotion(value) {
    this.setState({
      formData: {
        ...this.state.formData,
        emotion_model_id: value,
      },
    });
  }

  handleChangeSelectedClasses(selectedClasses) {
    this.setState({
      formData: {
        ...this.state.formData,
        classes: selectedClasses.map((result) => result.value),
      },
    });
  }

  handleChangeAccounts(event) {
    let errors = Object.assign({}, this.state.errors);
    if (/^(\d+,)*\d*$/.test(event.target.value) || event.target.value === "") {
      delete errors.accounts;
    } else {
      errors["accounts"] = "Only integers separated by commas are allowed";
    }

    this.setState({
      errors: errors,
      formData: {
        ...this.state.formData,
        accounts: event.target.value,
      },
    });
  }

  getErrorMessage(target) {
    const { error } = this.props;
    if (this.state.errors && this.state.errors.hasOwnProperty(target)) {
      return this.state.errors[target];
    }
    if (error && error.hasOwnProperty(target)) {
      return error[target];
    }

    return null;
  }

  formIsValid() {
    if (
      this.state.formData.product_type != "" &&
      this.state.formData.description != "" &&
      this.state.formData.sort_order != null &&
      this.state.formData.key != "" &&
      this.state.formData.main_category != "" &&
      this.state.formData.score_model_id != null &&
      this.state.formData.emotion_model_id &&
      this.state.formData.classes.length > 0 &&
      this.state.formData.product_sources &&
      this.state.formData.product_sources.length > 0 &&
      (this.state.errors == null || Object.keys(this.state.errors).length == 0)
    ) {
      return true;
    }
    return false;
  }

  getMessageErrorPlan() {
    const { currentPlan } = this.props;
    return (
      <Trans i18nKey="error_plan_add_user">
        You are on the {{ currentPlan }} plan. Please{" "}
        <Link
          to={PLAN_UGRAPDE(ACCOUNT_PLAN.BUSINESS)}
          className="text-decoration-underline color-000"
        >
          upgrade to Business plan
        </Link>{" "}
        to invite members and manage teams to your organization.
      </Trans>
    );
  }

  render() {
    const { t, loading } = this.props;
    const buttonIsEnabled = this.formIsValid();

    return (
      <Modal
        ref={this.modalRef}
        display={this.props.display}
        onClose={this.props.onClose}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: 700,
              pb: "40px",
            }}
          >
            {t("Create Product Category")}
          </Typography>

          <div className="padding-bottom-36">
            <FormControl sx={{ mb: "10px" }}>
              <FormLabel>{t("Name")}</FormLabel>
              <Input
                name="product_type"
                disabled={loading}
                onChange={this.handleChangeProduct}
                error={this.getErrorMessage("product_type")}
                sx={{ background: "white" }}
              />
              <FormHelperText sx={{ color: "#e04f77" }}>
                {this.getErrorMessage("product_type")}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ mb: "10px" }}>
              <FormLabel>{t("Description")}</FormLabel>
              <Input
                name="description"
                disabled={loading}
                onChange={this.handleChangeDescription}
                sx={{ background: "white" }}
              />
            </FormControl>
            <FormControl sx={{ mb: "10px" }}>
              <FormLabel>{t("Order")}</FormLabel>
              <Input
                name="sort_order"
                type="number"
                disabled={loading}
                onKeyDown={(event) => {
                  console.log(event);
                  if ([",", ".", "e", "E"].includes(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={this.handleChangeOrder}
                sx={{ background: "white" }}
              />
            </FormControl>
            <FormControl sx={{ mb: "10px" }}>
              <FormLabel>{t("Chose a main category")}</FormLabel>
              <Select
                name="main_category"
                disabled={loading}
                onChange={(e, newValue) =>
                  this.handleChangeMainCategory(newValue)
                }
                error={this.getErrorMessage("main_category")}
                indicator={<ArrowDropDown />}
                sx={{ background: "white" }}
              >
                {this.state.main_categories?.map((elm) => {
                  return <Option value={elm.value}>{elm.label}</Option>;
                })}
              </Select>
              <FormHelperText sx={{ color: "#e04f77" }}>
                {this.getErrorMessage("main_category")}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ mb: "10px" }}>
              <FormLabel>{t("Chose a Key")}</FormLabel>
              <Select
                name="key"
                disabled={loading}
                onChange={(e, newValue) => this.handleChangeKey(newValue)}
                error={this.getErrorMessage("key")}
                indicator={<ArrowDropDown />}
                sx={{ background: "white" }}
              >
                {this.state.keys?.map((elm) => {
                  return <Option value={elm.value}>{elm.value}</Option>;
                })}
              </Select>
              <FormHelperText sx={{ color: "#e04f77" }}>
                {this.getErrorMessage("key")}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ mb: "10px" }}>
              <FormLabel>{t("Chose a Score Model")}</FormLabel>
              <Select
                name="score_model"
                disabled={loading}
                onChange={(e, newValue) => this.handleChangeScore(newValue)}
                error={this.getErrorMessage("score_model")}
                indicator={<ArrowDropDown />}
                sx={{ background: "white" }}
              >
                {this.state.score_model?.map((elm) => {
                  return <Option value={elm.value}>{elm.label}</Option>;
                })}
              </Select>
              <FormHelperText sx={{ color: "#e04f77" }}>
                {this.getErrorMessage("score_model")}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ mb: "10px" }}>
              <FormLabel>{t("Chose a Feeling Model")}</FormLabel>
              <Select
                name="emotion_model"
                disabled={loading}
                onChange={(e, newValue) => this.handleChangeEmotion(newValue)}
                error={this.getErrorMessage("emotion_model")}
                indicator={<ArrowDropDown />}
                sx={{ background: "white" }}
              >
                {this.state.emotion_model?.map((elm) => {
                  return <Option value={elm.value}>{elm.label}</Option>;
                })}
              </Select>
              <FormHelperText sx={{ color: "#e04f77" }}>
                {this.getErrorMessage("emotion_model")}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ mb: "10px" }}>
              <InputLabel id="multiple-services">Services</InputLabel>
              <Select
                labelId="multiple-services"
                id="multiple-services"
                multiple
                value={this.state.product_sources}
                onChange={(e, newValue) => {
                  const formData = Object.assign({}, this.state.formData);
                  formData.product_sources = newValue;
                  this.setState({ formData: formData });
                }}
                indicator={<ArrowDropDown />}
                slotProps={{
                  listbox: {
                    component: "div",
                    sx: {
                      maxHeight: 240,
                      overflow: "auto",
                      "--List-padding": "0px",
                      "--ListItem-radius": "0px",
                    },
                  },
                }}
                sx={{
                  height: "50px",
                  background: "transparent",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  textAlign: "end",
                  [`& .${selectClasses.indicator}`]: {
                    transition: "0.2s",
                    [`&.${selectClasses.expanded}`]: {
                      transform: "rotate(-180deg)",
                    },
                  },
                  [`& .${selectClasses.button}`]: {
                    opacity: 1,
                  },
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
              >
                {this.props.productSources?.map((productSource) => (
                  <Option key={productSource.id} value={productSource.id}>
                    {productSource.title}
                  </Option>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: "10px" }}>
              <FormLabel>{t("Accounts")}</FormLabel>
              <Input
                name="accounts"
                disabled={loading}
                onChange={this.handleChangeAccounts}
                error={this.getErrorMessage("accounts")}
                sx={{ background: "white" }}
              />
              <FormHelperText sx={{ color: "#e04f77" }}>
                {this.getErrorMessage("accounts")}
              </FormHelperText>
            </FormControl>

            {this.state.inputClassesList ? (
              <SelectProductType
                onChange={this.handleChangeSelectedClasses}
                products={this.state.inputClassesList}
                selected={null}
                disabled={false}
                classe_padding="padding-top-5"
                labelTitle="Select Default classe"
              />
            ) : null}
            {this.props.errorPlan && (
              <div className="content-error-plan">
                {this.getMessageErrorPlan()}
              </div>
            )}
          </div>
          <div className="overflow-hidden">
            {!this.props.loading && (
              <Fragment>
                <div className="float-right">
                  <button
                    className="button backgroundless"
                    type="button"
                    onClick={this.onCancelClick}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="button backgroundless margin-left-5"
                    disabled={!buttonIsEnabled}
                    onClick={() => {
                      this.props.onCreateItem(this.state.formData);
                    }}
                  >
                    {t("Create")}
                  </button>
                </div>
                {!!this.props.error && (
                  <div className="color-e04f77 font-size-15 line-height-34">
                    <span className="display-inline-block line-height-1-2">
                      {this.props.error}
                    </span>
                  </div>
                )}
              </Fragment>
            )}
            {this.props.loading && (
              <div className="float-right" style={{ padding: "2px 0" }}>
                <Loader />
              </div>
            )}
          </div>
        </Box>
      </Modal>
    );
  }
}

export default withTranslation()(CreateModelModal);
