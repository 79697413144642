import HttpHelper from "../helpers/HttpHelper";

function getLanguages(onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/resources/languages",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

export const resourceService = {
  getLanguages,
};
