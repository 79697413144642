import React, { Fragment, createRef } from "react";
import { MEDIA_STATUS } from "../../app/constants";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Footer from "../../components/footer/Footer";
import { projectService } from "../../app/services/project.service";
import HttpHelper from "../../app/helpers/HttpHelper";
import { PROJECT_WORKSPACE } from "../../app/routing";
import { SessionStorageService } from "../../app/services/storage.service";

import "./projectWorkspace.css";
import SelectKeyword from "./elements/SelectKeyword";
import CollectionHelper from "../../app/helpers/CollectionHelper";
import ItemsList from "./elements/ItemsList";
import HighlightedSelectionTooltip from "./elements/HighlightedSelectionTooltip";
import { workspaceService } from "../../app/services/workspace.service";
import ColoredScore from "../../components/ui/ColoredScore";
import ReportHelper from "../../app/helpers/ReportHelper";
import ScoreTooltipContent from "../../components/tooltip/ScoreTooltipContent";
import Tooltip from "../../components/tooltip/Tooltip";
import UpdateKeywordClassModal from "../../components/modal/UpdateKeywordClassModal";
import Loader from "../../components/loader/Loader";
import { mediaService } from "../../app/services/media.service";
import PercentToolTipContent from "../../components/tooltip/PercentToolTipContent";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import "./style.css";
import ProjectFilters from "../../components/projectFilters/ProjectFilters";
import sensiaLogo from "../../res/images/icons/SensiaLogo.svg";

import Zoom from "../../res/images/empty-state-attribute.svg";
import PageEnd from "../../components/PageEnd/PageEnd";

import EmptyState from "./EmptyState";
import SkeletonLoad from "../projectReport/SkeletonLoad";

import p1 from "../../res/images/carousel/p9.svg";
import p2 from "../../res/images/carousel/p10.svg";
import p3 from "../../res/images/carousel/p11.svg";
import p4 from "../../res/images/carousel/p12.svg";

import CarouselComponent from "../../components/carroussel/Carousel";
import CarouselCard from "../../components/carroussel/Card";

import loadArrow from "../../res/images/icons/cached-filled.svg";
import endAnalyse from "../../res/images/icons/check-filled.svg";
import IntercomHelper from "../../app/helpers/IntercomHelper";
import StatisticsView from "../project/components/StatisticsView";
import { Box, Typography } from "@mui/material";
import DataSourceSelect from "../project/components/DataSourceSelect";
import ListOptSelect from "../../components/listOptSelect/ListOptSelect";
import MuiSwitch from "../../components/switch/MuiSwitch";

function getDefaultSortedBy(datasourceId) {
  const defaultSortedBy = datasourceId !== null ? "chronology" : "score";
  const savedKey = `workspace-sorted_by-${datasourceId ? "data" : "project"}`;

  return SessionStorageService.get(savedKey) || defaultSortedBy;
}

class ProjectWorkspaceView extends React.Component {
  constructor(props) {
    super(props);

    const keywords = props.defaultKeywords || [];
    const sortedBy = getDefaultSortedBy(props.datasourceId);
    this.state = {
      projectId: props.projectId,
      classId: props.defaultClassId,
      project: null,
      nbSources: 0,
      datasourceList: [],
      displayEndOfList: false,
      attributes: {},
      languages: [],
      datasources: [],
      isLoaded: false,
      inlineLoading: false,
      updateScoreLoading: false,
      listItems: [],
      totalItems: 0,
      scoreTooltipsRefs: [],
      displayOriginalReviews: props.displayOriginalReviews,
      formData: {
        datasource_id: props.datasourceId,
        keywords: keywords,
        page: 1,
        page_size: 10,
        sorted_by: sortedBy,
        class_id: props.defaultClassId,
      },
      selectKey: "select-0",
      selectedKeywords: ReportHelper.getDefaultSelectedKeywords(keywords),
      highlightedKeyword: "",
      uniqueKeywords: [],
      stats: {
        score: null,
        emotions: [],
        occurences: 0,
        report: [],
      },
      update_keyword_class: {
        display: false,
        keyword: null,
      },
      characteristics: "",
      datasource: "",
      percent_neg: "",
      percent_pos: "",
      all_emotions: [],
      total_obs: 0,
      total_attr: 0,
      selectedLanguages: [],
      selectedDataSource: [],
      changeAttributes: false,
      clipboardToast: false,
      last_review_date: null,
      mediasAnalyseInProgress: false,
      mediasAnalyseJustFinish: false,
      isUpdating: false,
      nbOfLoadingData: 0,
    };

    this.abortController = new AbortController();
    this.abortControllerSignal = this.abortController.signal;

    this.checkStatsTimeout = null;
    this.fetchProject = this.fetchProject.bind(this);
    this.fetchMediaList = this.fetchMediaList.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onChangeSortList = this.onChangeSortList.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.getDefaultSelectedKeywords =
      this.getDefaultSelectedKeywords.bind(this);

    this.onChangeMediaScope = this.onChangeMediaScope.bind(this);

    this.getPercent = this.getPercent.bind(this);
    this.getPercentTooltipContent = this.getPercentTooltipContent.bind(this);

    this.onSelectKeywordChange = this.onSelectKeywordChange.bind(this);
    this.getSelectionScoreTooltipContent =
      this.getSelectionScoreTooltipContent.bind(this);
    this.getSelectionScore = this.getSelectionScore.bind(this);

    this.onOpenItem = this.onOpenItem.bind(this);
    this.onCloseItem = this.onCloseItem.bind(this);

    this.getHighlightedSelectionParentElement =
      this.getHighlightedSelectionParentElement.bind(this);
    this.getHighlightedSelectionText =
      this.getHighlightedSelectionText.bind(this);

    this.onOpenUpdateKeywordClassModal =
      this.onOpenUpdateKeywordClassModal.bind(this);
    this.onCloseUpdateKeywordClassModal =
      this.onCloseUpdateKeywordClassModal.bind(this);
    this.onSucessUpdateKeywordClassModal =
      this.onSucessUpdateKeywordClassModal.bind(this);
    this.onUpdateItemScore = this.onUpdateItemScore.bind(this);
    this.onUpdateScoreTooltipsRefs = this.onUpdateScoreTooltipsRefs.bind(this);

    this.getFilters = this.getFilters.bind(this);
    this.filtersList = this.filtersList.bind(this);
    this.getLanguagesList = this.getLanguagesList.bind(this);
    this.getStoresList = this.getStoresList.bind(this);
    this.checkDatasourcesStatus = this.checkDatasourcesStatus.bind(this);
    this.getSortedByList = this.getSortedByList.bind(this);

    this.statisticsViewRef = createRef();

    IntercomHelper.updateIntercom();
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchProject();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    this.abortController.abort();
    this._ismounted = false;
    clearTimeout(this.checkStatsTimeout);
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    this.props.updateFormData(this.state.formData);
  }

  checkDatasourcesStatus() {
    const checkDatasourcesStatusInterval = setInterval(() => {
      mediaService.get.getProjectMedias(
        this.state.projectId,
        {
          sorted_by: "updated_at",
        },
        (response) => {
          let result = response.data.filter(
            (elm) => elm.status === "INPROGRESS"
          );
          if (result == undefined || result.length == 0) {
            clearInterval(checkDatasourcesStatusInterval);
            this.fetchProject();
            this.statisticsViewRef.current.refreshStatistics();
          }
        },
        (error) => {
          if (!this._ismounted) {
            return;
          }
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        },
        this.abortControllerSignal
      );
    }, 5000);
  }

  getDefaultSelectedKeywords(project) {
    project = typeof project !== "undefined" ? project : this.state.project;
    if (!project) {
      return [];
    }
    if (project.keyword_follow && project.keyword_follow.length > 0) {
      return ReportHelper.getDefaultSelectedKeywords(project.keyword_follow);
    }
    if (project.best_keywords && project.best_keywords.length > 0) {
      return ReportHelper.getDefaultSelectedKeywords(project.best_keywords);
    }
    return [];
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        let newFormData = Object.assign({}, this.state.formData);
        let newSelectedKeywords = Object.assign(
          [],
          this.state.selectedKeywords
        );

        if (!newSelectedKeywords.length) {
          newSelectedKeywords = this.getDefaultSelectedKeywords(response);
          newFormData.keywords = newSelectedKeywords.map((keyword) => {
            return keyword.label;
          });
        }

        this.setState({
          project: response,
          isLoaded: response.medias == 0,
          nbSources: response.medias,
          formData: newFormData,
          selectedKeywords: newSelectedKeywords,
          selectKey: "select-" + Date.now(),
          changeAttributes: true,
        });

        if (response.medias == 0) {
          return;
        }

        this.fetchMediaList(newFormData);
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      { schema: "workspace" },
      this.abortControllerSignal
    );
  }

  fetchMediaList(formData) {
    let newFormData = Object.assign({}, formData);
    mediaService.get.getProjectMedias(
      this.state.projectId,
      {
        sorted_by: "updated_at",
      },
      (response) => {
        let datasourceList = [];
        let mediaExist = false;

        let hasMediaInProgress = false;
        let analyseJustFinish = this.state.mediasAnalyseJustFinish;

        const result = response.data.filter(
          (elm) => elm.status === "INPROGRESS"
        );
        if (result != undefined && result.length > 0) {
          hasMediaInProgress = true;
          this.checkDatasourcesStatus();
        }

        if (this.state.mediasAnalyseInProgress && !hasMediaInProgress) {
          analyseJustFinish = true;
        }

        response.data.forEach((element) => {
          const hasAlreadyData =
            element.main_emotion && element.percent_neg && element.percent_pos;
          if (
            element.isCaption &&
            (element.status === MEDIA_STATUS.SUCCESS ||
              (element.status === MEDIA_STATUS.INPROGRESS && hasAlreadyData))
          ) {
            datasourceList.push(element);
            if (
              newFormData.datasource_id &&
              element.id === newFormData.datasource_id
            ) {
              mediaExist = true;
            }
          }
        });

        if (newFormData.datasource_id && !mediaExist) {
          newFormData.datasource_id = null;

          this.props.history.replace({
            pathname: PROJECT_WORKSPACE(this.state.projectId),
          });
        }

        const sources = response.data.filter((elm) => elm.status !== "FAILED");
        let nbSources = 0;
        if (sources) {
          nbSources = sources.length;
        }

        this.setState(
          {
            datasourceList: datasourceList,
            mediasAnalyseInProgress: hasMediaInProgress,
            mediasAnalyseJustFinish: analyseJustFinish,
            nbOfLoadingData: result,
            nbSources: nbSources,
            formData: newFormData,
          },
          () => {
            this.fetchData(newFormData);
          }
        );
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      this.abortControllerSignal
    );
  }

  fetchData(data, resetListItem, changeScope = false) {
    data = data || {};
    if (
      this.state.isLoaded &&
      !resetListItem &&
      this.state.listItems.length >= this.state.totalItems
    ) {
      return;
    }

    if (this._ismounted) {
      const listItems = resetListItem ? [] : this.state.listItems;
      this.setState({
        inlineLoading: true,
        listItems: listItems,
        stats: {
          score: this.state.stats.score,
          emotions: [],
          occurences: 0,
          report: [],
        },
      });
    }

    let newFormData = Object.assign(this.state.formData, data);

    if (changeScope) {
      delete newFormData.datasources;
      delete newFormData.attributes;
      delete newFormData.languages;
    }

    workspaceService.post.keywordReport(
      this.state.projectId,
      newFormData,
      (response) => {
        const newListItems = resetListItem
          ? response.keywords
          : this.state.listItems.concat(response.keywords);

        newFormData.page = response.meta.page;
        newFormData.page_size = response.meta.page_size;
        this.setState({
          formData: newFormData,
          listItems: newListItems,
          totalItems: response.meta.total,
          displayEndOfList: newListItems.length >= response.meta.total,
          isLoaded: true,
          inlineLoading: false,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          formData: newFormData,
          isLoaded: true,
          inlineLoading: false,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      this.abortControllerSignal
    );
  }

  handleScroll() {
    if (this.state.inlineLoading) {
      return;
    }
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      if (
        this.state.totalItems != null &&
        this.state.listItems.length >= this.state.totalItems
      ) {
        this.setState({
          displayEndOfList: true,
        });
        return;
      }

      if (!this.state.displayEndOfList) {
        this.setState(
          {
            inlineLoading: true,
          },
          () => {
            const infos = { page: this.state.formData.page + 1 };
            this.fetchData(infos);
          }
        );
      }
    }
  }

  onChangeSortList(infos) {
    const currentInfos = {
      sorted_by: this.state.formData.sorted_by,
    };
    if (JSON.stringify(infos) === JSON.stringify(currentInfos)) {
      return false;
    }
    infos.page = 1;
    const datasourceId = this.state.formData.datasource_id;
    const savedKey = `workspace-sorted_by-${datasourceId ? "data" : "project"}`;
    SessionStorageService.set(savedKey, infos.sorted_by);
    this.setState({
      listItems: [],
      formData: { ...this.state.formData, sorted_by: infos.sorted_by },
    });
    this.fetchData(infos, true);
  }

  onCloseItem() {
    let newListItems = Object.assign([], this.state.listItems);
    for (const item of newListItems) {
      item.opened = null;
    }
    this.setState({ listItems: newListItems });
  }

  onOpenItem(itemId, onThen) {
    this.onCloseItem();
    const item = CollectionHelper.findOneById(this.state.listItems, itemId);
    let newTotalItem = Object.assign({}, item);
    if (!item) {
      return;
    }
    newTotalItem.opened = {
      status: "loading",
    };
    let newListItems = CollectionHelper.updateOneById(
      this.state.listItems,
      itemId,
      newTotalItem
    );
    this.setState({ listItems: newListItems });

    workspaceService.get.getContext(
      this.state.projectId,
      item.id,
      item.start_at.toFixed(2),
      (response) => {
        const theItem = CollectionHelper.findOneById(
          this.state.listItems,
          itemId
        );
        if (theItem) {
          let newValue = Object.assign({}, theItem);
          newValue.opened = {
            status: "opened",
            infos: response.data,
          };
          this.setState({
            listItems: CollectionHelper.updateOneById(
              this.state.listItems,
              itemId,
              newValue
            ),
          });
        }
        if (onThen) {
          onThen();
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
        if (onThen) {
          onThen();
        }
      },
      this.abortControllerSignal
    );
  }

  onSelectKeywordChange(newKeywords) {
    let keywordValues = [];
    newKeywords.forEach((aKeyword) => {
      keywordValues.push(aKeyword.value);
    });
    let newFormdata = Object.assign(this.state.formData, {
      keywords: keywordValues,
    });
    newFormdata.page = 1;
    this.setState({
      selectedKeywords: newKeywords,
      formData: newFormdata,
    });
    this.fetchData(newFormdata, true);
  }

  getHighlightedSelectionParentElement() {
    let parentEl = null,
      sel;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        parentEl = sel.getRangeAt(0).commonAncestorContainer;
        if (parentEl.nodeType != 1) {
          parentEl = parentEl.parentNode;
        }
      }
    } else {
      sel = document.selection;
      if (sel.type != "Control") {
        parentEl = sel.createRange().parentElement();
      }
    }
    return parentEl;
  }

  getHighlightedSelectionText() {
    if (this.state.menuOpen) {
      return "";
    }

    let text = "";
    let parentEl = this.getHighlightedSelectionParentElement();
    let activeEl = document.activeElement;
    let activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
    if (
      activeElTagName === "textarea" ||
      (activeElTagName === "input" &&
        /^(?:text|search|password|tel|url)$/i.test(activeEl.type) &&
        typeof activeEl.selectionStart == "number")
    ) {
      text = activeEl.value.slice(
        activeEl.selectionStart,
        activeEl.selectionEnd
      );
    } else if (window.getSelection) {
      text = window.getSelection().toString();
    }
    if (
      parentEl &&
      parentEl.classList.contains("highlight-selection-tooltip")
    ) {
      this.setState({
        highlightedKeyword: text ? text : "",
      });
    } else {
      this.setState({ highlightedKeyword: "" });
    }

    return text;
  }

  getSelectionScoreTooltipContent() {
    const { t } = this.props;
    let item = null;
    const nbKeywords = this.state.total_attr;
    if (this.state.stats.score) {
      item = {
        value: t("Average score"),
        occurence: nbKeywords,
        score: this.state.stats.score,
        isGlobalScore: true,
      };
    }
    return (
      <div className="base-tooltip">
        <ScoreTooltipContent item={item} workspace={true} />
      </div>
    );
  }

  getSelectionScore() {
    const selectionScore = this.state.stats.score;
    if (selectionScore !== null) {
      return (
        <Tooltip
          content={this.getSelectionScoreTooltipContent()}
          placement="bottom"
        >
          <ColoredScore score={selectionScore} />
        </Tooltip>
      );
    }
    return null;
  }

  onOpenUpdateKeywordClassModal(aKeyword) {
    this.setState({
      update_keyword_class: {
        display: true,
        keyword: aKeyword,
      },
    });
  }

  onCloseUpdateKeywordClassModal() {
    this.setState({
      update_keyword_class: {
        display: false,
        keyword: null,
      },
    });
  }

  onSucessUpdateKeywordClassModal(response) {
    this.onCloseUpdateKeywordClassModal();
  }

  onChangeMediaScope(datasource_id) {
    if (datasource_id === this.state.formData.datasource_id) {
      return false;
    }

    this.setState(
      {
        selectedLanguages: [],
        selectedDataSource: [],
        changeAttributes: true,
        formData: {
          ...this.state.formData,
          datasource_id: datasource_id,
        },
      },
      () => {
        this.fetchData(
          { page: 1, sorted_by: datasource_id ? "chronology" : "score" },
          true,
          false
        );
      }
    );

    if (datasource_id === null) {
      sessionStorage.removeItem("selectedDatasource");
    } else {
      sessionStorage.setItem("selectedDatasource", datasource_id);
    }

    this.props.selectDatasourceId(datasource_id);
  }

  getPercent(value) {
    const percent =
      value === "positive" ? this.state.percent_pos : this.state.percent_neg;
    if (percent !== null) {
      return (
        <Tooltip content={this.getPercentTooltipContent(value)}>
          {percent}
        </Tooltip>
      );
    }
    return "-";
  }

  getPercentTooltipContent(value) {
    return (
      <div className="base-tooltip">
        <PercentToolTipContent
          percent={value}
          percentValue={
            value === "positive"
              ? this.state.percent_pos
              : this.state.percent_neg
          }
          totalObs={this.state.total_obs}
        />
      </div>
    );
  }

  onUpdateScoreTooltipsRefs(scoreTooltipsRefs) {
    this.state.scoreTooltipsRefs = scoreTooltipsRefs;
  }

  onUpdateItemScore(item, newScore) {
    this.setState({
      updateScoreLoading: true,
    });
    const formData = {
      sentence_id: item.id,
      new_score: newScore,
    };
    workspaceService.put.updateSentenceScore(
      this.state.projectId,
      formData,
      (response) => {
        let newListItems = Object.assign([], this.state.listItems);
        for (const item of newListItems) {
          if (item.id == response.sentence_id) {
            item.score = response.data.score;
            item.initial_score = response.data.initial_score;
            item.has_custom_score = response.data.has_custom_score;
          }
        }
        this.setState({
          listItems: newListItems,
          updateScoreLoading: false,
        });

        for (const ref of this.state.scoreTooltipsRefs) {
          if (
            ref.current &&
            ref.current.state.stayOpened &&
            ref.current.updateStayOpened
          ) {
            ref.current.updateStayOpened(false, false);
          }
        }
        this.setState({ listItems: newListItems });

        if (this.props.addToast) {
          this.props.addToast(
            "Score successfully updated. Thanks! Your feedback helps us improve our future recommendations",
            { type: "success", autoDismiss: true }
          );
        }
      },
      (error) => {
        if (this.props.addToast) {
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
        this.setState({ updateScoreLoading: false });
      }
    );
  }

  getLanguagesList() {
    let ret = [];

    if (this.state.project.languages === undefined) {
      return ret;
    }
    const languageArrayList = this.state.project.languages;

    languageArrayList.forEach((element) => {
      if (element !== null) {
        ret.push({
          label: element,
          value: element,
        });
      }
    });

    const uniqueValue = ret.filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) => t.label === thing.label && t.value === thing.value
        )
    );

    return uniqueValue;
  }

  getDatasourceList() {
    if (this.state.project && this.state.project.datasource) {
      return this.state.project.datasource;
    }
    return [];
  }

  getLanguagesList() {
    if (this.state.project && this.state.project.languages) {
      return this.state.project.languages;
    }
    return [];
  }

  getStoresList() {
    if (this.state.project && this.state.project.stores) {
      return this.state.project.stores;
    }
    return [];
  }

  filtersList() {
    const ret = [];

    if (this.getDatasourceList().length > 1) {
      ret.push({
        title: "Data source type",
        key: "datasources",
        filterItems: this.getDatasourceList(),
      });
    }

    if (this.getStoresList().length > 1) {
      ret.push({
        title: "Store",
        key: "stores",
        filterItems: this.getStoresList(),
      });
    }

    if (this.state.project?.attributes) {
      Object.entries(this.state.project.attributes).map((filter, i) => {
        if (filter.length >= 1) {
          ret.push({
            title: filter[1][0].DimensionLabel,
            key: filter[1][0].Id,
            filterItems: filter[1].filter((f) => f.Value != ""),
          });
        }
      });
    }

    if (this.getLanguagesList().length > 1) {
      ret.push({
        title: "Languages",
        key: "languages",
        filterItems: this.getLanguagesList(),
      });
    }

    if (this.state.project?.verified) {
      ret.push({
        title: "Verified Purchaser",
        key: "verified",
        filterItems: this.state.project.verified,
      });
    }
    return ret;
  }

  getFilters(filters) {
    let formData = {};

    if (filters) {
      for (const key in filters) {
        if (
          key !== "datasources" &&
          key !== "languages" &&
          key !== "verified" &&
          key !== "stores"
        ) {
          if (formData["attributes"] === undefined) {
            formData["attributes"] = {};
          }
          formData["attributes"][key] = filters[key];
        } else {
          formData[key] = filters[key];
        }
      }
      this.state.formData.page = 1;
    }

    this.fetchData(formData, true);
  }

  getCards() {
    return [
      {
        key: 1,
        title: "Class",
        text: "A class is a set of keywords grouped by theme that constitute the attributes of the product analyzed by the AI.",
        content: <CarouselCard imagen={p1} />,
      },
      {
        key: 2,
        title: "Reviews",
        text: "Inspect provides access to the list of consumer reviews containing a set of attributes, along with their score and dominant sentiment.",
        content: <CarouselCard imagen={p2} />,
      },
      {
        key: 3,
        title: "Adjust score",
        text: "Adjust score allows customization of the score provided by the AI based on personal criterias.",
        content: <CarouselCard imagen={p3} />,
      },
      {
        key: 4,
        title: "Filters",
        text: "Filters enable targeting of analyzed reviews based on defined consumer groups using various criteria.",
        content: <CarouselCard imagen={p4} />,
      },
    ];
  }

  getSortedByList() {
    const { t } = this.props;
    let ret = [];
    if (this.state.formData.datasource_id) {
      ret.push({
        label: t("Sort by chronology"),
        value: "chronology",
        active: false,
        activeLabel: t("Chronology"),
      });
    }
    ret.push({
      label: t("Sort by score ascending"),
      value: "score",
      active: false,
      activeLabel: t("Ascending"),
    });
    ret.push({
      label: t("Sort by score descending"),
      value: "score_desc",
      active: false,
      activeLabel: t("Descending"),
    });
    return ret;
  }

  render() {
    const { t } = this.props;

    let {
      project,
      nbSources,
      datasourceList,
      mediasAnalyseInProgress,
      mediasAnalyseJustFinish,
    } = this.state;

    const { print } = this.props;

    if (nbSources == null) {
      nbSources = 0;
    }

    const hasSources = nbSources > 0;

    const hasSourceWithData = datasourceList.length > 0;

    // Display empty state if project doesn't contain any source
    const displayEmptyState = !hasSources;

    // Display caroussel if project contains source and analyse is in progress
    const displayCarousel = hasSources && !hasSourceWithData;

    const displayCrunchingNumbers =
      hasSourceWithData && mediasAnalyseInProgress;
    const displayLetsAnalyse = hasSourceWithData && mediasAnalyseJustFinish;

    const displaySourceList = !print && hasSourceWithData;

    const displayFilters =
      this.filtersList().length !== 0 && !print && hasSourceWithData;
    const displaySelectKeywords = !print && hasSourceWithData;
    const displayScore = hasSourceWithData;
    const displayItemListBloc = hasSourceWithData;
    const displayLoader =
      this.state.inlineLoading && this.state.listItems.length === 0;
    const displayItemList =
      this.state.totalItems > 0 && this.state.listItems.length > 0;
    const displayNoResult =
      this.state.listItems.length === 0 &&
      hasSourceWithData &&
      this.state.isLoaded &&
      !this.state.inlineLoading;

    const displayPageEnd = hasSourceWithData && this.state.displayEndOfList;

    if (this.state.isLoaded) {
      return (
        <Fragment>
          <div className="responsive-920-padding-top-20">
            <div className="central-content">
              <div className="position-relative">
                {print && (
                  <div
                    style={{
                      fontSize: "9px",
                      marginTop: "15px",
                      alignSelf: "flex-end",
                      textAlign: "right",
                      width: "700px",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "6px",
                      justifyContent: "flex-end",
                    }}
                  >
                    Powered by <img src={sensiaLogo} alt="" />
                  </div>
                )}
              </div>

              {displaySourceList && (
                <Box sx={{ mt: "10px", mr: "95px" }}>
                  <DataSourceSelect
                    isLoading={this.state.datasourceList.length == 0}
                    menuTitle={t("Choose inspect scope")}
                    datasources={this.state.datasourceList}
                    selectedDatasource={this.state.formData.datasource_id}
                    onChange={this.onChangeMediaScope}
                  />
                </Box>
              )}

              {displayScore && (
                <div id="insights-score" className="margin-top-20">
                  <StatisticsView
                    ref={this.statisticsViewRef}
                    projectId={this.state.projectId}
                    datasourceId={this.state.formData.datasource_id}
                    keywords={this.state.formData.keywords}
                    languages={this.state.formData.languages}
                    verified={this.state.formData.verified}
                    attributes={this.state.formData.attributes}
                    stores={this.state.formData.stores}
                    tab="inspect"
                  />
                </div>
              )}

              <div className="position-relative">
                <div
                  style={
                    !print
                      ? { alignItems: "flex-end" }
                      : {
                          marginTop: "6px",
                          marginBottom: "0px",
                          alignItems: "flex-end",
                        }
                  }
                  className="font-size-30 font-inter-700 color-000 display-flex"
                >
                  {displayCrunchingNumbers && (
                    <div className="updateMessage">
                      <img className="rotate-img" src={loadArrow} alt="" />
                      {t("Crunching numbers, stay tuned")}
                    </div>
                  )}
                  {displayLetsAnalyse && (
                    <div className="updateMessage">
                      <img src={endAnalyse} alt="" />
                      {t("All set, let's analyse")}
                    </div>
                  )}
                </div>
              </div>

              {displaySelectKeywords && (
                <div
                  style={
                    this.filtersList().length > 0
                      ? { marginTop: "42px" }
                      : { marginTop: "34px" }
                  }
                  className="display-flex flex-wrap gap-5 margin-bottom-30"
                >
                  <div
                    id="attributes"
                    className="exept"
                    style={{ width: "100%" }}
                  >
                    <SelectKeyword
                      className="customlabel"
                      customlabel="Search for Keywords"
                      key={this.state.selectKey}
                      onChange={this.onSelectKeywordChange}
                      keywords={this.state.uniqueKeywords}
                      defaultValue={this.state.selectedKeywords}
                      project_id={project.id}
                      datasource_id={this.state.formData.datasource_id}
                      onOpenUpdateKeywordClassModal={
                        this.onOpenUpdateKeywordClassModal
                      }
                    />
                  </div>
                  {this.state.displayOriginalReviews && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <InfoOutlinedIcon
                        sx={{ height: "20px", color: "#7F7F7F" }}
                      />
                      <Typography
                        sx={{
                          ml: "4px",
                          color: "#7F7F7F",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }}
                      >
                        {t(
                          "Reviews are displayed in their original language but searches are still performed in English."
                        )}
                      </Typography>
                    </Box>
                  )}
                </div>
              )}

              <div
                style={{
                  position: "relative", // define position as relative
                  zIndex: "16",
                  gap: "20px",
                  marginTop: "34px",
                  display: "flex",
                  justifyContent: displayFilters ? "space-between" : "flex-end",
                  alignItems: "flex-start",
                }}
              >
                {displayFilters && (
                  <ProjectFilters
                    style={{
                      marginBottom: "24px",
                      display: "flex",
                    }}
                    name="Project Filters"
                    filters={this.filtersList()}
                    onChange={(e) => this.getFilters(e)}
                  />
                )}
                <div className="display-flex justify-content-flex-end">
                  {!print && hasSourceWithData && displayItemList && (
                    <div
                      className="position-relative text-align-right"
                      style={{ height: "80px" }}
                    >
                      <div className="display-inline-block z-index-2">
                        <ListOptSelect
                          color="3a79e6"
                          displayFamily="sorted_by"
                          defaultActive={this.state.formData.sorted_by}
                          position="right"
                          autoRefresh={true}
                          onChange={this.onChangeSortList}
                          sort_list={true}
                          lists={{
                            sorted_by: this.getSortedByList(),
                          }}
                        />
                      </div>

                      <MuiSwitch
                        checked={this.state.displayOriginalReviews}
                        onChange={(val) => {
                          this.props.updateDisplayOriginalReviews(val);
                          this.setState({ displayOriginalReviews: val });
                        }}
                        sx={{ mt: "8px", mr: "13px" }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="padding-top-5" />
            </div>
          </div>

          {displayItemListBloc && (
            <Fragment>
              {displayLoader && (
                <div style={{ marginTop: "200px" }}>
                  <Loader />
                </div>
              )}

              <div style={!print ? {} : { fontSize: "11px", width: "700px" }}>
                {displayItemList && (
                  <ItemsList
                    project={project}
                    datasourceId={this.state.formData.datasource_id}
                    inlineLoading={this.state.inlineLoading}
                    listItems={this.state.listItems}
                    displayOriginalReviews={this.state.displayOriginalReviews}
                    updateScoreTooltipsRefs={this.onUpdateScoreTooltipsRefs}
                    onOpenItem={this.onOpenItem}
                    onCloseItem={this.onCloseItem}
                    selectedKeywords={this.state.formData.keywords}
                    getHighlightedSelectionText={
                      this.getHighlightedSelectionText
                    }
                    highlightedKeyword={this.state.highlightedKeyword}
                    onUpdateItemScore={this.onUpdateItemScore}
                    updateScoreLoading={this.state.updateScoreLoading}
                    onOpenUpdateKeywordClassModal={
                      this.onOpenUpdateKeywordClassModal
                    }
                    total={this.state.totalItems}
                    menuOpe={this.state.menuOpen}
                    print={print}
                  />
                )}
                {displayNoResult && (
                  <div
                    className="central-content"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      rowGap: "10px",
                    }}
                  >
                    <img
                      src={Zoom}
                      alt=""
                      style={{ width: "250", height: "200px" }}
                    />
                    <h3 className="font-size-24 color-616161">
                      {t("No result found")}
                    </h3>
                    <p className="font-size-15 color-757575">
                      {t("Try adjusting your search")} <br />{" "}
                      {t("to find what you are looking for")}
                    </p>
                  </div>
                )}
              </div>
            </Fragment>
          )}

          {displayEmptyState && (
            <EmptyState
              history={this.props.history}
              projectId={this.state.projectId}
              handleClickAddDatasouce={() => {
                this.props.handleClickAddDatasource();
              }}
              handleClickSearchDatasouce={() => {
                this.props.handleClickSearchDatasource();
              }}
            />
          )}

          {displayCarousel && (
            <CarouselComponent
              cards={this.getCards()}
              height="500px"
              width="28%"
              margin="0 auto"
              offset={2}
              showArrows={false}
              mainTitle={"Waiting is rewarding. Explore features now! ⭐🔍"}
              secondTitle={
                "While Sensia does its magic, why not take a breather or enjoy a quick virtual coffee? We'll have your insights ready before you know it! ☕✨"
              }
            />
          )}

          {displayPageEnd && <PageEnd />}

          {hasSourceWithData && <Footer />}
          <HighlightedSelectionTooltip
            projectId={this.state.projectId}
            datasourceId={this.state.formData.datasource_id}
            keyword={this.state.highlightedKeyword}
            onOpenUpdateKeywordClassModal={this.onOpenUpdateKeywordClassModal}
            getHighlightedSelectionText={this.getHighlightedSelectionText}
          />
          {this.state.update_keyword_class.display && (
            <UpdateKeywordClassModal
              projectId={this.state.projectId}
              project={project}
              keyword={this.state.update_keyword_class.keyword}
              onClose={this.onCloseUpdateKeywordClassModal}
              onSuccess={this.onSucessUpdateKeywordClassModal}
              parentProps={this.props}
            />
          )}
        </Fragment>
      );
    }
    return (
      <Fragment>
        <SkeletonLoad />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(
  connect(mapStateToProps)(ProjectWorkspaceView)
);
