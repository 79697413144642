import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select, { selectClasses } from "@mui/joy/Select";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Option from "@mui/joy/Option";
import STypography from "./SMui/STypography";
import { Divider } from "@mui/material";
import { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

export default function DateRangeSelect({
  sx = { width: 300 },
  value = null,
  onChange = () => null,
}) {
  const [selectedKey, setSelectedKey] = useState(() => {
    if (value?.first_review !== undefined) return "after_first_review";
    if (value?.last_review !== undefined) return "before_last_review";
    if (value?.start || value?.end) return "custom_range";
    if (value?.after_date) return "after_specific_date"; // Hypothèse pour un autre cas spécifique
    if (value?.before_date) return "before_specific_date";
    return null;
  });
  const [specificDate, setSpecificDate] = useState(
    value?.after_date || value?.before_date || null
  );
  const [durationValue, setDurationValue] = useState(() => {
    if (value?.first_review !== undefined) return value.first_review;
    if (value?.last_review !== undefined) return Math.abs(value.last_review);
    return value?.duration ? Math.abs(value.duration) : null;
  });
  const [durationType, setDurationType] = useState(value?.type || null);
  const [customRange, setCustomRange] = useState({
    start: value?.start,
    end: value?.end,
  });

  const isInternalUpdate = useRef(false); // Pour distinguer les modifications internes des externes

  useEffect(() => {
    if (!isInternalUpdate.current) {
      return;
    }

    if (selectedKey === "custom_range") {
      onChange(customRange);
    } else if (selectedKey === "after_first_review") {
      onChange({
        first_review: durationValue ? parseInt(durationValue) : null,
        type: durationType,
      });
    } else if (selectedKey === "before_last_review") {
      onChange({
        last_review: durationValue ? -parseInt(durationValue) : null,
        type: durationType,
      });
    } else if (selectedKey === "after_specific_date") {
      onChange({
        after_date: specificDate || null,
        duration: durationValue || null,
        type: durationType,
      });
    } else if (selectedKey === "before_specific_date") {
      onChange({
        before_date: specificDate || null,
        duration: durationValue ? -parseInt(durationValue) : null,
        type: durationType,
      });
    }

    isInternalUpdate.current = false; // Démarque la mise à jour interne
  }, [
    selectedKey,
    specificDate,
    durationValue,
    durationType,
    customRange,
    onChange,
  ]);

  const handleUserChange = (type, value) => {
    isInternalUpdate.current = true; // Marque l'origine utilisateur
    if (type === "selectedKey") setSelectedKey(value);
    if (type === "specificDate") setSpecificDate(value);
    if (type === "durationValue") setDurationValue(value);
    if (type === "durationType") setDurationType(value);
    if (type === "customRange")
      setCustomRange((prev) => ({ ...prev, ...value }));
  };

  const options = [
    [
      {
        key: "after_first_review",
        label: "After first review",
      },
      {
        key: "after_specific_date",
        label: "After specific date",
      },
    ],
    [
      {
        key: "before_last_review",
        label: "Before last review",
      },
      {
        key: "before_specific_date",
        label: "Before specific date",
      },
    ],

    [
      {
        key: "custom_range",
        label: "Custom Range",
      },
    ],
  ];

  return (
    <Fragment>
      <Select
        id="date_range_list"
        placeholder="Select"
        onChange={(e, newValue) => {
          handleUserChange("selectedKey", newValue);
        }}
        indicator={<ArrowDropDown />}
        value={selectedKey}
        renderValue={(option) => {
          return (
            <STypography sx={{ fontSize: "15px", fontWeight: 400 }}>
              {option.label}
            </STypography>
          );
        }}
        slotProps={{
          listbox: {
            component: "div",
            sx: {
              maxHeight: 240,
              overflow: "auto",
              "--List-padding": "0px",
              "--ListItem-radius": "0px",
            },
          },
        }}
        sx={{
          ...sx,
          width: "100%",
          height: "40px",
          background: "transparent",
          fontFamily: "Inter",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          "&:hover": {
            backgroundColor: "transparent",
            border: " 1px solid #000",
          },
          [`& .${selectClasses.indicator}`]: {
            transition: "0.2s",
            [`&.${selectClasses.expanded}`]: {
              transform: "rotate(-180deg)",
            },
          },
          [`& .${selectClasses.button}`]: {
            opacity: 1,
            color: "#B6B6B6",
          },
        }}
      >
        {options.map((data, i) => (
          <>
            {data.map((option, index) => (
              <Option
                key={`date-range-select-option-${index}`}
                value={option.key}
                label={option.label}
              >
                <STypography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                  }}
                >
                  {option.label}
                </STypography>
              </Option>
            ))}
            <Divider key={`divider-${i}`} />
          </>
        ))}
      </Select>
      {selectedKey && selectedKey != "custom_range" && (
        <Box sx={{ display: "flex", mt: "5px", gap: "20px", flexWrap: "wrap" }}>
          {["after_specific_date", "before_specific_date"].includes(
            selectedKey
          ) && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <STypography sx={{ fontWeight: 400 }}>Reference</STypography>
              <TextField
                id="end_date"
                type="date"
                size="small"
                value={specificDate}
                onChange={(e) => {
                  handleUserChange("specificDate", e.target.value);
                }}
                sx={{ width: "200px" }}
              />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <STypography sx={{ fontWeight: 400 }}>Duration</STypography>
            <Box sx={{ display: "flex", gap: "5px" }}>
              <TextField
                id="duration_value"
                type="number"
                size="small"
                min={1}
                value={durationValue}
                onChange={(e) => {
                  handleUserChange("durationValue", e.target.value);
                }}
                sx={{ width: "75px" }}
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
                onInput={(e) => {
                  const numericValue = parseFloat(e.target.value);

                  // Check if the numeric value is below the min value (0)
                  if (!isNaN(numericValue) && numericValue < 1) {
                    e.target.value = "1";
                  }
                }}
              />
              <Select
                id="duration_type"
                placeholder="Select"
                onChange={(e, newValue) => {
                  handleUserChange("durationType", newValue);
                }}
                indicator={<ArrowDropDown />}
                value={durationType}
                renderValue={(option) => {
                  return (
                    <STypography sx={{ fontSize: "15px", fontWeight: 400 }}>
                      {option.label}
                    </STypography>
                  );
                }}
                slotProps={{
                  listbox: {
                    component: "div",
                    sx: {
                      maxHeight: 240,
                      overflow: "auto",
                      "--List-padding": "0px",
                      "--ListItem-radius": "0px",
                    },
                  },
                }}
                sx={{
                  width: "130px",
                  height: "40px",
                  background: "transparent",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  "&:hover": {
                    backgroundColor: "transparent",
                    border: " 1px solid #000",
                  },
                  [`& .${selectClasses.indicator}`]: {
                    transition: "0.2s",
                    [`&.${selectClasses.expanded}`]: {
                      transform: "rotate(-180deg)",
                    },
                  },
                  [`& .${selectClasses.button}`]: {
                    opacity: 1,
                    color: "#B6B6B6",
                  },
                }}
              >
                <Option key={`duration-type-day`} value="day" label="Day">
                  <STypography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 400,
                    }}
                  >
                    Day
                  </STypography>
                </Option>
                <Option key={`duration-type-month`} value="month" label="Month">
                  <STypography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 400,
                    }}
                  >
                    Month
                  </STypography>
                </Option>
                <Option key={`duration-type-year`} value="year" label="Year">
                  <STypography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 400,
                    }}
                  >
                    Year
                  </STypography>
                </Option>
              </Select>
            </Box>
          </Box>
        </Box>
      )}
      {selectedKey === "custom_range" && (
        <Box
          sx={{
            mt: "15px",
            width: "100%",
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <STypography sx={{ fontWeight: 400 }}>From</STypography>
            <TextField
              id="start_date"
              type="date"
              size="small"
              value={customRange?.start}
              onChange={(e) => {
                handleUserChange("customRange", { start: e.target.value });
              }}
              sx={{ width: "200px" }}
              InputProps={{ inputProps: { max: customRange.end } }}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <STypography sx={{ fontWeight: 400 }}>To</STypography>
            <TextField
              id="end_date"
              type="date"
              size="small"
              value={customRange?.end}
              onChange={(e) => {
                handleUserChange("customRange", { end: e.target.value });
              }}
              InputProps={{ inputProps: { min: customRange.start } }}
              sx={{ width: "200px" }}
            />
          </Box>
        </Box>
      )}
    </Fragment>
  );
}
