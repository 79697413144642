import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { globalConstants } from "../../app/constants";
import { withTranslation } from "react-i18next";
import MediaHeader from "./elements/MediaHeader";
import Link from "../../components/Link";
import { mediaService } from "../../app/services/media.service";
import HttpHelper from "../../app/helpers/HttpHelper";
import MediaHelper from "../../app/helpers/MediaHelper";
import {
  PROJECT_ADD_MEDIA,
  PROJECT_ADD_MEDIA_SELECT_FIELDS,
  PROJECT_DETAIL,
} from "../../app/routing";
import Loader from "../../components/loader/Loader";
import { SessionStorageService } from "../../app/services/storage.service";
import { projectService } from "../../app/services/project.service";
import LoadingView from "../../components/loader/Loader";
import addDataSource2Img from "./../../res/images/AddDataSource2.png";
import { Checkbox } from "@mui/material";
import Typography from "@mui/joy/Typography";
import Box from "@mui/system/Box";

import Select, { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

class SelectSheetDetails extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = HttpHelper.getUrlParameters(props.location.search);
    const title = urlParams.title || "";
    const url = urlParams.url || "";
    const type = urlParams.type || MediaHelper.type.YOUTUBE;
    const productSourcesId = urlParams.productSourcesId || null;
    const filepath = urlParams.filepath || "";
    const referrer =
      props.location.state && props.location.state.referrer
        ? props.location.state.referrer
        : null;
    const extension = filepath?.split(".").pop().toLowerCase();
    this.state = {
      projectId: props.match.params.id,
      project: null,
      formData: {
        title: title,
        url: url,
        productSourcesId: productSourcesId,
        type: type,
        filepath: filepath,
        extension: extension,
      },
      fileData: {
        nbOfSheet: 1,
        nbOfColumn: 1,
        sheetList: [],
        columnBySheet: {},
      },
      columnKey: 0,
      defaultSheet: null,
      defaultColumn: null,
      selectedSheet: null,
      previewSelectedSheet: null,
      selectedColumn: null,
      columnsOptions: [],
      removeHeader: true,
      dataIsLoaded: false,
      referrer: referrer,
      isLoaded: false,
      languageInfos: urlParams.default_language,
    };

    this.fetchExcelDetails = this.fetchExcelDetails.bind(this);
    this.handleChangeSheet = this.handleChangeSheet.bind(this);
    this.handleChangeHeader = this.handleChangeHeader.bind(this);
    this.fetchProject = this.fetchProject.bind(this);
    this.next = this.next.bind(this);

    if (!SessionStorageService.get("update_media_referrer") && referrer) {
      SessionStorageService.set("update_media_referrer", referrer);
    }

    if (!this.state.formData.url && !this.state.formData.filepath) {
      this.props.history.push(PROJECT_ADD_MEDIA(this.state.projectId));
    }
  }

  componentDidMount() {
    this._ismounted = true;

    if (sessionStorage.getItem("reloaded") !== "true") {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    }

    this.fetchExcelDetails(this.state.formData.filepath);
    this.fetchProject();
  }

  componentWillUnmount() {
    sessionStorage.removeItem("reloaded");
    this._ismounted = false;
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        this.setState({
          project: response,
          isLoaded: true,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          isLoaded: true,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      { schema: "minimal" }
    );
  }

  fetchExcelDetails(filePath) {
    mediaService.get.getExcelFileData(
      filePath,
      (response) => {
        const data = {
          nbOfSheet: response.file_data.nb_sheet || 1,
          nbOfColumn: response.file_data.nb_column,
          sheetList: response.file_data.sheet_list,
          columnBySheet: response.file_data.column_by_sheet,
        };
        let firstSheetKey = data.sheetList[0];
        let columns = data.columnBySheet[firstSheetKey];
        let columnsOptions = [];
        let defaultColumn = {};

        if (columns.length > 0) {
          columns.map((d) =>
            columnsOptions.push({ label: d.title, value: d.index })
          );

          let firstColumn = columns[0];
          defaultColumn = {
            label: firstColumn.title,
            value: firstColumn.index,
          };
        }

        firstSheetKey = sessionStorage.getItem("selectedSheet")
          ? sessionStorage.getItem("selectedSheet")
          : firstSheetKey;

        this.setState({
          fileData: data,
          selectedSheet: firstSheetKey,
          previewSelectedSheet: firstSheetKey,
          selectedColumn: defaultColumn.value,
          columnsOptions: columnsOptions,
          defaultSheet: {
            label: firstSheetKey,
            value: firstSheetKey,
          },
          defaultColumn: defaultColumn,
          dataIsLoaded: true,
        });

        sessionStorage.setItem("selectedSheet", firstSheetKey);

        if (data.nbOfSheet === 1) {
          this.setState({
            multiPages: true,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getSheetOptions() {
    let modelsOptions = [];
    if (this.state.formData.extension == "csv") {
      modelsOptions.push({
        label: this.state.formData.title,
        value: this.state.fileData.sheetList[0],
      });
      return modelsOptions;
    }
    this.state.fileData.sheetList.forEach((element) => {
      modelsOptions.push({
        label: element,
        value: element,
      });
    });
    return modelsOptions;
  }

  handleChangeHeader() {
    let removeHeader = this.state.removeHeader;
    this.setState({
      removeHeader: !removeHeader,
    });
  }

  handleChangeSheet(value) {
    console.log(value);

    sessionStorage.setItem("selectedSheet", value);

    this.setState({
      selectedSheet: value,
      previewSelectedSheet: value,
    });
  }

  next() {
    const urlParams = {
      title: this.state.formData.title,
      url: this.state.formData.url,
      type: this.state.formData.type,
      productSourcesId: this.state.formData.productSourcesId,
      filepath: this.state.formData.filepath,
      selectedSheet: this.state.selectedSheet,
      default_language: this.state.languageInfos,
      nbOfSheet: this.state.fileData.nbOfSheet,
      removeHeader: this.state.removeHeader ? 1 : 0,
    };
    this.props.history.push(
      HttpHelper.addParametersToUrl(
        PROJECT_ADD_MEDIA_SELECT_FIELDS(this.state.projectId),
        urlParams
      ),
      { referrer: document.location.pathname }
    );
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Add data source")} - ${
      globalConstants.WEBSITE_TITLE
    }`;

    const buttonIsEnabled =
      this.state.selectedSheet !== null &&
      this.state.selectedColumn !== null &&
      this.state.dataIsLoaded;
    const project = this.state.project;
    let previewSelectedSheet = this.state.previewSelectedSheet;
    if (this.state.formData.extension == "csv") {
      previewSelectedSheet = this.state.formData.title;
    }
    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <MediaHeader
            history={this.props.history}
            title={t("Add data source")}
          />
          <div className="display-flex justify-content-center align-items-flex-start">
            <div className="width-520 mobile-full-width padding-top-50 padding-bottom-20 selectSheetView">
              <div className="central-content-768">
                <div>
                  {project && (
                    <div className="padding-bottom-4">
                      <Link
                        to={PROJECT_DETAIL(project.id)}
                        className="font-size-18 link-grey transition-color text-break-line"
                      >
                        {project.name}
                      </Link>
                    </div>
                  )}
                  <div className="color-000000 font-size-32 font-inter-700">
                    Customize Import Options
                  </div>
                </div>
                {!this.state.dataIsLoaded && (
                  <div className="text-align-center padding-vertical-80 margin-bottom-20">
                    <Loader />
                  </div>
                )}

                {this.state.dataIsLoaded && (
                  <Fragment>
                    <p className="margin-top-16 font-size-15 link-grey transition-color text-break-line">
                      Please ensure that you are importing the correct sheet and
                      select the corresponding checkbox if you want the first
                      row to be skipped during the data import.
                    </p>
                    {!this.state.multiPages && (
                      <div className="infoBox font-size-14">
                        <p>
                          <span>i</span> It looks like your file includes
                          multiple sheets.
                        </p>
                        <p className="font-size-14 font-weight-500 color-7f7f7f">
                          Please select the one you want to import.
                        </p>
                      </div>
                    )}
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                        letterSpacing: "0.15px",
                        mt: "50px",
                        mb: "15px",
                      }}
                    >
                      {t("Sheet")}
                    </Typography>
                    <Select
                      indicator={<ArrowDropDown />}
                      value={this.state.selectedSheet}
                      onChange={(e, newValue) =>
                        this.handleChangeSheet(newValue)
                      }
                      sx={{
                        borderRadius: "4px",
                        width: "100%",
                        background: "transparent",
                        "&:hover": {
                          backgroundColor: "transparent",
                          border: " 1px solid #000",
                        },
                        [`& .${selectClasses.indicator}`]: {
                          transition: "0.2s",
                          [`&.${selectClasses.expanded}`]: {
                            transform: "rotate(-180deg)",
                          },
                        },
                      }}
                    >
                      {this.getSheetOptions().map((name) => (
                        <Option value={name.value}>{name.label}</Option>
                      ))}
                    </Select>
                  </Fragment>
                )}
                <div
                  className="checkbox margin-top-25"
                  style={{
                    visibility: !this.state.dataIsLoaded ? "hidden" : "visible",
                  }}
                >
                  <Checkbox
                    className="typology-checkbox"
                    id="keep_header"
                    type="checkbox"
                    onClick={this.handleChangeHeader}
                    checked={this.state.removeHeader}
                    sx={{
                      color: "#b4b4b4",
                      "&$checked": {
                        color: "#3a79e6",
                      },
                      "&:hover": {
                        backgroundColor: "transparent !important",
                      },
                    }}
                  />
                  <div>{t("First row contains headers")}</div>
                </div>
                <div className="nextPageBtn margin-top-35">
                  <Link
                    className="button backgroundless color-6d9eee"
                    to={this.state.referrer}
                  >
                    {t("Back")}
                  </Link>
                  <button
                    onClick={this.next}
                    disabled={!buttonIsEnabled}
                    className="margin-left-5 button backgroundless color-306ed6"
                  >
                    {t("Next")}
                  </button>
                </div>
              </div>
            </div>

            <div className="width-60" />

            <div className="width-520 padding-top-50 padding-bottom-20 display-only-large-screen">
              <Box
                sx={{
                  backgroundImage: `url(${addDataSource2Img})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "545px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  width="288px"
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                    letterSpacing: "0.15px",
                    mt: "220px",
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textWrap: "nowrap",
                  }}
                >
                  {previewSelectedSheet}
                </Typography>
              </Box>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={false}
        headerProps={{ isProject: true }}
      />
    );
  }
}

export default withTranslation()(SelectSheetDetails);
