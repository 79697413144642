import React from "react";
import "../list.css";
import { withTranslation } from "react-i18next";
import { MEDIA_STATUS } from "../../../app/constants";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Typography, styled } from "@mui/material";

class MediaStatus extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    const item = this.props.item;
    const status = item.status;
    const status_message = item.status_message;
    const error_message = item.status_error_message;

    const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        padding: "5px",
        borderRadius: "2px",
        backgroundColor: "#fff",
        width: "auto",
        color: "#000",
        boxShadow:
          "0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.2)",
      },
    }));

    switch (status) {
      case MEDIA_STATUS.TOPROCESS:
        return (
          <div className="media-status to-process">
            <div className="icon-media-status to-process" />
            <span className="font-size-13 color-7f7f7f">
              {t("Processing...")}
            </span>
          </div>
        );
      case MEDIA_STATUS.INPROGRESS:
        return (
          <div className="media-status in-progress">
            <div className="icon-media-status in-progress animation-iteration-count-infinite animation-rotate animation-duration-2 animation-timing-function-linear animation-rotate-reverse" />
            <span className="font-size-13 color-7f7f7f">{status_message}</span>
          </div>
        );
      case MEDIA_STATUS.FAILED:
        if (error_message) {
          return (
            <div className="media-status failed">
              <div className="icon-media-status failed" />
              <HtmlTooltip
                title={
                  <div className="font-size-13 color-e04f77">
                    {error_message}
                  </div>
                }
                placement="bottom-start"
              >
                <Typography
                  sx={{
                    color: "#E04F77",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  {t("Error Analysis")}
                </Typography>
              </HtmlTooltip>
            </div>
          );
        }
        if (!item.isCaption) {
          const captionIconClasses = item.isCaption
            ? "icon-captions v2 ok background-image-cover"
            : "icon-captions v2 ko background-image-cover";
          return (
            <div className="failed content-captions-infos content-captions-infos-responsive">
              <div className={captionIconClasses} />
              <span className="font-size-13 color-e04f77 padding-top-0-responsive padding-left-5-responsive">
                {t("No review detected")}
              </span>
            </div>
          );
        }
        return (
          <div className="media-status failed">
            <div className="icon-media-status failed" />
            <HtmlTooltip
              title={
                <div className="font-size-13 color-e04f77">
                  {t("An error occured, please try again")}
                </div>
              }
              placement="bottom-start"
            >
              <div className="text font-size-13 color-e04f77">
                {t("An error occured, please try again")}
              </div>
            </HtmlTooltip>
          </div>
        );
      case MEDIA_STATUS.SUCCESS:
        if (!item.isCaption) {
          const captionIconClasses = item.isCaption
            ? "icon-captions v2 ok background-image-cover"
            : "icon-captions v2 ko background-image-cover";
          return (
            <div className="failed content-captions-infos content-captions-infos-responsive">
              <div className={captionIconClasses} />
              <span className="font-size-13 color-e04f77 padding-top-0-responsive padding-left-5-responsive">
                {t("No review detected")}
              </span>
            </div>
          );
        }
        return <div></div>;
      default:
        return <div></div>;
    }
  }
}

export default withTranslation()(MediaStatus);
