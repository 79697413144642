class StringHelper {}

StringHelper.capitalize = (s, makeLower) => {
  if (typeof s !== "string") return "";
  if (makeLower) s = s.toLowerCase();
  return s.charAt(0).toUpperCase() + s.slice(1);
};

StringHelper.capitalizeFirstLetter = (string) => {
  if (typeof string == "undefined") {
    return null;
  }
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

StringHelper.isEmailValid = (email) => {
  return /\S+@\S+\.+[a-zA-Z]{2,}/.test(email);
};

StringHelper.isPasswordValid = (password) => {
  const checks = StringHelper.getPasswordChecks(password);
  return checks.size && checks.lowercase && checks.uppercase && checks.symbol;
};

StringHelper.cleanFilename = (filename) => {
  return filename.replace(/[^a-z\d\-_\s]/gi, "");
};

StringHelper.cleanDiplayNumber = (x) => {
  x = parseFloat(x).toFixed(2);
  x = x.toString();
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1 $2");
  return x;
};

StringHelper.padLeadingZeros = (x, size = 4) => {
  return x.toString().slice(-size).padStart(size, "0");
};

StringHelper.getPasswordChecks = (password) => {
  let ret = {
    size: false,
    lowercase: false,
    uppercase: false,
    symbol: false,
  };
  if (!password) {
    return ret;
  }
  if (password.length >= 8) {
    ret.size = true;
  }
  if (/[a-z]/.test(password)) {
    ret.lowercase = true;
  }
  if (/[A-Z]/.test(password)) {
    ret.uppercase = true;
  }
  if (
    /\d/.test(password) ||
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)
  ) {
    ret.symbol = true;
  }
  return ret;
};

StringHelper.arrayToString = (array, limit = 3, separator = ", ") => {
  if (!Array.isArray(array) || array.length === 0) return "";

  const displayedString = array.slice(0, limit);
  const remainingCount = array.length - limit;

  const label =
    remainingCount > 0
      ? `${displayedString.join(separator)}${separator}+${remainingCount}`
      : displayedString.join(separator);

  return label;
};

export default StringHelper;
