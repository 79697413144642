import React, { Fragment } from "react";
import "./header.css";
import ProfileMenu from "./elements/ProfileMenu";
import { withTranslation, Trans } from "react-i18next";
import Link from "../Link";
import { ROUTES } from "../../app/routing";
import logo from "../../res/images/logo/logo.svg";
import logo2 from "../../res/images/logo/logo-pimpon.svg";
import logo3 from "../../res/images/logo/logo-saintseya.svg";
import star_icon from "../../res/images/icons/star-icon-orange.png";
import { SessionStorageService } from "../../app/services/storage.service";
import { ACCOUNT_PLAN, globalConstants } from "../../app/constants";
import StagingRibbon from "../stagingRibbon/StagingRibbon";
import UserHelper from "../../app/helpers/UserHelper";
import Drawer from "@mui/material/Drawer";
import HelpMenu from "./elements/HelpMenu";
import {
  Box,
  Button,
  ClickAwayListener,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import ReviewsIcon from "@mui/icons-material/Reviews";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { accountService } from "../../app/services/account.service";
import IntercomHelper from "../../app/helpers/IntercomHelper";
import Loader from "../loader/Loader";
import STypography from "../SMui/STypography";
import { AccountDataManagerContext } from "../../dataManagers/accountDataManager";
import { max } from "moment-timezone";

class Header extends React.Component {
  // contexte que le Header va consommer
  static contextType = AccountDataManagerContext;

  constructor(props) {
    super(props);
    const isAdmin = UserHelper.isAdmin();

    this.state = {
      drawerIsOpen: false,
      usageIsLoading: true,
      usageAndPlanIsOpen: false,
      productCategories: [],
      isAdmin: isAdmin,
    };

    this.projectIsActive = this.projectIsActive.bind(this);
    this.getLogo = this.getLogo.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.openUsageDrawer = this.openUsageDrawer.bind(this);
    this.closeUsageDrawer = this.closeUsageDrawer.bind(this);
    this.fetchAccountData = this.fetchAccountData.bind(this);
    this.usageButton = this.usageButton.bind(this);
    this.getStorageMessage = this.getStorageMessage.bind(this);
    SessionStorageService.delete("update_plan_referrer");
  }

  componentDidMount() {
    this._ismounted = true;
  }

  getStorageMessage() {
    const totalReviews = this.context.accountDataManagerState.totalReviews;
    const maxReviews = this.context.accountDataManagerState.maxReviews;
    const quotasStatus =
      this.context.accountDataManagerState.reviewsQuotasStatus;

    const isAdmin = UserHelper.isAdmin();

    const isEnterprise =
      this.context.accountDataManagerState.plan == ACCOUNT_PLAN.ENTERPRISE;

    let exceedPercent = ((totalReviews - maxReviews) / maxReviews || 1) * 100;
    exceedPercent = exceedPercent?.toFixed();

    if (quotasStatus != "error") {
      return null;
    }

    if (maxReviews == 0 && this.context.isLoading) {
      return null;
    }

    const messagelink = isEnterprise ? (
      <STypography
        className="text-decoration-underline color-fff"
        onClick={() => IntercomHelper.trackEvent("contact-success-team")}
        sx={{
          display: "inline",
          fontWeight: 400,
          textTransform: "none",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Trans i18nKey="talk_to_our_success_team">
          Contact our success team
        </Trans>
      </STypography>
    ) : (
      <Link
        to={ROUTES.SETTINGS_PLAN_AND_BILLING_OVERVIEW}
        className="text-decoration-underline color-fff"
      >
        Add more storage
      </Link>
    );
    const message = isAdmin ? (
      <Fragment>
        <span className="icon plan-frame margin-right-5" />
        <span className="display-inline-block vertical-align-middle">
          <Trans i18nKey="hit_storage_media_limit_warning">
            You've exceeded your storage limit by{" "}
            <span className="font-weight-600">{{ exceedPercent }}%</span> (max{" "}
            {{ maxReviews }} reviews). Updates are paused. {messagelink} or free
            up space to keep exploring.
          </Trans>
        </span>
      </Fragment>
    ) : (
      <Fragment>
        <span className="icon plan-frame margin-right-5" />
        <span className="display-inline-block vertical-align-middle">
          <Trans i18nKey="hit_storage_media_limit_warning">
            You've exceeded your storage limit by{" "}
            <span className="font-weight-600">{{ exceedPercent }}%</span> (max{" "}
            {{ maxReviews }} reviews). Updates are paused. Free up space to keep
            exploring
          </Trans>
        </span>
      </Fragment>
    );

    return message;
  }

  fetchAccountData() {
    const isAdmin = UserHelper.isAdmin();
    this.setState({ usageIsLoading: true });
    this.context.fetchAccountReviewsData();
    accountService.getAccountData(
      (response) => {
        this.setState({
          productCategories: response.product_categories,
          usageIsLoading: false,
          isAdmin: isAdmin,
        });
      },
      (error) => {
        console.error(error);
        this.setState({ usageIsLoading: false });
      }
    );
  }

  projectIsActive() {
    const pathname = window.location.pathname;
    if ([ROUTES.HOME, ROUTES.CREATE_PROJECT].includes(pathname)) {
      return true;
    }
    if (this.props.isProject) {
      return true;
    }
    return false;
  }

  getLogo() {
    const theme = SessionStorageService.get("theme");
    if (theme === "theme2") {
      return logo2;
    }
    if (theme === "theme3") {
      return logo3;
    }
    return logo;
  }

  toggleDrawer() {
    this.setState({ drawerIsOpen: !this.state.drawerIsOpen });
  }

  openUsageDrawer() {
    this.fetchAccountData();
    this.setState({ usageAndPlanIsOpen: true });
  }

  closeUsageDrawer() {
    this.setState({ usageAndPlanIsOpen: false });
  }

  usageButton() {
    const { t } = this.props;
    const { productCategories, usageIsLoading, isAdmin } = this.state;
    const { accountDataManagerState } = this.context;
    const isManualBilling =
      this.context.accountDataManagerState.plan == ACCOUNT_PLAN.MANUAL_BILLING;
    const isLoading = usageIsLoading || accountDataManagerState.isLoading;
    const max_ia = 3;
    let productCategoriesLabel = productCategories
      ?.slice(0, max_ia)
      .map((item) => item.title)
      .join(", ");

    const other_count = productCategories.length - max_ia;

    if (productCategories.length > max_ia) {
      productCategoriesLabel +=
        other_count > 1
          ? t(" and {{count}} others", {
              count: other_count,
            })
          : t(" and {{count}} other", {
              count: other_count,
            });
    }

    let quotasPercent =
      (accountDataManagerState.totalReviews /
        accountDataManagerState.maxReviews || 1) * 100;

    if (isManualBilling) {
      if (accountDataManagerState.manualReviews != null) {
        quotasPercent =
          (accountDataManagerState.totalReviews /
            accountDataManagerState.manualReviews || 1) * 100;
      } else {
        quotasPercent = 100;
      }
    }

    return (
      <ClickAwayListener
        onClickAway={() => {
          this.setState({ usageAndPlanIsOpen: false });
        }}
      >
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => {
              this.setState({ usageAndPlanIsOpen: false });
            }}
            open={this.state.usageAndPlanIsOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "transparent",
                },
              },
            }}
            title={
              <Box
                sx={{
                  display: "flex",
                  width: "293px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "10px",
                  borderRadius: "6px",
                  background: "#FFF",
                  boxShadow:
                    "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f",
                }}
              >
                {isLoading && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "290px",
                      height: "260px",
                    }}
                  >
                    <Loader />
                  </Box>
                )}
                {!isLoading && (
                  <>
                    {/* PLAN */}
                    <Box
                      sx={{
                        display: "flex",
                        padding: "15px 15px 0",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        alignSelf: "stretch",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#F7847F",
                          textAlign: "right",
                          fontFeatureSettings: "'clig' off, 'liga' off",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        {[
                          ACCOUNT_PLAN.ENTERPRISE,
                          ACCOUNT_PLAN.ENTERPRISE_SAAS,
                          ACCOUNT_PLAN.MANUAL_BILLING,
                        ].includes(accountDataManagerState.plan) &&
                          "Enterprise"}
                        {![
                          ACCOUNT_PLAN.ENTERPRISE,
                          ACCOUNT_PLAN.ENTERPRISE_SAAS,
                          ACCOUNT_PLAN.MANUAL_BILLING,
                        ].includes(accountDataManagerState.plan) &&
                          `${accountDataManagerState.plan} plan`}
                      </Typography>
                    </Box>

                    {/* REVIEWS */}
                    <Box
                      sx={{
                        p: "5px 15px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: "5px 0px",
                          alignItems: "center",
                          gap: " 5px",
                        }}
                      >
                        <ReviewsIcon sx={{ color: "#FF9378" }} />
                        <Typography
                          sx={{
                            color: "#000",
                            fontFeatureSettings: "'clig' off, 'liga' off",

                            /* Typography/Caption */
                            fontFamily: '"Inter", sans-serif',
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                          }}
                        >
                          {t("Reviews")}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          padding: "5px 0px",
                          alignItems: "center",
                          width: "263px",
                          gap: "5px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#000",
                            fontFeatureSettings: "'clig' off, 'liga' off",

                            /* Typography/Caption */
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                          }}
                        >
                          {accountDataManagerState.totalReviews.toLocaleString()}
                          {isManualBilling &&
                            accountDataManagerState.manualReviews != null && (
                              <span className="font-size-12 font-weight-400">
                                /
                                {accountDataManagerState.manualReviews.toLocaleString()}
                              </span>
                            )}
                          {!isManualBilling && (
                            <span className="font-size-12 font-weight-400">
                              /
                              {accountDataManagerState.maxReviews.toLocaleString()}
                            </span>
                          )}
                        </Typography>

                        <LinearProgress
                          sx={{
                            width: "138px",
                            height: "6px",
                            backgroundColor: "#27C98699",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor:
                                quotasPercent > 100 ? "#F78F64" : "#27C986",
                            },
                            borderRadius: 5,
                          }}
                          variant="determinate"
                          value={quotasPercent > 100 ? 100 : quotasPercent}
                        />
                      </Box>

                      {isAdmin && (
                        <Box
                          sx={{
                            display: "flex",
                            padding: "5px 0px",
                            alignItems: "center",
                            width: "263px",
                            gap: "5px",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            to={ROUTES.SETTINGS_PLAN_AND_BILLING_BASE}
                            className="light-link-blue"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {t("Manage your plan")}
                              <ArrowForwardIcon
                                sx={{
                                  ml: "4px",
                                  width: "20px",
                                  color: "#306ED6",
                                }}
                              />
                            </Box>
                          </Link>
                        </Box>
                      )}
                    </Box>

                    {/* IA */}
                    <Box
                      sx={{
                        p: "5px 15px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: "5px 0px",
                          alignItems: "center",
                          gap: " 5px",
                        }}
                      >
                        <img src={star_icon} width="20px" />
                        <Typography
                          sx={{
                            color: "#000",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: '"Inter", sans-serif',
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                          }}
                        >
                          {t("IA")}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          padding: "5px 0px",
                          alignItems: "center",
                          width: "263px",
                          gap: "5px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#000",
                            fontFeatureSettings: "'clig' off, 'liga' off",

                            /* Typography/Caption */
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                          }}
                        >
                          {productCategoriesLabel}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          padding: "5px 0px",
                          alignItems: "center",
                          width: "263px",
                          gap: "5px",
                          justifyContent: "space-between",
                        }}
                      >
                        {isAdmin && (
                          <Link
                            to={ROUTES.SETTINGS_PLAN_AND_BILLING_BASE}
                            className="light-link-blue"
                          >
                            <Box
                              className="light-link-blue"
                              sx={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {t("Add New IA")}
                              <ArrowForwardIcon
                                sx={{
                                  ml: "4px",
                                  width: "20px",
                                  color: "#306ED6",
                                }}
                              />
                            </Box>
                          </Link>
                        )}
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            }
          >
            <Button
              sx={{
                height: "36px",
                display: "flex",
                padding: "14px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "20px",
                border: this.state.usageAndPlanIsOpen
                  ? "2px solid #fff;"
                  : "none",
                background: "linear-gradient(90deg, #F9B16E 0%, #F68080 100%)",
                textTransform: "none",
                "&:hover": {
                  border: "2px solid #fff;",
                },
              }}
              startIcon={<BoltIcon sx={{ color: "#FFF" }} />}
              onClick={() => {
                if (this.state.usageAndPlanIsOpen) {
                  this.closeUsageDrawer();
                } else {
                  this.openUsageDrawer();
                }
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  /* Components/Button Medium */
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                {t("Usage and Plan")}
              </Typography>
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    );
  }

  render() {
    const { t } = this.props;
    const pathname = window.location.pathname;
    const userIsSensia = UserHelper.userIsSensia();

    const orangeHeaderMessage = this.getStorageMessage();

    return (
      <Fragment>
        <div
          className="content-main-header"
          style={
            this.props.errorPage
              ? { position: "absolute", zIndex: "99" }
              : { zIndex: "99" }
          }
        >
          {!!orangeHeaderMessage && (
            <div className="orange-header">{orangeHeaderMessage}</div>
          )}
          <div
            className={`main-header ${
              !!orangeHeaderMessage ? "with-orange-header" : ""
            }`}
            data-env={globalConstants.ENV}
          >
            <StagingRibbon />
            <Drawer
              anchor="right"
              BackdropProps={{ invisible: true }}
              open={this.state.drawerIsOpen}
              onClose={this.toggleDrawer}
            >
              {<HelpMenu toggleDrawer={this.toggleDrawer} />}
            </Drawer>
            <div className="central-content">
              <div className="position-relative">
                <div className="float-left line-height-53">
                  <Link to={ROUTES.HOME} className="link">
                    <img
                      src={this.getLogo()}
                      alt="Logo"
                      className="website-logo"
                    />
                  </Link>
                </div>
                <div className="float-right line-height-53">
                  <ul className="menu font-inter-500">
                    <li className="menu-li-link">{this.usageButton()}</li>
                    <li
                      className={`${
                        this.projectIsActive() ? "active" : ""
                      } menu-li-link`}
                    >
                      <Link
                        to={ROUTES.HOME}
                        className="transition-opacity active"
                      >
                        {t("Projects")}
                      </Link>
                    </li>
                    {userIsSensia && (
                      <li
                        className={`${
                          pathname.includes("/models/") ? "active" : ""
                        }  menu-li-link`}
                      >
                        <Link
                          to={ROUTES.MODELS_SCORE}
                          className="transition-opacity"
                        >
                          {t("Models")}
                        </Link>
                      </li>
                    )}
                    {userIsSensia && (
                      <li
                        className={`${
                          pathname.includes("/admin") ? "active" : ""
                        }  menu-li-link`}
                      >
                        <Link
                          to={ROUTES.BACK_OFFICE}
                          className="transition-opacity"
                        >
                          {t("Admin")}
                        </Link>
                      </li>
                    )}
                    {/* <li>
                      <div
                        className={`helpMenu cursor-pointer ${
                          this.state.drawerIsOpen ? "active" : ""
                        }`}
                        onClick={this.toggleDrawer}
                      />
                    </li> */}
                    <li>
                      <ProfileMenu />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="fake-main-header" /> */}
      </Fragment>
    );
  }
}

export default withTranslation()(Header);
