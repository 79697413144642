import React from "react";
import moment from "moment-timezone";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box, styled } from "@mui/material";
import "./list.css";
import { withTranslation } from "react-i18next";
import RoundSelect from "../roundSelect/RoundSelect";
import MediaStatus from "./elements/MediaStatus";

import MediaButton from "./elements/MediaButton";
import DateHelper from "../../app/helpers/DateHelper";
import { MEDIA_STATUS } from "../../app/constants";
import MediaHelper from "../../app/helpers/MediaHelper";
import pictoConsumer from "../../res/images/icons/picto-consumer.svg";
import pictoExpert from "../../res/images/icons/picto-expert.svg";
import pictoStatusExclude from "../../res/images/icons/datasource-status-exclude.svg";
import pictoStatus25 from "../../res/images/icons/datasource-status-25.svg";
import pictoStatus50 from "../../res/images/icons/datasource-status-50.svg";
import pictoStatus75 from "../../res/images/icons/datasource-status-75.svg";
import pictoStatusInclude from "../../res/images/icons/datasource-status-include.svg";
import Score from "../../components/score/Score";
import upgradeIcon from "./../../res/images/icons/upgrade_icon.svg";
import parse from "html-react-parser";
import SChip from "../SMui/SChip";

class MediaListItem extends React.Component {
  constructor(props) {
    super(props);
    this.getPreviewPicture = this.getPreviewPicture.bind(this);
    this.getUsedPercent = this.getUsedPercent.bind(this);
    this.getTotalPercent = this.getTotalPercent.bind(this);
    this.getMediaType = this.getMediaType.bind(this);
  }

  getPreviewPicture() {
    const { item } = this.props;
    if (!item.picture) {
      return null;
    }

    return "url('" + item.picture + "')";
  }

  getImageStyle() {
    return { maxHeight: "18px" };
  }

  getUsedPercent() {
    const { item } = this.props;
    let percent = 0;
    item.total_obs.total_obs != 0
      ? (percent = item.total_obs.total_obs_used / item.total_obs.total_obs)
      : (percent = 0);
    return Number(percent).toLocaleString("en-US", {
      style: "percent",
      minimumFractionDigits: 0,
    });
  }

  getTotalPercent() {
    const { item } = this.props;
    let percent = 0;
    item.total_obs.total_obs != 0
      ? (percent = item.total_obs.total_obs / item.total_obs.total_reviews)
      : (percent = 0);
    return Number(percent).toLocaleString("en-US", {
      style: "percent",
      minimumFractionDigits: 0,
    });
  }

  getMediaType(type) {
    return type.toLowerCase();
  }

  render() {
    const { t, quotasExceeded, projectId, projectFilters } = this.props;

    const item = this.props.item;

    let listItemsClasses = ["media-list-item", "transition-color"];
    listItemsClasses.push(item.status.toLowerCase());
    const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        padding: "5px",
        borderRadius: "2px",
        backgroundColor: "#424242",
      },
    }));
    const previewPicture = this.getPreviewPicture();
    const imageStyle = this.getImageStyle();
    let roundSelectList = {};
    if (item.status !== MEDIA_STATUS.INPROGRESS) {
      if (item.status === MEDIA_STATUS.SUCCESS && item.total_obs?.total_obs) {
        roundSelectList["links"] = [];

        roundSelectList["links"].push({
          label: t("Insights"),
          action: () => {
            sessionStorage.setItem("selectedDatasource", item.id);
            this.props.selectDatasourceId(item.id, "insights");
          },
        });

        roundSelectList["links"].push({
          label: t("In-Depth"),
          action: () => {
            sessionStorage.setItem("selectedDatasource", item.id);
            this.props.selectDatasourceId(item.id, "in-depth");
          },
        });

        roundSelectList["links"].push({
          label: t("Inspect"),
          action: () => {
            sessionStorage.setItem("selectedDatasource", item.id);
            this.props.selectDatasourceId(item.id, "inspect");
          },
        });
      }

      if (
        item.type !== MediaHelper.type.TXT_FILE &&
        item.type !== MediaHelper.type.SHEET_FILE
      ) {
        roundSelectList["actions"] = [];

        roundSelectList["actions"].push({
          label: t("Open Source Page"),
          action: this.props.onOpenLink,
        });

        roundSelectList["actions"].push({
          label: t("Update"),
          action: this.props.onOpenReloadModal,
          className: "display-flex-important justify-content-space-between",
          icon: quotasExceeded ? <img src={upgradeIcon} /> : <></>,
        });
      } else {
        roundSelectList["actions"] = [];

        roundSelectList["actions"].push({
          label: t("Update Insights"),
          action: this.props.onOpenReloadModal,
        });
      }
    }

    roundSelectList["other"] = [
      {
        label: t("Delete"),
        action: this.props.onOpenDeleteModal,
        style: { color: "#e04f77" },
      },
    ];

    const displayStatus =
      item.status == MEDIA_STATUS.SUCCESS &&
      item.isCaption &&
      projectFilters &&
      (projectFilters.langs || projectFilters.date);

    // Regles:
    // si total review ET total obs = 0 → no caption
    // si total review > 0 ET total obs = 0 → exclus
    // Si total review <> total obs → partiel  ( au plus proche de 25%, 50% et 75%)
    // Si total review = total obs ( Et SUp à 0) → inclus

    let tooltipTitle = t(
      "No reviews from this datasource match your selected filters."
    );
    let statusPicto = pictoStatusExclude;
    let statusLabel = "Out of scope";

    if (
      item.total_obs?.total_reviews &&
      item.total_obs?.total_reviews > 0 &&
      item.total_obs?.total_obs == item.total_obs.total_reviews
    ) {
      statusPicto = pictoStatusInclude;
      statusLabel = "Complete";
      tooltipTitle = t(
        "All reviews from this source are included in your analysis."
      );
    } else if (
      item.total_obs?.total_reviews &&
      item.total_obs?.total_reviews > 0 &&
      item.total_obs?.total_obs == 0
    ) {
      statusPicto = pictoStatusExclude;
      statusLabel = "Out of scope";
    } else if (item.total_obs?.total_obs < item.total_obs.total_reviews) {
      const percentage =
        item.total_obs.total_obs / item.total_obs.total_reviews;
      statusLabel = "Limited";
      tooltipTitle = t(
        "Some reviews from this datasource fall outside your selected filters and are excluded from the analysis."
      );
      if (percentage > 0.62) {
        statusPicto = pictoStatus75;
      } else if (percentage > 0.37) {
        statusPicto = pictoStatus50;
      } else if (percentage > 0) {
        statusPicto = pictoStatus25;
      }
    }
    return (
      <div className={listItemsClasses.join(" ")}>
        <Box sx={{ ml: "12px", height: "fit-content" }}>
          <Box
            sx={{
              width: { xs: "95px", md: "190px" },
              height: { xs: "75px", md: "145px" },
              borderRadius: "2px",
              backgroundColor: "#FFFF",
              backgroundImage: previewPicture,
              backgroundPosition: "center center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              display: "flex",
              flexDirection: "column-reverse",
              alignItems: "flex-end",
            }}
          >
            {!!item.duration && (
              <div className="content-duration font-size-12 font-inter-500 color-ebebeb ">
                {DateHelper.getSecondsTohhmmss(item.duration)}
              </div>
            )}
          </Box>
        </Box>
        <div className="content-left" id="medialistitem">
          {/* info datasource */}
          <div className="responsive-920-padding-right-0 content-media-list">
            <div className="my-left">
              <div className="max-width-565 font-size-18 color-424242 font-inter-600 text-ellipsis-1 padding-bottom-2">
                {parse(item.title)}
              </div>

              <div className="font-size-13 color-757575 font-inter-400 no-responsive-920 display-flex margin-top-4">
                {item.typology === "CONSUMER" ? (
                  <img src={pictoConsumer} alt="" />
                ) : (
                  <img src={pictoExpert} alt="" />
                )}
                <div className="font-size-13 color-aaa font-inter-400 margin-left-2">
                  {item.typology === "CONSUMER"
                    ? t("Consumer")
                    : t("Lab / Expert")}
                </div>
              </div>

              {!(item.status === MEDIA_STATUS.FAILED) &&
                !(item.status === MEDIA_STATUS.TOPROCESS) &&
                item.datasource_type[0] && (
                  <div className="mediaList margin-top-10">
                    <div className="mediaItem">
                      <img
                        src={
                          process.env.REACT_APP_API_URL +
                          item.datasource_type[0]?.icon
                        }
                        alt=""
                        style={imageStyle}
                        height="18px"
                      />
                      <div>{item.datasource_type[0]?.label}</div>
                    </div>
                    {item.languages.map((language, i) => {
                      if (i < 2) {
                        return (
                          <div className="mediaItem" key={i}>
                            <div>{language?.label}</div>
                          </div>
                        );
                      }
                    })}
                    {item.languages.length > 3 && (
                      <HtmlTooltip
                        title={
                          <ul className="mediaItem-tooltip">
                            {item.languages.map((language, i) => {
                              if (i >= 2) {
                                return <li key={i}>{language.label}</li>;
                              }
                            })}
                          </ul>
                        }
                        placement="bottom-start"
                      >
                        <div className="mediaItem">
                          <div>+{item.languages.length - 2}</div>
                        </div>
                      </HtmlTooltip>
                    )}
                  </div>
                )}
              {(item.status === MEDIA_STATUS.FAILED ||
                item.status === MEDIA_STATUS.TOPROCESS) &&
                item.datasource_type[0] && (
                  <div className="mediaList margin-top-10">
                    <div className="mediaItem">
                      <img
                        src={
                          process.env.REACT_APP_API_URL +
                          item.datasource_type[0]?.icon
                        }
                        alt=""
                        style={imageStyle}
                        height="18px"
                      />
                      <div>{item.datasource_type[0]?.label}</div>
                    </div>
                  </div>
                )}

              {(item.status === MEDIA_STATUS.FAILED ||
                item.status === MEDIA_STATUS.TOPROCESS) &&
                item.datasource_type[0] === undefined && (
                  <div className="mediaList margin-top-10">
                    <div style={{ height: "38px" }} />
                  </div>
                )}
            </div>

            <div className="my-right">
              {item.status === MEDIA_STATUS.FAILED && (
                <div className="retry">
                  <MediaButton
                    item={item}
                    projectId={projectId}
                    onLaunchAnalyse={this.props.onLaunchAnalyse}
                  />
                </div>
              )}

              <div className="content-status">
                <MediaStatus item={item} />
              </div>

              <div className="hoverInfo">
                {item.status === MEDIA_STATUS.SUCCESS && (
                  <div
                    className="font-size-13 color-7f7f7f font-inter-400"
                    id="hoverInfoTime"
                    title={moment
                      .utc(item.updated_at)
                      .local()
                      .format("MMMM Do YYYY, h:mm:ss a")}
                  >
                    {moment.utc(item.updated_at).local().fromNow(true) === "Now"
                      ? moment.utc(item.updated_at).local().fromNow(true)
                      : `${moment.utc(item.updated_at).local().fromNow()}`}
                  </div>
                )}

                <div className="roundSelect">
                  <RoundSelect lists={roundSelectList} />
                </div>
              </div>
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: displayStatus ? "space-between" : "flex-end",
              alignItems: "flex-end",
              height: "100%",
            }}
          >
            {displayStatus && (
              <Tooltip
                title={tooltipTitle}
                arrow
                sx={{
                  maxWidth: "400px",
                  color: "#FFF",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                  letterSpacing: "0.14px",
                  background: "rgba(97, 97, 97, 1)",
                }}
              >
                <SChip
                  icon={<img height="13px" src={statusPicto} alt="ia icon" />}
                  label={statusLabel}
                  variant="outlined"
                  sx={{
                    height: "21px",
                    borderRadius: "8px",
                    borderColor: "#616161",
                    background: "#FFF",
                    color: "#616161",
                    fontSize: "12px",
                    fontWeight: 500,
                    mb: "8px",
                  }}
                />
              </Tooltip>
            )}
            <Box
              sx={{
                justifyContent: "flex-end",
                display: { xs: "none", md: "flex" },
              }}
            >
              {item.average_score !== null &&
                item.status !== MEDIA_STATUS.FAILED &&
                item.isCaption && (
                  <Score
                    listItem={true}
                    size="medium"
                    style={{ marginTop: "6px", marginRight: "14px" }}
                    reviews={item?.total_obs?.total_obs_used}
                    total_reviews={item?.total_obs?.total_obs}
                    total={item.total_obs.total_reviews}
                    percent={this.getTotalPercent()}
                    relevantReviews={
                      item?.total_obs?.total_obs_used &&
                      item?.total_obs?.total_obs
                        ? Number(
                            item?.total_obs?.total_obs_used /
                              item?.total_obs?.total_obs
                          ).toLocaleString("en-US", {
                            style: "percent",
                            minimumFractionDigits: 0,
                          })
                        : item?.total_obs?.total_obs_used == null
                        ? "-"
                        : "0%"
                    }
                    positive={
                      item?.percent_pos !== null &&
                      item?.percent_pos !== undefined
                        ? Number(item?.percent_pos).toLocaleString("en-US", {
                            style: "percent",
                            minimumFractionDigits: 0,
                          })
                        : null
                    }
                    negative={
                      item?.percent_neg !== null &&
                      item?.percent_neg !== undefined
                        ? Number(item?.percent_neg).toLocaleString("en-US", {
                            style: "percent",
                            minimumFractionDigits: 0,
                          })
                        : null
                    }
                    score={item?.average_score || "-"}
                    feeling={item?.main_emotion}
                    last_review_date={item?.last_review_date?.[0]}
                    print={false}
                  />
                )}
            </Box>
          </Box>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MediaListItem);
